import Title from "../../../../components/Title/Title"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getCustomersThunk } from "../../store/customersSlice"
import { Link } from "react-router-dom"
import ROUTES, { getAbsoluteURL } from "../../../../application/routes/routes"
import { Table } from "../../../../components"

const Сustomers = () => {
  const dispatch = useDispatch()
  const { customers } = useSelector((e) => e.customers)

  useEffect(() => {
    dispatch(getCustomersThunk())
  }, [dispatch])

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
    },

    {
      field: "full_name",
      headerName: "ФИО",
      flex: 1.5,
      // cellClassName: "name-column--cell",
      // rowclassName: "test",
      renderCell: (props) => {
        const url = getAbsoluteURL(ROUTES.CUSTOMER_ID, props.row.id)
        return <Link to={url}>{props.value}</Link>
      },
    },

    {
      field: "date_create",
      headerName: "Дата регистрации",
      // type: "date",
      flex: 1,
    },

    {
      field: "passport.place_residence",
      headerName: "Прописка",
      flex: 1.5,
      renderCell: (props) => {
        try {
          return <div>{props.row.passport.place_residence}</div>
        } catch (err) {
          return <div></div>
        }
      },
    },

    {
      field: "date_birth",
      headerName: "День рождение",
      flex: 1,
    },
  ]

  if (customers === null) return <div>Loading...</div>

  return (
    <div>
      <Title title="Клиенты" subtitle=""></Title>
      <Table className="mt20" columns={columns} operations={customers} />
    </div>
  )
}

export default Сustomers
