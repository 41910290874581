import React, { useState } from "react"
import { Menu } from "../../../components"
import AdditionalMenu from "./HiddenMenuActionLog"

const MenuActionLog = ({ list, setActionLogListFilter, getData }) => {
  const listField = { employees: "Все", type_operation: "Все", bank_account: "Все" }
  const [select, setSelect] = useState(listField)

  const getDataFun = (props) => {
    getData(props)
    setSelect(listField)
  }

  const onChangeFilter = (props) => {
    const result = list.filter((e) => {
      const r1 = props.employees === e.employee || props.employees === "Все" ? true : false
      const r2 = props.type_operation === e.type_operation || props.type_operation === "Все" ? true : false
      const r3 = props.bank_account === e.bank_account || props.bank_account === "Все" ? true : false
      return [r1, r2, r3].every((e) => e === true)
    })
    setActionLogListFilter(result)
  }

  return (
    <div>
      <Menu onClickBtn={getDataFun}>
        <AdditionalMenu onChangeFilter={onChangeFilter} select={select} setSelect={setSelect} />
      </Menu>
    </div>
  )
}

export default MenuActionLog
