import st from "../TicketCard/TicketCard.module.scss"
import { Table } from "../../../../components"
import { getValueDate } from "../../../../shared/utils/utils_date"
import DeleteIconTable from "../../../../shared/UI/DeleteIconTable/DeleteIconTable"

const TicketCardTablePayment = ({ payment, DeleteOperationSet }) => {
  const [popUpOpenFun, popUpOpenDeleteOperation] = DeleteOperationSet

  const columnsTable1 = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
    },

    {
      field: "date",
      headerName: "Дата оплаты",
      valueGetter: getValueDate,
      flex: 1,
    },

    {
      field: "action",
      headerName: "Действие",
      flex: 1,
    },

    {
      field: "percent",
      headerName: "Процент в день",
      flex: 1,
      renderCell: (props) => {
        return <div>{`${props.value}%`}</div>
      },
    },

    {
      field: "amount",
      headerName: "Сумма",
      flex: 1,
      renderCell: (props) => {
        return <div>{`${props.value} ₽`}</div>
      },
    },

    {
      field: "amount_interest_paid",
      headerName: "Уплаченные проценты",
      flex: 1,
      renderCell: (props) => {
        return <div>{`${props.value} ₽`}</div>
      },
    },

    {
      field: "fun",
      headerName: "Функции",
      width: "100",

      renderCell: (props) => {
        return <DeleteIconTable deleteOperation={() => popUpOpenFun(props.row.operation)} />
      },
    },
  ]

  return (
    <>
      {popUpOpenDeleteOperation}
      <div className={st.tables}>
        <div className={st.wrapper}>
          <div className={st.title}>Платежи</div>
          <Table className={st.table} columns={columnsTable1} operations={payment} />
        </div>
      </div>
    </>
  )
}

export default TicketCardTablePayment
