import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from '../api/api'
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"


export const getCustomersThunk = createAsyncThunk('customers/getCustomers', async (query, thunkAPI) => {
    try {
        const request = await API.getCustomers(query)
        return request
    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})


const customersSlice = createSlice({
    name: 'customers',
    initialState: {
        customers: [],
    },
    extraReducers: (builder) => {
        //
        builder.addCase(getCustomersThunk.fulfilled, (state, action) => {
            state.customers = action.payload
        })
        // ======================================================
    }
})

export default customersSlice.reducer