import dayjs from "dayjs"
import { addNotification } from "../../modules/NotificationMessages"
import { Logout } from "../../pages/Authorization"

export const getSubCategory = (props) => {
  const { category, catId } = props
  const res = category.filter((e) => e.slug === catId || e.id === catId)
  return res.length ? res[0].subcategories : null
}

export const getSubCategoryId = (props) => {
  const { category, slug } = props
  for (let item of category) {
    for (let cat of item.subcategories) {
      if (cat.slug === slug) return cat.id
    }
  }
}

export const validationField = (props) => {
  const { value, key, simbol, max_length } = props

  let result = ""
  const text = value
  const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
  for (let i in text) {
    if (Number(i) > max_length) break
    if (Number(i) === key && text[i] !== simbol) result = `${result}${simbol}`
    if (!numbers.includes(text[i]) && !(Number(i) === key)) break
    result += text[i]
  }
  return result
}

export const validationNumberField = ({ value, length }) => {
  const myPattern = new RegExp(`\\d{1,50}`, "g")
  let result = value.match(myPattern)
  result = result ? result.join("") : ""
  return result.substring(0, length)
}

export const convertIdInName = ({ list, id }) => {
  for (let item of list) {
    if (item.id === id) return item.name
  }
}

export const convertNameInId = ({ list, name }) => {
  for (let item of list) {
    if (item.name === name) return item.id
  }
}

export const errorManager = (err, dispatch, addNotification) => {
  let message = null

  if (err.response.status === 500) {
    message = "Ошибка сервера!"
  } else if (err.response.status === 400) {
    // message = `ERROR: ${err.response.data['ошибка']}`
    message = JSON.stringify(err.response.data)
  } else if (err.response.status === 401) {
    message = JSON.stringify(err.response.data)
    dispatch(Logout())
  } else {
    message = JSON.stringify(err.response.data)
  }

  dispatch(addNotification(message))
}

export const blankForThunk = async ({ request, thunkAPI, messageSuccess }) => {
  try {
    const result = await request()
    if (messageSuccess) thunkAPI.dispatch(addNotification(messageSuccess))
    return result
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
}
