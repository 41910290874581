import { axiosBase } from "../../../features/globalApi"

export const API = {
  async createCategoryAPI(params) {
    const response = await axiosBase.post("/category/", params)
    return response.data
  },

  async selectCategoryAPI(params) {
    const response = await axiosBase.put("/product/change_category/", params)
    return response.data
  },
}
