import React, { useEffect, useState } from "react"
import { ButtonHref, FieldText } from "../../../shared"
import FieldSelectPlus from "../../../shared/UI/Field/FieldSelectPlus"
import st from "../style/CreateСategory.module.scss"
import { createCategoryThunk } from "../store/CreateСategorySlice"
import { useDispatch } from "react-redux"
import useGetCategories2 from "../../../shared/hooks/useGetCategories2"

const CreateСategory = ({ setTypeForm }) => {
  const dispatch = useDispatch()

  const {
    uniqCategories: uniqCategory,
    categories: fullCategory,
    onUpdate,
    categoryLoading: isLoading,
  } = useGetCategories2()

  const [categoryList, setCategoryList] = useState({ list: [], value: "" })
  const [subCategory, setSubCategory] = useState("")

  useEffect(() => {
    setCategoryList({ list: uniqCategory, value: "" })
    // eslint-disable-next-line
  }, [fullCategory])

  const cleanText = (text) => {
    return text.toLowerCase().trim()
  }

  const addCategory = (value) => {
    const _t = categoryList.list.filter((e) => cleanText(e.name) === cleanText(value))
    let params = null

    if (_t.length > 0) {
      params = { list: categoryList.list, value: _t[0].id }
    } else {
      const item = { id: `a100`, name: value }
      params = { list: [...categoryList.list.filter((e) => e.id !== "a100"), item], value: "a100" }
    }

    setCategoryList(params)
  }

  if (isLoading) return <div>Loadiang...</div>

  const selectCategory = (id) => {
    setCategoryList((e) => {
      return { ...e, value: id }
    })
  }

  const saveNewCategory = () => {
    const result = categoryList.list.filter((e) => categoryList.value === e.id)[0]
    const params = { category: result.name, subCategory: subCategory }
    dispatch(createCategoryThunk(params)).then((e) => {
      if (!e.error) {
        setTypeForm()
        onUpdate()
      }
    })
  }

  return (
    <>
      <FieldSelectPlus
        label="Категория"
        fun={selectCategory}
        addFun={addCategory}
        list={categoryList.list}
        id="category"
        value={categoryList.value}
      ></FieldSelectPlus>

      {categoryList.value && (
        <FieldText
          label="Подкатегория"
          id="subCategory"
          placeholder="Новая категория"
          value={subCategory}
          onChange={(e) => setSubCategory(e.target.value)}
        ></FieldText>
      )}

      <div className={st.btn}>
        <ButtonHref onClick={setTypeForm}>Отмена</ButtonHref>
        {subCategory && <ButtonHref onClick={saveNewCategory}>Сохранить изменения</ButtonHref>}
      </div>
    </>
  )
}

export default CreateСategory
