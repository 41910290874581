import st from "../AddUser/AddUser.module.scss"
import { useFormik } from "formik"
import { validationField } from "../../../../shared/utils/utils"
import * as yup from "yup"
import { ButtonElem, ButtonHref, FieldDate, FieldText } from "../../../../shared"

const AddPassportForm = (props) => {
  const { openForm, SubmitForm, DataForm } = props

  const required = "Обязательное поле"
  const checkoutSchema = yup.object().shape({
    series_number: yup.string().required(required).min(11, "Должно быть ровно 10 цифр"),
    issued_whom: yup.string().required(required),
    date_issue: yup.string().required(required),
    division_code: yup.string().required(required).min(7, "Должно быть ровно 6 цифр"),
    place_residence: yup.string().required(required),
  })

  const formik = useFormik({
    initialValues: {
      series_number: "",
      issued_whom: "",
      date_issue: "",
      division_code: "",
      place_residence: "",
      ...(DataForm !== null ? DataForm : {}),
    },
    onSubmit: (value) => {
      SubmitForm(value)
      openForm()
    },

    validationSchema: checkoutSchema,
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FieldText
          label="Серия, номер"
          id="series_number"
          placeholder="Пример: 3601 123456"
          error={{ touched: formik.touched.series_number, message: formik.errors.series_number }}
          value={formik.values.series_number}
          onChange={(props) => {
            const result = validationField({
              value: props.target.value,
              key: 4,
              simbol: " ",
              max_length: 10,
            })
            formik.setFieldValue("series_number", result)
          }}
        ></FieldText>

        <FieldText
          label="Кем выдан"
          id="issued_whom"
          placeholder="Пример: УФМС России"
          error={{ touched: formik.touched.issued_whom, message: formik.errors.issued_whom }}
          value={formik.values.issued_whom}
          onChange={formik.handleChange}
        ></FieldText>

        <FieldDate
          label="Дата выдачи"
          id="date_issue"
          error={{ touched: formik.touched.date_issue, message: formik.errors.date_issue }}
          value={formik.values.date_issue}
          fun={(value) => formik.setFieldValue("date_issue", value)}
        ></FieldDate>

        <FieldText
          label="Код подразделения"
          id="division_code"
          placeholder="Пример: 630-004"
          error={{ touched: formik.touched.division_code, message: formik.errors.division_code }}
          value={formik.values.division_code}
          onChange={(props) => {
            const result = validationField({
              value: props.target.value,
              key: 3,
              simbol: "-",
              max_length: 6,
            })
            formik.setFieldValue("division_code", result)
          }}
        ></FieldText>

        <FieldText
          label="Адрес регистрации"
          id="place_residence"
          placeholder="Пример: Омск, осоавиахимовкая 181к3, кв151"
          error={{ touched: formik.touched.place_residence, message: formik.errors.place_residence }}
          value={formik.values.place_residence}
          onChange={formik.handleChange}
        ></FieldText>

        <div className={st.btn_wrapper}>
          <ButtonElem type="submit">Сохранить</ButtonElem>
          <ButtonHref type="button" onClick={openForm}>
            Отмена
          </ButtonHref>
        </div>
      </form>
    </>
  )
}

export default AddPassportForm
