import React, { useEffect } from "react"
import { Title } from "../../../components"
import { useDispatch, useSelector } from "react-redux"
import BaseFormFields from "../../../components/BaseFormFields/BaseFormFields"
import { changeStatusIsCreateEmployee, createEmployeeThunk } from "../store/teamStore"

const FormCreateTeam = ({ closeForm }) => {
  const dispatch = useDispatch()
  const { isCreateEmployee } = useSelector((e) => e.team)

  const createEmployee = (props) => {
    delete props["password2"]
    dispatch(createEmployeeThunk(props))
  }

  useEffect(() => {
    if (isCreateEmployee) {
      dispatch(changeStatusIsCreateEmployee())
      closeForm()
    }
    // eslint-disable-next-line
  }, [isCreateEmployee])

  const fields = [
    { id: "first_name", label: "Имя", placeholder: "Александр", validation: "string" },
    { id: "last_name", label: "Фамилия", placeholder: "Иванов", validation: "string" },
    { id: "email", label: "Почта", placeholder: "aleksandr_ivanov@gmail.com", validation: "email" },
    { id: "password", label: "Пароль для входа", placeholder: "fwaofj@0_waof", validation: "password" },
    { id: "password2", label: "Ещё раз пароль", placeholder: "fwaofj@0_waof", validation: "passwordConfirmation" },
  ]

  return (
    <div>
      <Title title="" subtitle={"Добавления сотрудника"}></Title>
      <BaseFormFields fields={fields} closeForm={closeForm} SubmitForm={createEmployee} />
    </div>
  )
}

export default FormCreateTeam
