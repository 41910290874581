import { Table } from "../../../components"
import st from "../style/Base.module.scss"

const TableActionLog = ({ list }) => {
  const columns = [
    {
      field: "date_log",
      headerName: "Дата записи",
      flex: 1,
    },

    {
      field: "date_action",
      headerName: "Дата события",
      flex: 1,
    },

    {
      field: "employee",
      headerName: "Сотрудник",
      flex: 1,
    },

    {
      field: "description_action",
      headerName: "Описание события",
      flex: 2,
      renderCell: (props) => {
        return <div className={st.renderCell}>{props.value}</div>
      },
    },

    {
      field: "type_operation",
      headerName: "Тип операции",
      flex: 1,
    },

    {
      field: "calculation_method",
      headerName: "Способ оплаты",
      flex: 0.9,
    },
  ]

  if (list === null) return <div>Loading...</div>

  return (
    <div>
      <Table className="mt20" columns={columns} operations={list} autoHeight={true} />
    </div>
  )
}

export default TableActionLog
