import { createAsyncThunk } from "@reduxjs/toolkit"
import { errorManager } from "./utils"
import { addNotification } from "../../modules/NotificationMessages"
import { redirectAdd } from "../../features/globalSlice"

export const abbreviatedThunk = ({ api_request, _nameStore, messageSuccess, redirectSuccess }) => {
  if (typeof api_request !== "function") {
    throw new Error("В api_request нужно передать функцию не вызывая её!")
  }

  const baseThunk = createAsyncThunk(`${_nameStore}/${api_request.name}`, async (query, thunkAPI) => {
    try {
      const request = await api_request(query)
      if (messageSuccess) thunkAPI.dispatch(addNotification(messageSuccess))
      if (redirectSuccess) thunkAPI.dispatch(redirectAdd(redirectSuccess))

      return request
    } catch (err) {
      errorManager(err, thunkAPI.dispatch, addNotification)
      return thunkAPI.rejectWithValue(err)
    }
  })

  return baseThunk
}
