import { addHeaders, axiosBase } from "../../../features/globalApi"

export const API = {
  async search(props = {}) {
    const response = await axiosBase.get(`/search/`, { params: props })
    return response.data
  },

  async getAuthUser(props = {}) {
    addHeaders()
    const response = await axiosBase.get(`/auth/`, { params: props })
    return response.data
  },
}
