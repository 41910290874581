import { IconButton } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useTheme } from "@mui/material"
import { ColorModeContext } from "../../../theme"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined"
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import st from "../style/Header.module.scss"
import { NavLink } from "react-router-dom"
import ROUTES from "../../../application/routes/routes"
import SearchInput from "./SearchInput"
import { Logout } from "../../../pages/Authorization"
import { useDispatch, useSelector } from "react-redux"
import { clearEmployeeData, getAuthUserThunk } from "../store/HeaderSlice"

const Base = () => {
  const dispatch = useDispatch()
  const [accountIsOpen, setAccountIsOpen] = useState(false)

  useEffect(() => {
    dispatch(getAuthUserThunk())
    // eslint-disable-next-line
  }, [])

  const theme = useTheme()
  let colorMode = useContext(ColorModeContext)

  const handleTheme = () => {
    colorMode.toggleColorMode()
  }

  const FunClassActive = (props) => {
    const { isActive, isPending } = props
    return isPending ? "pending" : isActive ? props.style : ""
  }

  return (
    <div className={st.header}>
      <div className={st.wrapper}>
        <SearchInput />

        <div className={st.icon_wrapper}>
          <IconButton onClick={handleTheme}>
            {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
          </IconButton>
          <div>
            <NavLink>
              <NotificationsOutlinedIcon />
            </NavLink>
          </div>
          <div>
            <NavLink to={ROUTES.SETTINGS_PAGE} className={(props) => FunClassActive({ ...props, style: st.active })}>
              <SettingsOutlinedIcon className={st.icon} />
            </NavLink>
          </div>
          <div className={st.account_wrapper}>
            <NavLink onClick={() => setAccountIsOpen((e) => !e)}>
              <PersonOutlinedIcon />
            </NavLink>
            {accountIsOpen && <PopUpAccount />}
          </div>
        </div>
      </div>
    </div>
  )
}

const PopUpAccount = () => {
  const { employee } = useSelector((e) => e.header)
  const dispatch = useDispatch()

  const onClickLogout = () => {
    dispatch(Logout())
    dispatch(clearEmployeeData())
  }

  return (
    <div className={st.account}>
      <div className={st.item}>{`${employee.first_name} ${employee.last_name}`}</div>
      <NavLink className={st.item}>Настройки</NavLink>
      <NavLink className={st.item} onClick={onClickLogout}>
        Выйти
      </NavLink>
    </div>
  )
}

export default Base
