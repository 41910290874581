import { axiosBase } from "../../../features/globalApi"

export const API = {
  async getEmployee() {
    const response = await axiosBase.get("/employee/")
    return response.data
  },

  async createEmployee(params) {
    const response = await axiosBase.post("/employee/", params)
    return response.data
  },
}
