import st from "./Menu.module.scss"
import * as React from "react"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { useState } from "react"
import MenuCustom from "../../../../components/MenuCustom/MenuCustom"
import { getTicketStatusThunk } from "../../store/ticketListSlice"

const MenuTableСatalogTicket = (props) => {
  const { onTicketParamsSubmit } = props
  const dispatch = useDispatch()
  const [status, setStatus] = useState("Все статусы")
  const [dateList, setDateList] = useState({ loanDate1: null, loanDate2: null, сlosingDate1: null, сlosingDate2: null })

  const { ticketStatus } = useSelector((e) => e.ticketList)

  const handleChange = (date, type) => {
    setDateList((e) => ({ ...e, [type]: date }))
  }

  const handleSumbit = () => {
    onTicketParamsSubmit(dateList, status)
  }

  useEffect(() => {
    dispatch(getTicketStatusThunk())
    // eslint-disable-next-line
  }, [])

  const SelectFilter = () => {
    return (
      <div className={st.items}>
        <Select className={st.select} size="small" value={status} onChange={(e) => setStatus(e.target.value)}>
          {ticketStatus.map((e) => {
            return (
              <MenuItem key={e.id} value={e.name}>
                {e.name}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    )
  }

  const DataFilter = () => {
    return (
      <>
        <div className={st.date}>
          <div className={st.label}>Дата займа с</div>
          <DatePicker
            slotProps={{ textField: { size: "small" } }}
            disableFuture
            value={dateList.loanDate1}
            onChange={(newValue) => handleChange(newValue, "loanDate1")}
            className={st.field}
          />
          <div>по</div>
          <DatePicker
            slotProps={{ textField: { size: "small" } }}
            disableFuture
            value={dateList.loanDate2}
            onChange={(newValue) => handleChange(newValue, "loanDate2")}
            className={st.field}
          />
        </div>
        <div className={st.date}>
          <div className={st.label}>Дата закрытия с</div>
          <DatePicker
            slotProps={{ textField: { size: "small" } }}
            // disableFuture
            value={dateList.сlosingDate1}
            onChange={(newValue) => handleChange(newValue, "сlosingDate1")}
            className={st.field}
          />
          <div>по</div>
          <DatePicker
            slotProps={{ textField: { size: "small" } }}
            // disableFuture
            value={dateList.сlosingDate2}
            onChange={(newValue) => handleChange(newValue, "сlosingDate2")}
            className={st.field}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <MenuCustom onClickBtn={handleSumbit}>
        <SelectFilter />
        <DataFilter />
      </MenuCustom>
    </>
  )
}

export default MenuTableСatalogTicket
