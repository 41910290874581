import { axiosBase } from "../../../features/globalApi"

export const API = {
  async uploadingCustomer(params) {
    const response = await axiosBase.post(`/uploading/customer/`, params)
    return response.data
  },

  async uploadingProduct(params) {
    const response = await axiosBase.post(`/uploading/product/`, params)
    return response.data
  },

  async securityTicket(params) {
    const response = await axiosBase.post(`/uploading/security_ticket/`, params)
    return response.data
  },
}
