import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { Menu } from "../../../components"
import { getArrivalExpenseThunk } from "../store/arrivalExpenseOperationSlice"
import dayjs from "dayjs"
import FinanceFooter from "../../../components/FinanceFooter/FinanceFooter"
import TableAccountTransactions from "../../../components/TableAccountTransactions/TableAccountTransactions"

const Base = () => {
  const dispatch = useDispatch()
  const { operationsIncome, operationsExpense } = useSelector((e) => e.arrivalExpenseOperation)

  const getData = (props) => dispatch(getArrivalExpenseThunk(props))

  useEffect(() => {
    const data = dayjs(new Date())
    const params = { start: data.format("DD.MM.YYYY"), end: data.format("DD.MM.YYYY") }
    if (!operationsIncome.length && !operationsExpense.length) getData(params)
    // eslint-disable-next-line
  }, [dispatch])

  return (
    <>
      <Menu onClickBtn={getData} />
      <TableAccountTransactions Income={operationsIncome} Expense={operationsExpense}></TableAccountTransactions>
      <FinanceFooter Income={operationsIncome} Expense={operationsExpense} />
    </>
  )
}

export default Base
