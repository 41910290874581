import * as yup from "yup"

export const getValidationYup = (fields) => {
  const required = "Обязательное поле"

  const schema = {
    string: yup.string().required(required),
    email: yup.string().email("Email введен некорректно").required(required),
    password: yup
      .string()
      .required(required)
      .min(8, "Пароль слишком короткий - он должен содержать не менее 8 символов.")
      .matches(/[a-zA-Z]/, "Пароль может содержать только латинские буквы."),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Пароли должны совпадать")
      .required(required),
  }

  const listSchema = fields.reduce((accumulatorelem, currentValue) => {
    return { ...accumulatorelem, ...{ [currentValue.id]: schema[currentValue.validation] } }
  }, {})

  return yup.object().shape(listSchema)
}
