import React from "react"
import DeleteIconTable from "../../../shared/UI/DeleteIconTable/DeleteIconTable"
import { Table } from "../../../components"
import ROUTES, { getAbsoluteURL } from "../../../application/routes/routes"
import { Link } from "react-router-dom"
import useDeleteOperation from "../../../shared/hooks/useDeleteOperation"

const TableOperations = ({ operations }) => {
  const [popUpOpenFun, popUpOpenDeleteOperation] = useDeleteOperation()

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: "60",
    },

    {
      field: "date",
      headerName: "Дата, время записи",
      width: "140",
    },

    {
      field: "operation",
      headerName: "Операция",
      width: "140",
      cellClassName: "name-column--cell",
      rowclassName: "test",
    },

    {
      field: "description",
      headerName: "Описание",
      flex: 1,
      renderCell: (props) => {
        let url = ""

        if (["Залог", "Выкуп", "Перезалог"].indexOf(props.row.operation) >= 0) {
          url = getAbsoluteURL(ROUTES.TICKET_ID, props.row.description_id)
        } else if (["Покупка", "Продажа"].indexOf(props.row.operation) >= 0) {
          url = getAbsoluteURL(ROUTES.PRODUCT_ID, props.row.description_id)
        } else {
          return <div>{props.value}</div>
        }

        return <Link to={url}>{props.value}</Link>
      },
    },

    {
      field: "price",
      headerName: "Сумма",
      width: "100",
    },

    {
      field: "customer",
      headerName: "Клиент",
      width: "220",

      renderCell: (props) => {
        if (props.row.customer_id) {
          const url = getAbsoluteURL(ROUTES.CUSTOMER_ID, props.row.customer_id)
          return <Link to={url}>{props.value}</Link>
        }
        return <div></div>
      },
    },

    {
      field: "employee",
      headerName: "Сотрудник",
      width: "200",
    },

    {
      field: "fun",
      headerName: "Функции",
      width: "100",

      renderCell: (props) => {
        return <DeleteIconTable deleteOperation={() => popUpOpenFun(props.row.id)} />
      },
    },
  ]

  return (
    <>
      {popUpOpenDeleteOperation}
      <Table className="mt20" c columns={columns} operations={operations} />
    </>
  )
}

export default TableOperations
