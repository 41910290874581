import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProductWriteDownThunk, productWriteDownThunk } from "../store/productSlice"
import st2 from "../style/PopUpWriteDowns.module.scss"
import st from "../style/PopUp.module.scss"
import { getCurentDateTime } from "../../../shared/utils/utils_date"

const PopUpWriteDowns = ({ finalAction, idProduct }) => {
  const dispatch = useDispatch()
  const { productsWriteDown } = useSelector((e) => e.product)

  useEffect(() => {
    dispatch(getProductWriteDownThunk({ id: idProduct }))
    // eslint-disable-next-line
  }, [])

  const onSelectProduct = (id_item) => {
    const dateTime = getCurentDateTime()
    const paras = { idProduct: idProduct, item_id: id_item, date: dateTime }
    dispatch(productWriteDownThunk(paras)).then((e) => {
      if (!e.error) finalAction()
    })
  }

  if (!productsWriteDown) return <div>Loading...</div>

  return (
    <div className={st.table}>
      <div className={st.title}>
        <div className={st2.cell}>id</div>
        <div className={st2.cell}>Дата</div>
        <div className={st2.cell}>Цена</div>
      </div>
      <div className={st.wrapper}>
        {productsWriteDown.map((e) => {
          return (
            <div key={e.id} className={st.row} onClick={() => onSelectProduct(e.id)}>
              <div className={st2.cell}>{e.id}</div>
              <div className={st2.cell}>{e.date}</div>
              <div className={st2.cell}>{e.price} ₽</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PopUpWriteDowns
