import { axiosBase } from "../../../features/globalApi"

export const API = {
    async addOtherOperation(props = {}) {
        const response = await axiosBase.post(`/operation/other/`, props)
        return response.data
    },

    async getOperationType() {
        const response = await axiosBase.get(`/operation/type/`)
        return response.data
    },

}