import React, { useEffect } from "react"
import { useRef } from "react"
import { useState } from "react"
import { InputBase } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { IconButton } from "@mui/material"
import st from "../style/SearchInput.module.scss"
import { useDispatch, useSelector } from "react-redux"

import ROUTES, { getAbsoluteURL } from "../../../application/routes/routes"
import { Link, useNavigate } from "react-router-dom"
import { BoxUI, useDebounce, useOnClickOutside } from "../../../shared"
import { clearSearchData, searchThunk, writeSearchValue } from "../store/HeaderSlice"

const SearchInput = () => {
  // TODO Можно снизить кол-во рендеров заменив события онблюр на ref
  const dispatch = useDispatch()
  const { prioritySearch } = useSelector((e) => e.header)
  const [showModal, setShowModal] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [searchValueFocus, setSearchValueFocus] = useState("")
  const ref = useRef()
  const refInput = useRef()
  const navigate = useNavigate()

  const { search, searchLength } = useSelector((e) => e.header)

  useOnClickOutside(ref, () => setShowModal(false))

  const funSearch = (props) => {
    dispatch(searchThunk({ q: props }))
  }
  const debounceSearch = useDebounce(funSearch, 1000)

  const funFocus = (e) => {
    const _value = e.target.value

    setSearchValue(_value)
    if (_value.length > 1) {
      debounceSearch(_value)
    } else if (_value.length === 0) {
      debounceSearch("", "stop")
      dispatch(clearSearchData())
      setShowModal(false)
    }
  }

  useEffect(() => {
    if (search === null) setShowModal(false)
    else if (searchValueFocus) setShowModal(true)
    // eslint-disable-next-line
  }, [search])

  const openWindow = () => {
    if (search !== null) setShowModal(true)
  }

  const redirectPageSearch = () => {
    if (searchValue.length > 1) {
      setShowModal(false)
      // debounceSearch("", "stop")
      // debugger
      if (prioritySearch[1]) navigate(`/search/${prioritySearch[1]}`)
      else navigate(ROUTES.SEARCH)
      dispatch(writeSearchValue(searchValue))
    }
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      refInput.current.childNodes[0].blur()
      redirectPageSearch()
    }
  }

  const getProducts = () => {
    if (!search) return
    if (search.products.length === 0) return

    return (
      <div>
        <div className={st.title}>Новые товары</div>
        {search.products.map((e) => (
          <div key={e.id_product} className={st.item_search}>
            <Link to={getAbsoluteURL(ROUTES.PRODUCT_ID, e.id_product)} target="_blank">
              {e.name}
            </Link>
          </div>
        ))}
      </div>
    )
  }

  const getProductsUsed = () => {
    if (!search) return
    if (search.productsUsed.length === 0) return

    return (
      <div>
        <div className={st.title}>Б/У товары</div>
        {search.productsUsed.map((e) => (
          <div key={e.id_product} className={st.item_search}>
            <Link to={getAbsoluteURL(ROUTES.PRODUCT_ID, e.id_product)} target="_blank">
              {e.name} <span className={st.fullname}>({`id:${e.id_customer} ${e.fullname}`})</span>
            </Link>
          </div>
        ))}
      </div>
    )
  }

  const getProperty = () => {
    if (!search) return
    if (search.property.length === 0) return

    return (
      <div>
        <div className={st.title}>Имущество</div>
        {search.property.map((e) => (
          <div key={e.id_product} className={st.item_search}>
            <Link to={getAbsoluteURL(ROUTES.PROPERTY_ID, e.id_product)} target="_blank">
              {e.name} <span className={st.fullname}>({`id:${e.id_customer} ${e.fullname}`})</span>
            </Link>
          </div>
        ))}
      </div>
    )
  }

  const getcustomers = () => {
    if (!search) return
    if (search.customers.length === 0) return

    return (
      <div>
        <div className={st.title}>Клиенты</div>
        {search.customers.map((e) => (
          <div key={e.id} className={st.item_search}>
            <Link to={getAbsoluteURL(ROUTES.CUSTOMER_ID, e.id)} target="_blank">
              {e.name} <span className={st.fullname}>({`id:${e.id}`})</span>
            </Link>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={st.search_wrapper} ref={ref}>
      <InputBase
        onFocus={() => setSearchValueFocus(true)}
        onBlur={() => setSearchValueFocus(false)}
        ref={refInput}
        value={searchValue}
        className={st.input}
        placeholder="Поиск..."
        onChange={funFocus}
        onClick={openWindow}
        onKeyDown={onKeyDown}
        id={"search_input"}
      />
      <IconButton type="button" onClick={redirectPageSearch}>
        <SearchIcon />
      </IconButton>

      {showModal && (
        <BoxUI className={st.result_window}>
          {getProducts()}
          {getProductsUsed()}
          {getProperty()}
          {getcustomers()}

          {searchLength > 0 ? (
            ""
          ) : (
            <div className={st.nothing_found}>К сожалению по вашему запросу ничего не найдено...</div>
          )}
        </BoxUI>
      )}
    </div>
  )
}

export default SearchInput
