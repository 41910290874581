import React from "react"
import { Route, Routes } from "react-router-dom"
import ROUTES from "./routes.js"

import NewOperation from "../../pages/NewOperationPage/NewOperation"
import Team from "../../modules/Team/components/Team.jsx"
import Faq from "../../pages/SettingPage/Faq/Faq.jsx"
import Dashboard from "../../pages/DashboardPage/Dashboard.jsx"
import Invoices from "../../pages/SettingPage/Invoices/Invoices.jsx"
import SettingsPage from "../../pages/SettingPage/SettingsPage.jsx"

import { Main } from "../App.jsx"
import { ArrivalExpenseDate } from "../../pages/F_ArrivalExpenseDate/index.js"
import { ArrivalExpenseOperations } from "../../pages/F_ArrivalExpenseOperations/index.js"
import { Operations } from "../../pages/OperationsPage/index.js"
import { Product } from "../../pages/ProductCard/index.js"
import { Catalog } from "../../pages/Catalog/index.js"
import { ProductList } from "../../pages/ProductList/index.js"
import { Сustomers } from "../../pages/Сustomers/index.js"
import { PurchasesCustomer, SalesCustomer, TicketsCustomer, СustomerCard } from "../../pages/СustomerCard/index.js"
import { Authorization } from "../../pages/Authorization/index.js"
import { Finance } from "../../pages/Finance/index.js"
import {
  Search,
  SearchTableCustomers,
  SearchTableProducts,
  SearchTableProductsUsed,
  SearchTableProperty,
} from "../../pages/SearchPage/index.js"
import { TicketCard } from "../../pages/TicketCard/index.js"
import { TicketList } from "../../pages/TicketList/index.js"
import { PropertyCard } from "../../pages/PropertyCard/index.js"
import { PropertyList } from "../../pages/PropertyList/index.js"
import { PropertyLiens } from "../../pages/PropertyLiens/index.js"

import { OperationPurchaseProduct } from "../../modules/OperationPurchaseProduct/index.js"
import { OperationSaleProduct } from "../../modules/OperationSaleProduct/index.js"
import { OperationOther } from "../../modules/OperationOther/index.js"
import { OperationSecurityTicket } from "../../modules/OperationSecurityTicket/index.js"
import { BankAccount, BankAccounts } from "../../modules/BankAccounts/index.js"

import { AccountTransactions } from "../../pages/F_AccountTransactions/index.js"
import { ActionLog } from "../../pages/ActionLog/index.js"
import { Employee } from "../../modules/Employee/index.js"
import { OperationPurchaseGroupProducts } from "../../modules/OperationPurchaseGroupProducts/index.js"
import { DataTransfer } from "../../modules/DataTransfer/index.js"

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={<Main />}
      >
        <Route
          path={ROUTES.HOME}
          element={<Dashboard />}
        />

        <Route
          path="/operations/*"
          element={<Operations />}
        />

        <Route
          path={ROUTES.NEW_OPERATION}
          element={<NewOperation />}
        >
          <Route
            index
            element={<></>}
          />
          <Route
            path={ROUTES.PURCHASE}
            element={<OperationPurchaseProduct />}
          />
          <Route
            path={ROUTES.PURCHASE_GROUP}
            element={<OperationPurchaseGroupProducts />}
          />
          <Route
            path={ROUTES.SALE}
            element={<OperationSaleProduct />}
          />
          <Route
            path={ROUTES.TICKET}
            element={<OperationSecurityTicket />}
          />
          <Route
            path={ROUTES.OTHER_OPERATION}
            element={<OperationOther />}
          />
        </Route>

        <Route path="/catalog">
          <Route
            index
            element={<Catalog />}
          />
          <Route
            path=":catId"
            element={<Catalog />}
          />
          <Route
            path=":catId/:subId/*"
            element={<ProductList />}
          />
        </Route>

        <Route
          path={ROUTES.PRODUCT_ID}
          element={<Product />}
        />

        <Route
          path={ROUTES.PROPERTY_LIENS}
          element={<PropertyLiens />}
        >
          <Route
            index
            element={<></>}
          />
          <Route
            path={ROUTES.TICKETS}
            element={<TicketList />}
          />
          <Route
            path={ROUTES.PROPERTY}
            element={<PropertyList />}
          />
        </Route>

        <Route
          path={ROUTES.PROPERTY_ID}
          element={<PropertyCard />}
        />
        <Route
          path={ROUTES.TICKET_ID}
          element={<TicketCard />}
        />

        <Route
          path={ROUTES.CUSTOMERS}
          element={<Сustomers />}
        />

        <Route
          path={ROUTES.CUSTOMER_ID}
          element={<СustomerCard />}
        >
          <Route
            index
            element={<></>}
          />
          <Route
            path={ROUTES.TICKETS_CUSTOMER}
            element={<TicketsCustomer />}
          />
          <Route
            path={ROUTES.SALES_CUSTOMER}
            element={<SalesCustomer />}
          />
          <Route
            path={ROUTES.PURCHASES_CUSTOMER}
            element={<PurchasesCustomer />}
          />
        </Route>

        <Route
          path={ROUTES.SETTINGS_PAGE}
          element={<SettingsPage />}
        >
          <Route
            index
            element={<></>}
          />
          <Route
            path={ROUTES.TEAM}
            element={<Team />}
          />
          <Route
            path={ROUTES.EMPLOYEE}
            element={<Employee />}
          />
          <Route
            path={ROUTES.INVOICES}
            element={<Invoices />}
          />
          <Route
            path={ROUTES.FAQ}
            element={<Faq />}
          />
          <Route
            path={ROUTES.BANK_ACCOUNTS}
            element={<BankAccounts />}
          />
          <Route
            path={ROUTES.BANK_ACCOUNT}
            element={<BankAccount />}
          />
          <Route
            path={ROUTES.DATA_TRANSFER}
            element={<DataTransfer />}
          />
          {/* <Route path={ROUTES.TICKETS_CUSTOMER_ID} element={<TicketsCustomer />} /> */}
        </Route>

        <Route
          path={ROUTES.FINANCE}
          element={<Finance />}
        >
          <Route
            index
            element={<></>}
          />
          <Route
            path={ROUTES.ARRIVAL_EXPENSE_DATE}
            element={<ArrivalExpenseDate />}
          />
          <Route
            path={ROUTES.ARRIVAL_EXPENSE_OPERATIONS}
            element={<ArrivalExpenseOperations />}
          />
          <Route
            path={ROUTES.ACCOUNT_TRANSACTIONS}
            element={<AccountTransactions />}
          />
        </Route>

        <Route
          path={ROUTES.SEARCH}
          element={<Search />}
        >
          {/* <Route index element={<></>} /> */}
          <Route
            index
            path={ROUTES.SEARCH_PRODUCTS}
            element={<SearchTableProducts />}
          />
          <Route
            index
            path={ROUTES.SEARCH_PRODUCTS_USED}
            element={<SearchTableProductsUsed />}
          />
          <Route
            index
            path={ROUTES.SEARCH_PROPERTY}
            element={<SearchTableProperty />}
          />
          <Route
            index
            path={ROUTES.SEARCH_CUSTOMERS}
            element={<SearchTableCustomers />}
          />
        </Route>

        <Route
          path={ROUTES.ACTION_LOG}
          element={<ActionLog />}
        />
      </Route>

      {/* <Route path={ROUTES.SEARCH} element={<Search />} /> */}

      <Route
        path={ROUTES.AUTHORIZATION}
        element={<Authorization />}
      />
    </Routes>
  )
}

export default AppRoutes
