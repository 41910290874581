import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../NotificationMessages"

export const getSaleProductsThunk = createAsyncThunk("Products/getSaleProducts", async (query, thunkAPI) => {
  try {
    const request = await API.getSaleProducts(query)
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

const selectProductSlice = createSlice({
  name: "selectProduct",
  initialState: {
    saleProducts: null,
    isLoadingSaleProducts: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    //
    builder.addCase(getSaleProductsThunk.pending, (state, action) => {
      state.isLoadingSaleProducts = true
    })
    builder.addCase(getSaleProductsThunk.fulfilled, (state, action) => {
      state.saleProducts = action.payload
      state.isLoadingSaleProducts = false
    })
    // ======================================================
  },
})

export default selectProductSlice.reducer
