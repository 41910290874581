import { useEffect } from "react"
import { getBankAccountsLiteThunk } from "../../features/globalSlice"
import { useDispatch, useSelector } from "react-redux"

export default function useGetBankAccounts() {
  const dispatch = useDispatch()
  const { bank_accounts } = useSelector((e) => e.global)

  useEffect(() => {
    if (bank_accounts.length === 0) {
      dispatch(getBankAccountsLiteThunk())
    }
    // eslint-disable-next-line
  }, [])

  return bank_accounts
}
