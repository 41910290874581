import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import st from "./TicketCard.module.scss"
import TicketCardTablePropery from "../TicketCardTablePropery/TicketCardTablePropery"
import TicketCardTablePayment from "../TicketCardTablePayment/TicketCardTablePayment"
import { Title } from "../../../../components"
import { FieldReadOnly } from "../../../../shared"
import { changeIsRedirect, getTicketCardThunk } from "../../store/ticketCardSlice"
import Actions from "../Actions/Actions"
import useDeleteOperation from "../../../../shared/hooks/useDeleteOperation"
import { useNavigate } from "react-router-dom"
import ROUTES, { getAbsoluteURL } from "../../../../application/routes/routes"

const TicketCard = () => {
  const navigate = useNavigate()
  const DeleteOperation = useDeleteOperation()
  const isUpdate = DeleteOperation[2]
  const setIsUpdate = DeleteOperation[3]

  const dispatch = useDispatch()
  const url_params = useParams()
  const { ticketCard, isUpdateTicket, redirect } = useSelector((e) => e.ticketCard)
  const [openWindow, setOpenWindow] = useState("")

  useEffect(() => {
    dispatch(getTicketCardThunk({ id: url_params.id }))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (redirect) {
      dispatch(changeIsRedirect())
      navigate("/propertyliens/ticket/")
    }
    // eslint-disable-next-line
  }, [redirect])

  useEffect(() => {
    setIsUpdate((e) => {
      // Обновление при удалении
      if (e.update === "update") {
        dispatch(getTicketCardThunk({ id: url_params.id }))
        return { update: false }
      } else {
        return e
      }
    })
    // eslint-disable-next-line
  }, [isUpdate])

  useEffect(() => {
    // Обновление при добавлении
    if (isUpdateTicket === true) {
      dispatch(getTicketCardThunk({ id: url_params.id }))
      setOpenWindow(false)
    }
    // eslint-disable-next-line
  }, [isUpdateTicket])

  if (ticketCard === null) {
    return "Londing..."
  }

  return (
    <div className={st.product}>
      <Title title="Залоговый билет" subtitle={ticketCard.number}></Title>

      <div className={st.info}>
        <Link to={getAbsoluteURL(ROUTES.CUSTOMER_ID, ticketCard ? ticketCard.customer_id : "")} className={st.customer}>
          {ticketCard.customer}
        </Link>
        <div className={st.items}>
          {/* <FieldReadOnly label="Номер билета" value={ticketCard.number} /> */}
          <FieldReadOnly label="Тариф" value={ticketCard.rate} />
          <FieldReadOnly label="Сумма займа" value={`${ticketCard.loan_amount} ₽`} />
          <FieldReadOnly label="Сумма уплаченных процентов" value={`${ticketCard.amount_interest_paid} ₽`} />
          <FieldReadOnly label="Дата займа" value={ticketCard.loan_date} />
          <FieldReadOnly label="Действие залога" value={ticketCard.сlosing_date} />
          <FieldReadOnly label="Статус билета" value={ticketCard.ticket_status} />
        </div>
      </div>

      <Actions ticketCard={ticketCard} openWindow={openWindow} setOpenWindow={setOpenWindow} />

      <TicketCardTablePropery property={ticketCard.collateral_property_set} />
      <TicketCardTablePayment payment={ticketCard.payment_security_ticket_set} DeleteOperationSet={DeleteOperation} />
    </div>
  )
}

export default TicketCard
