import { useEffect, useRef, useState } from "react"
import PopUpWarning from "../../components/PopUpWarning/PopUpWarning"
import { useDispatch, useSelector } from "react-redux"
import { deleteOperationsThunk } from "../../pages/OperationsPage/store/operationsSlice"

export default function useDeleteOperation(props) {
  const dispatch = useDispatch()

  const { remote_operations } = useSelector((e) => e.operations)
  const [popUpOpen, setPopUpOpen] = useState(false)
  const [isUpdate, setIsUpdate] = useState({ update: false })
  const refValuePopUp = useRef()

  useEffect(() => {
    // при добавлении в список удаленных элементов переменная update изменится
    if (remote_operations.includes(refValuePopUp.current) > 0) {
      setIsUpdate({ update: "update" })
    }
  }, [remote_operations])

  const PopUpOpen = (e) => {
    setPopUpOpen(true)
    refValuePopUp.current = e
  }

  const rejectionPopUp = () => {
    setPopUpOpen(false)
  }

  const confirmationPopUp = () => {
    setPopUpOpen(false)
    const params = { id: refValuePopUp.current }
    dispatch(deleteOperationsThunk(params)).then((e) => {
      if (!e.error) {
        if (props.finalAction) props.finalAction()
      }
    })
  }

  if (popUpOpen) {
    return [PopUpOpen, <PopUpWarning confirmation={confirmationPopUp} rejection={rejectionPopUp} />, isUpdate]
  } else {
    return [PopUpOpen, <></>, isUpdate, setIsUpdate]
  }
}
