import React from "react"
import { useState } from "react"
import st from "./Menu.module.scss"

import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { BoxUI, ButtonElem } from "../../shared"
import { convertDate } from "../../shared/utils/utils_date"
import dayjs from "dayjs"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"

const Menu = ({ onClickBtn, date, children }) => {
  const [dateList, setDateList] = useState(() => {
    if (date) {
      return { start: convertDate(date.start), end: convertDate(date.end) }
    }
    return { start: dayjs(new Date()), end: dayjs(new Date()) }
  })
  const [additionalMenuOpen, setAdditionalMenuOpen] = useState(false)

  const handleChange = (date, name) => {
    setDateList((e) => ({ ...e, [name]: date }))
  }

  const onClick = () => {
    const result = { start: convertDate(dateList.start), end: convertDate(dateList.end) }
    onClickBtn(result)
  }

  return (
    <BoxUI className={st.menu}>
      <div className={st.fields}>
        <div className={st.label}>Показывать записи с</div>
        <DatePicker
          slotProps={{ textField: { size: "small" } }}
          disableFuture
          value={dateList.start}
          onChange={(newValue) => handleChange(newValue, "start")}
          className={st.field}
        />
        <div>по </div>
        <DatePicker
          slotProps={{ textField: { size: "small" } }}
          disableFuture
          value={dateList.end}
          onChange={(newValue) => handleChange(newValue, "end")}
          className={st.field}
        />
        <ButtonElem className={st.btn} onClick={onClick}>
          ОК
        </ButtonElem>
        {children && (
          <SettingsOutlinedIcon className={st.icon} onClick={() => setAdditionalMenuOpen(!additionalMenuOpen)} />
        )}
      </div>
      {additionalMenuOpen && children}
    </BoxUI>
  )
}

export default Menu
