import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAccountTransactionsThunk } from "../store/baseSlice"
import FinanceFooter from "../../../components/FinanceFooter/FinanceFooter"
import TableAccountTransactions from "../../../components/TableAccountTransactions/TableAccountTransactions"
import AccountTransactionsMenu from "./AccountTransactionsMenu"

const AccountTransactions = () => {
  const dispatch = useDispatch()
  const { operationsIncome, operationsExpense } = useSelector((e) => e.accountTransactions)

  const getData = (props) => {
    let result = { bank_account: props.status }

    if (props.dateList.loanDate1 && props.dateList.loanDate2) {
      result = {
        ...result,
        start: props.dateList.loanDate1.format("DD.MM.YYYY"),
        end: props.dateList.loanDate2.format("DD.MM.YYYY"),
      }
    }

    dispatch(getAccountTransactionsThunk(result))
  }

  return (
    <>
      <AccountTransactionsMenu getData={getData} />
      <TableAccountTransactions Income={operationsIncome} Expense={operationsExpense}></TableAccountTransactions>
      <FinanceFooter Income={operationsIncome} Expense={operationsExpense} />
    </>
  )
}

export default AccountTransactions
