import React from "react"
import st from "./PopUpWarning.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import { ButtonElem } from "../../shared"

const PopUpWarning = ({ confirmation, rejection, text = "Вы уверены что хотите удалить операцию?" }) => {
  return (
    <div className={st.pop_up}>
      <div className={st.message}>
        <div className={st.close}>
          <CloseIcon className={st.icon} onClick={rejection} />
        </div>
        <div className={st.title}>{text}</div>
        <div className={st.wrapper_btn}>
          <ButtonElem className={st.btn} onClick={confirmation}>
            ДА
          </ButtonElem>
          <ButtonElem className={st.btn} onClick={rejection}>
            Нет
          </ButtonElem>
        </div>
      </div>
    </div>
  )
}

export default PopUpWarning
