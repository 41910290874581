import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import MenuTableСatalogProperty from "../Menu/Menu"
import { Link } from "react-router-dom"
import ROUTES, { getAbsoluteURL } from "../../../../application/routes/routes"
import { Table } from "../../../../components"
import { getPropertyThunk } from "../../store/propertyListSlice"

const PropertyList = () => {
  const dispatch = useDispatch()

  const { property } = useSelector((e) => e.propertyList)

  const onTicketSubmit = (status) => {
    let params = {}
    if (status !== "Все статусы") params = { ...params, status: status }
    dispatch(getPropertyThunk(params))
  }

  useEffect(() => {
    dispatch(getPropertyThunk())
  }, [dispatch])

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
    },

    {
      field: "product",
      headerName: "Наименование",
      flex: 1.5,
      renderCell: (props) => {
        const url = getAbsoluteURL(ROUTES.PROPERTY_ID, props.row.id)
        return <Link to={url}>{props.value}</Link>
      },
    },

    {
      field: "assessment_amount",
      headerName: "Сумма оценки",
      flex: 1,
      align: "right",
      type: "number",
    },

    {
      field: "subcategory",
      headerName: "Категория",
      flex: 1,
    },

    {
      field: "status",
      headerName: "Состояние",
      flex: 1,
    },

    {
      field: "customer",
      headerName: "Клиент",
      flex: 1.2,
    },

    {
      field: "Employee",
      headerName: "Сотрудник",
      flex: 1.2,
    },
  ]

  return (
    <div>
      <MenuTableСatalogProperty onTicketSubmit={onTicketSubmit} />

      <Table className="mt20" columns={columns} operations={property} />
    </div>
  )
}

export default PropertyList
