import PieChart from "../Chart/PieChart/PieChart"
import st from "./StatBox.module.scss"

const StatBox = (props) => {
  const { title, subtitle, icon, increase } = props

  return (
    <div className={st.container}>
      <div className={st.column1}>
        {icon}
        <h4 className={st.number}>{title}</h4>
        <h5 className={st.text1}>{subtitle}</h5>
      </div>

      <div className={st.column2}>
        <div className={st.pie_chart}>
          <PieChart procent={increase} />
        </div>
        <h5 className={st.percent}>{increase}%</h5>
      </div>
    </div>
  )
}

export default StatBox
