import { createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { abbreviatedThunk } from "../../../shared/utils/utils_abbreviatedThunk"

const _nameStore = "team"

export const getEmployeeThunk = abbreviatedThunk({ _nameStore, api_request: API.getEmployee })
export const createEmployeeThunk = abbreviatedThunk({ _nameStore, api_request: API.createEmployee })

const teamSlice = createSlice({
  name: "team",
  initialState: {
    employees: null,
    isCreateEmployee: false,
  },
  reducers: {
    changeStatusIsCreateEmployee: (state) => {
      state.isCreateEmployee = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployeeThunk.fulfilled, (state, action) => {
      state.employees = action.payload
    })
    // ======================================================
    builder.addCase(createEmployeeThunk.fulfilled, (state, action) => {
      state.isCreateEmployee = true
    })
  },
})

export const { changeStatusIsCreateEmployee } = teamSlice.actions

export default teamSlice.reducer
