import { useEffect, useState } from "react"
import { FieldSelect, useGetCategories } from "../../shared"

const CategorySelect = (props) => {
  const onChange = props.onChange
  const category = useGetCategories()[0]
  const [valueCat, setValueCat] = useState("")
  const [valueSubCat, setValueSubCat] = useState("")
  const [SubCatList, setValueSubList] = useState([])

  const getSubCategoryList = (catID) => {
    const itemCat = category.filter((item) => item.id === catID)
    setValueSubList(itemCat[0].subcategories)
  }

  useEffect(() => {
    if (props.cat) {
      setValueCat(props.cat)
      getSubCategoryList(props.cat)
    }
    if (props.subCat) setValueSubCat(props.subCat)
    // eslint-disable-next-line
  }, [])

  const onCategoryChange = (e) => {
    setValueCat(e.target.value)
    getSubCategoryList(e.target.value)
    setValueSubCat("")
    onChange({ category: e.target.value, subCategory: "" })
  }

  const onSubCategoryChange = (e) => {
    setValueSubCat(e.target.value)
    onChange({ category: valueCat, subCategory: e.target.value })
  }

  if (onChange === undefined) {
    console.error("не передана функция onChange")
    return <div></div>
  }

  return (
    <>
      <FieldSelect
        label="Категория"
        fun={onCategoryChange}
        list={category}
        id="category"
        value={valueCat}
      ></FieldSelect>

      {valueCat && (
        <FieldSelect
          label="Подкатегория"
          fun={onSubCategoryChange}
          list={SubCatList}
          id="subcategory"
          value={valueSubCat}
        ></FieldSelect>
      )}
    </>
  )
}

export default CategorySelect
