import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from '../api/api'
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../NotificationMessages"


export const addOtherOperationThunk = createAsyncThunk('otherOperation/addOtherOperation', async (query, thunkAPI) => {
    try {
        const request = await API.addOtherOperation(query)
        thunkAPI.dispatch(addNotification("Приходно расходная операция добавлена"))
        return request

    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})

export const getOperationTypeThunk = createAsyncThunk('otherOperation/getOperationType', async (query, thunkAPI) => {
    try {
        const request = await API.getOperationType(query)
        return request

    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})


const otherOperationSlice = createSlice({
    name: 'otherOperation',
    initialState: {
        SuccessAddOtherOperation: false,
        operationsOthers: [],
    },
    reducers: {
        changeStatusAddOtherOperation: (state) => {
            state.SuccessAddOtherOperation = false

        },
    },
    extraReducers: (builder) => {
        // 
        builder.addCase(addOtherOperationThunk.fulfilled, (state, action) => {
            state.SuccessAddOtherOperation = true
        })
        // ======================================================
        builder.addCase(getOperationTypeThunk.fulfilled, (state, action) => {
            state.operationsOthers = action.payload
        })

    }
})

export const {
    changeStatusAddOtherOperation,
} = otherOperationSlice.actions

export default otherOperationSlice.reducer