import React, { useState } from "react"
import TableBankAccounts from "./TableBankAccounts"
import MenuTable from "./MenuTable"
import CreateBankAccount from "./CreateBankAccount"

const BankAccounts = () => {
  const [showTable, createBankAccount] = ["showTable", "createBankAccount"]
  const [typeForm, setTypeForm] = useState(showTable)

  return (
    <div>
      {typeForm === showTable && (
        <>
          <MenuTable changeForm={() => setTypeForm(createBankAccount)} />
          <TableBankAccounts />
        </>
      )}

      {typeForm === createBankAccount && <CreateBankAccount changeForm={() => setTypeForm(showTable)} />}
    </div>
  )
}

export default BankAccounts
