import st from "../СustomerCard/СustomerCard.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { Table } from "../../../../components"
import { getTicketsThunk } from "../../../../features/globalSlice"
import ROUTES, { getAbsoluteURL } from "../../../../application/routes/routes"

const TicketsCustomer = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { ticket } = useSelector((e) => e.global)

  useEffect(() => {
    if (id) {
      dispatch(getTicketsThunk({ customer: id }))
    }
  }, [id, dispatch])

  const columnsTable1 = [
    {
      field: "number",
      headerName: "Номер договора",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 1,

      renderCell: (props) => {
        const url = getAbsoluteURL(ROUTES.TICKET_ID, props.row.id)
        return <Link to={url}>{props.value}</Link>
      },
    },

    {
      field: "rate",
      headerName: "Тариф",
      flex: 1,
    },

    {
      field: "loan_amount",
      headerName: "Сумма займа",
      flex: 1,
    },

    {
      field: "amount_interest_paid",
      headerName: "Уплаченные проценты",
      flex: 1,
    },

    {
      field: "сlosing_date",
      headerName: "Действие залога",
      // type: "date",
      flex: 1,
    },

    {
      field: "customer",
      headerName: "Клиент",
      flex: 1.2,
    },

    {
      field: "ticket_status",
      headerName: "Статус",
      flex: 1,
    },

    {
      field: "closed_date",
      headerName: "Дата закрытия",
      // type: "date",
      flex: 1,
    },
  ]

  return (
    <div className={st.table_wrapper}>
      <div className={st.title}>Залоги</div>
      <Table className={st.table} columns={columnsTable1} operations={ticket} />
    </div>
  )
}

export default TicketsCustomer
