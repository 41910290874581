import st from "../style/CreateProperty.module.scss"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import * as yup from "yup"
import { ButtonElem, ButtonHref, FieldSearchProduct, FieldText } from "../../../shared"
import { validationNumberField } from "../../../shared/utils/utils"
import { getProductNameThunk } from "../../../features/globalSlice"
import { CreateСategory } from "../../CreateСategory"
import CategorySelect from "../../../components/CategorySelect/CategorySelect"

const CreateProperty = ({ property, closeFormAddProperty, addPropertyFun }) => {
  const dispatch = useDispatch()
  const { product_name } = useSelector((e) => e.global)
  const [typeForm, setTypeForm] = useState(1)

  const required = "Обязательное поле"
  const checkoutSchema = yup.object().shape({
    // product: yup.string().required(required),
    // price_purchase: yup.string().required(required),
    brand: yup.string().required(required),
    assessment_amount: yup.string().required(required),
  })

  const formik = useFormik({
    initialValues: {
      id: "",
      category: "",
      subcategory: "",
      product: "",
      assessment_amount: "",
      brand: "",
      comment: "",
      ...property,
    },
    onSubmit: (value) => {
      addPropertyFun(value)
    },
    validationSchema: checkoutSchema,
  })

  useEffect(() => {
    if (formik.values.subcategory) {
      dispatch(getProductNameThunk({ cat: formik.values.category, subcat: formik.values.subcategory }))
    }
  }, [formik.values.subcategory, formik.values.category, dispatch])

  const onChangeCategory = ({ category, subCategory }) => {
    formik.setFieldValue("category", category)
    formik.setFieldValue("subcategory", subCategory)
  }

  const onFieldBrandChange = (e) => {
    for (let item of product_name) {
      if (item.id === e) {
        formik.setFieldValue("brand", item.brand)
        break
      }
    }
  }

  if (typeForm === 2) return <CreateСategory setTypeForm={() => setTypeForm(1)} />

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <CategorySelect onChange={onChangeCategory} cat={formik.values.category} subCat={formik.values.subcategory} />

        {formik.values.subcategory && (
          <>
            <FieldSearchProduct
              id="product"
              label="Наименование"
              list={product_name}
              value={formik.values.product}
              fun={(e, data) => {
                if (!data) return formik.setFieldValue("product", "")
                if (!data.label) return formik.setFieldValue("product", data)
                if (data.id) onFieldBrandChange(data.id)
                return formik.setFieldValue("product", data.label)
              }}
            ></FieldSearchProduct>

            <FieldText
              label="Бренд"
              id="brand"
              placeholder="Xiaomi"
              value={formik.values.brand}
              onChange={formik.handleChange}
              error={{ touched: formik.touched.brand, message: formik.errors.brand }}
            ></FieldText>

            <FieldText
              label="Сумма оценки:"
              id="assessment_amount"
              placeholder="Пример: 700 ₽"
              value={formik.values.assessment_amount}
              onChange={(props) => {
                const result = validationNumberField({ value: props.target.value, length: 6 })
                formik.setFieldValue("assessment_amount", result)
              }}
              error={{ touched: formik.touched.assessment_amount, message: formik.errors.assessment_amount }}
            ></FieldText>

            <FieldText
              label="Коментарий"
              id="comment"
              placeholder="Тут твои заметки..."
              value={formik.values.comment}
              onChange={formik.handleChange}
            ></FieldText>
          </>
        )}

        <div className={st.btn_wrapper}>
          {formik.values.product ? <ButtonElem type="submit">Сохранить</ButtonElem> : <div></div>}

          <ButtonHref type="button" onClick={closeFormAddProperty}>
            Отмена
          </ButtonHref>
        </div>
      </form>
      <div className="mt20">
        <ButtonHref onClick={() => setTypeForm(2)}>Добавить новую категорию</ButtonHref>
      </div>
    </>
  )
}

export default CreateProperty
