import axios from "axios"
const baseURL_v1 = "https://crm.ch-shop.ru/api/v1"
const baseURL_v2 = "https://crm.ch-shop.ru/api/v2"

const getToken = () => {
  const token = localStorage.getItem("token")
  return token ? { Authorization: `Token ${token}` } : ""
}

export const addHeaders = () => {
  const header = getToken()
  const axios_list = [axiosBase, axiosBase2]

  axios_list.map((item) => {
    const headers = {
      ...item.defaults.headers,
      ...header,
    }
    item.defaults.headers = headers
    return item
  })
}

export const axiosBase = axios.create({
  baseURL: baseURL_v1,
  headers: getToken(),
})

export const axiosBase2 = axios.create({
  baseURL: baseURL_v2,
  headers: getToken(),
})

export const globalAPI = {
  async category() {
    const response = await axiosBase.get(`/categories/`)
    return response.data
  },

  async category2() {
    const response = await axiosBase.get(`/category2/`)
    return response.data
  },

  async getProductName(props = {}) {
    const params = { cat: props["cat"], subcat: props["subcat"] }
    const response = await axiosBase.get(`/productname/`, { params: params })
    return response.data
  },

  async categories(props = {}) {
    const response = await axiosBase.get("/categories/", { params: props })
    return response.data
  },

  async getTickets(query) {
    const response = await axiosBase.get("/ticket/", { params: query })
    return response.data
  },

  async getBankAccountsLiteAPI(params) {
    debugger
    const response = await axiosBase.get("/finance/bank_accounts_lite/", params)
    return response.data
  },
}
