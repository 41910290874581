import DeleteIcon from "@mui/icons-material/Delete"
import st from "./DeleteIconTable.module.scss"

const DeleteIconTable = ({ deleteOperation }) => {
  return (
    <div>
      <DeleteIcon className={st.icon_delete} onClick={deleteOperation} />
    </div>
  )
}

export default DeleteIconTable
