import st from "./SettingsPage.module.scss"
import Header from "../../components/Title/Title"
import ROUTES from "../../application/routes/routes"
import { Outlet } from "react-router-dom"
import { ButtonLink } from "../../shared"

const SettingsPage = (props) => {
  const { subtitle = "" } = props

  return (
    <div>
      <Header
        title="Настройки"
        subtitle={subtitle}
      ></Header>

      <div className={st.items}>
        <ButtonLink link={ROUTES.TEAM}>Сотрудники</ButtonLink>
        <ButtonLink link={ROUTES.BANK_ACCOUNTS}>Банковские счета</ButtonLink>
        <ButtonLink link={ROUTES.INVOICES}>TEST</ButtonLink>
        <ButtonLink link={ROUTES.FAQ}>TEST</ButtonLink>
        <ButtonLink link={ROUTES.DATA_TRANSFER}>Перенос данных</ButtonLink>
      </div>
      <div className={st.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default SettingsPage
