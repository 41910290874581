import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import st from "./PropertyCard.module.scss"

import { BoxUI, ButtonElem, useGetCategories } from "../../../../shared"
import { Title } from "../../../../components"
import { getPropertyCardThunk } from "../../store/propertyCardSlice"
import FormEdit from "../FormEdit/FormEdit"
import FormNoEdit from "../FormNoEdit/FormNoEdit"

const PropertyCard = () => {
  const dispatch = useDispatch()
  const url_params = useParams()
  const [editMode, setEditMode] = useState(false)
  const { propertyId } = useSelector((e) => e.propertyCard)
  const category = useGetCategories()[0]

  useEffect(() => {
    dispatch(getPropertyCardThunk({ id: url_params.id }))
    // eslint-disable-next-line
  }, [])

  if (propertyId === null) {
    return "Londing..."
  }

  return (
    <div className={st.wrapper}>
      <Title title="Страница имущества" subtitle={propertyId.product}></Title>

      <BoxUI className={st.info}>
        <ButtonElem className={st.btn_edit} onClick={() => setEditMode(!editMode)}>
          Изменить
        </ButtonElem>
        {editMode ? <FormEdit propertyId={propertyId} category={category} /> : <FormNoEdit propertyId={propertyId} />}
      </BoxUI>
    </div>
  )
}

export default PropertyCard
