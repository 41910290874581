import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import st from "./Base.module.scss"
import { useEffect, useRef } from "react"

const AdditionalMenuBase = ({ dataMenu, setDataMenu, fields, list, setList }) => {
  const firstRrender = useRef({ updateList: true, filterList: true })

  // фильтр списка
  const onChangeFilter = (props) => {
    const filter_operations = list.filter((e) => {
      const result = Object.keys(props).map((key) => {
        return props[key] === e[key] || props[key] === "Все" ? true : false
      })

      return result.every((e) => e === true)
    })

    setList(filter_operations)
  }

  // запись изменений в полях
  const handleChange = (e) => {
    setDataMenu({ ...dataMenu, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (firstRrender.current.filterList === false) onChangeFilter(dataMenu)
    else firstRrender.current.filterList = false
    // eslint-disable-next-line
  }, [dataMenu])

  useEffect(() => {
    if (firstRrender.current.updateList === false) {
      let data = {}
      fields.map((e) => (data[e.name] = "Все"))
      setDataMenu(data)
    } else {
      firstRrender.current.updateList = false
    }
    // eslint-disable-next-line
  }, [list])

  return (
    <div className={st.wrapper}>
      <div className={st.column}>
        {fields.map((field) => {
          return (
            <div key={field.name} className={st.item}>
              <div className={st.title}>{field.title}:</div>
              <Select
                className={st.select}
                name={field.name}
                size="small"
                value={dataMenu[field.name] || field.list[0].value}
                onChange={handleChange}
              >
                {field.list.map((e) => {
                  return (
                    <MenuItem key={e.id} value={e.value}>
                      {e.value}
                    </MenuItem>
                  )
                })}
              </Select>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AdditionalMenuBase
