import { useDispatch } from "react-redux"
import st from "./Message.module.scss"
import { useEffect } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { BoxUI } from "../../../../shared"
import { disableDisplaynNotification } from "../../store/NotificationMessageSlice"

const Message = ({ message }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const timeId2 = setTimeout(() => {
      dispatch(disableDisplaynNotification(message))
    }, 12000)

    return () => {
      clearTimeout(timeId2)
    }
    // eslint-disable-next-line
  }, [])

  const hideNotification = (message) => {
    dispatch(disableDisplaynNotification(message))
  }

  return (
    <BoxUI className={st.message}>
      <div className={st.title}>
        <CloseIcon className={st.icon} onClick={() => hideNotification(message)} />
      </div>
      <div>{message.name}</div>
    </BoxUI>
  )
}

export default Message
