import st from "../style/OtherOperation.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import OtherOperationForm from "./OtherOperationForm"
import { useNavigate } from "react-router-dom"
import { AddUser } from "../../AddUser"
import { SwitchingForm } from "../../../components"
import { BoxUI, ContainerForm } from "../../../shared"
import { convertDate, convertTime } from "../../../shared/utils/utils_date"
import { addOtherOperationThunk, changeStatusAddOtherOperation } from "../store/otherOperationSlice"

const OtherOperatio = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { SuccessAddOtherOperation } = useSelector((e) => e.otherOperation)
  const [stepForm, setStepForm] = useState(1)
  const [selectClient, setSelectClient] = useState("")
  const stepActive = [!!selectClient]

  useEffect(() => {
    if (SuccessAddOtherOperation === true) {
      dispatch(changeStatusAddOtherOperation())
      navigate("/operations")
    }
    // eslint-disable-next-line
  }, [SuccessAddOtherOperation, navigate])

  const submitForm = (otherOperation) => {
    const date = `${convertDate(otherOperation.date)} ${convertTime(otherOperation.time)}`

    const data = {
      date_create: date,
      description: otherOperation.description,
      payment_method: otherOperation.calculationMethod,
      type_operation: otherOperation.typeOperation,
      type_operation_id: otherOperation.nameOperation,
      amount: otherOperation.amount,
      customer: selectClient.id,
    }
    dispatch(addOtherOperationThunk(data))
  }

  const isStepForm = (numberForm) => {
    if (numberForm === 1) {
      return <AddUser setSelectClient={setSelectClient} selectClient={selectClient} />
    } else if (numberForm === 2) {
      return <OtherOperationForm submitForm={submitForm} />
    }
  }

  return (
    <div className={st.new_operation}>
      <ContainerForm>
        <BoxUI>
          <SwitchingForm setStepForm={setStepForm} stepForm={stepForm} stepActive={stepActive} />
          {isStepForm(stepForm)}
        </BoxUI>
      </ContainerForm>
    </div>
  )
}

export default OtherOperatio
