// import { getSubCategoryId } from "../../../../shared/utils/utils"

import { getSubCategoryId } from "../../../shared/utils/utils"


export const filterProducts = (value, products, category) => {
    const condition_list = { 0: "Все", 1: "Новый", 2: "Б/У" }

    if (value === null) return products

    const { brand, сondition, subCat } = value

    const result = products.filter((e) => {
        const _brand = brand === "Все" ? true : e.brand === brand
        console.log(e)
        const term = сondition === "0" || condition_list[сondition] === e.condition
        const _сondition = term ? true : false

        const slugCat = getSubCategoryId({ category: category, slug: subCat })
        const _subcategory = e.subcategory === slugCat

        return _subcategory && _сondition && _brand
    })

    return result
}