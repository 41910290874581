import { Title } from "../../../components"
import BaseFormFields from "../../../components/BaseFormFields/BaseFormFields"
import { useDispatch, useSelector } from "react-redux"
import { changeStatusRequest, passwordChangeEmployeeThunk } from "../store/employeeStore"
import { useEffect } from "react"

const EmployeeEditPassword = ({ closeForm, userId }) => {
  const dispatch = useDispatch()
  const { requestCompleted } = useSelector((e) => e.employee)

  const fields = [
    { id: "old_password", label: "Старый пароль", placeholder: "12345678", validation: "string" },
    { id: "password", label: "Пароль для входа", placeholder: "fwaofj@0_waof", validation: "password" },
    { id: "password2", label: "Ещё раз пароль", placeholder: "fwaofj@0_waof", validation: "passwordConfirmation" },
  ]

  const passwordChangeFun = (props) => {
    const params = { id: userId, old_password: props.old_password, password: props.password }
    dispatch(passwordChangeEmployeeThunk(params))
  }

  useEffect(() => {
    if (requestCompleted.passwordChange) {
      dispatch(changeStatusRequest("passwordChange"))
      closeForm()
    }
    // eslint-disable-next-line
  }, [requestCompleted])

  return (
    <div>
      <Title title="" subtitle={"Смена пароля"}></Title>
      <BaseFormFields fields={fields} closeForm={closeForm} SubmitForm={passwordChangeFun} />
    </div>
  )
}

export default EmployeeEditPassword
