import React, { useEffect, useState } from "react"
import EmployeeEditForm from "./EmployeeEditForm"
import EmployeeNoEditForm from "./EmployeeNoEditForm"

import { useDispatch, useSelector } from "react-redux"
import { getEmployeeIdThunk } from "../store/employeeStore"
import { useParams } from "react-router-dom"
import EmployeeEditEmail from "./EmployeeEditEmail"
import EmployeeEditPassword from "./EmployeeEditPassword"

const Employee = () => {
  const dispatch = useDispatch()
  const id_url = useParams()
  const [formEdit, formNoEdit, formEmail, formPassword] = ["formEdit", "formNoEdit", "formEmail", "formPassword"]
  const [typeForm, setTypeForm] = useState(formNoEdit)
  const { employeeId, requestCompleted } = useSelector((e) => e.employee)

  useEffect(() => {
    dispatch(getEmployeeIdThunk(id_url))
    // eslint-disable-next-line
  }, [requestCompleted])

  const paramsForm = { closeForm: () => setTypeForm(formNoEdit), userId: id_url.id }

  return (
    <>
      {typeForm === formEdit && <EmployeeEditForm {...paramsForm} data={employeeId} />}
      {typeForm === formEmail && <EmployeeEditEmail {...paramsForm} />}
      {typeForm === formPassword && <EmployeeEditPassword {...paramsForm} />}

      {typeForm === formNoEdit && (
        <EmployeeNoEditForm
          data={employeeId}
          formEditActive={() => setTypeForm(formEdit)}
          formEmailActive={() => setTypeForm(formEmail)}
          formPasswordActive={() => setTypeForm(formPassword)}
        />
      )}
      {/* {typeForm === formEdit && <div bankAccount={bankAccount} setTypeForm={() => setTypeForm(formNoEdit)} />} */}
    </>
  )
}

export default Employee
