import { useFormik } from "formik"
import { useState } from "react"
import { CreateСategory } from "../../../CreateСategory"
import FormReadOnly from "./FormReadOnly"
import FormWrite from "./FormWrite"

const AddProduct = ({ selectProduct, setSelectProduct }) => {
  const [typeForm, setTypeForm] = useState(1)

  const condition_list = [
    { id: 1, name: "Б/У" },
    { id: 2, name: "Новый" },
  ]

  const formik = useFormik({
    initialValues: {
      id: "",
      category: "",
      subcategory: "",
      product: "",
      brand: "",
      condition: "",
      ...selectProduct,
    },
    onSubmit: (value) => {
      setSelectProduct(value)
    },
  })

  const clearActionProduct = () => {
    formik.resetForm()
    setSelectProduct("")
  }

  if (typeForm === 1) {
    return (
      <>
        {!!selectProduct.product ? (
          <FormReadOnly formik={formik} clearActionProduct={clearActionProduct} condition_list={condition_list} />
        ) : (
          <FormWrite formik={formik} condition_list={condition_list} setTypeForm={setTypeForm} />
        )}
      </>
    )
  } else if (typeForm === 2) {
    return <CreateСategory setTypeForm={() => setTypeForm(1)} />
  }
}

export default AddProduct
