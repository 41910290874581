import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import dayjs from "dayjs"
import { ButtonElem, FieldDate, TimeFieldUI, FieldText, FieldSelect } from "../../../shared"
import { getOperationTypeThunk } from "../store/otherOperationSlice"
import useGetBankAccounts from "../../../shared/hooks/useGetBankAccounts"
import { validationNumberField } from "../../../shared/utils/utils"

const OtherOperationForm = ({ submitForm }) => {
  const dispatch = useDispatch()
  const { operationsOthers } = useSelector((e) => e.otherOperation)

  const bank_accounts = useGetBankAccounts()

  useEffect(() => {
    dispatch(getOperationTypeThunk())
  }, [dispatch])

  const formik = useFormik({
    initialValues: {
      typeOperation: "",
      calculationMethod: "",
      nameOperation: "",
      description: "",
      amount: "",
      date: dayjs(new Date()),
      time: dayjs(new Date()),
    },
    onSubmit: (value) => {
      const { typeOperation, ...newValue } = value

      submitForm(newValue)
    },
  })

  const getDescriptionFilter = (_list) => {
    const result = _list.filter((e) => {
      const account_type_form = e.сalculation_method === "cash" ? 1 : 2
      const account_type_serv = bank_accounts.filter((e) => e.id === formik.values.calculationMethod)

      const m1 = e.type_operation === formik.values.typeOperation
      const m2 = account_type_form === account_type_serv[0].type_calculation
      return m1 && m2
    })

    return result
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FieldSelect
          label="Тип операции"
          fun={(e) => {
            formik.setFieldValue("calculationMethod", "")
            formik.setFieldValue("nameOperation", "")
            formik.handleChange(e)
          }}
          list={operationsOthers.operations_type ? operationsOthers.operations_type : []}
          id="typeOperation"
          value={formik.values.typeOperation}
        ></FieldSelect>

        {formik.values.typeOperation && (
          <FieldSelect
            label="Способ расчета"
            fun={(e) => {
              formik.setFieldValue("calculationMethod", "")
              formik.setFieldValue("nameOperation", "")
              formik.handleChange(e)
            }}
            // list={operationsOthers.calculation_method_choices ? operationsOthers.calculation_method_choices : []}
            list={bank_accounts ? bank_accounts : []}
            id="calculationMethod"
            value={formik.values.calculationMethod}
          ></FieldSelect>
        )}

        {formik.values.calculationMethod && (
          <FieldSelect
            label="Основание"
            fun={formik.handleChange}
            list={getDescriptionFilter(operationsOthers.data)}
            id="nameOperation"
            value={formik.values.nameOperation}
          ></FieldSelect>
        )}

        {formik.values.nameOperation && (
          <>
            <FieldText
              label="Сумма"
              id="amount"
              placeholder="1000 ₽"
              value={formik.values.amount}
              onChange={(props) => {
                const result = validationNumberField({ value: props.target.value, length: 6 })
                formik.setFieldValue("amount", result)
              }}
            ></FieldText>

            <FieldText
              label="Коментарий"
              id="description"
              placeholder="Пример: за декабрь"
              value={formik.values.description}
              onChange={formik.handleChange}
            ></FieldText>

            <FieldDate
              label="Дата/время операции"
              id="date"
              // error={{ touched: formik.touched.date, message: formik.errors.date }}
              value={formik.values.date}
              fun={(value) => formik.setFieldValue("date", value)}
            ></FieldDate>

            <TimeFieldUI
              label="Время операции"
              id="time"
              fun={(e) => formik.setFieldValue("time", e)}
              value={formik.values.time}
            ></TimeFieldUI>
          </>
        )}

        <div className="mt20">
          <ButtonElem type="submit" color="secondary" variant="contained">
            Сохранить
          </ButtonElem>
        </div>
      </form>
    </>
  )
}

export default OtherOperationForm
