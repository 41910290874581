import st from "../style/NewOperation.module.scss"
import { useEffect, useState } from "react"
import AddProperty from "./AddProperty"
import { BoxUI, ContainerForm } from "../../../shared"
import { AddUser } from "../../AddUser"
import { SwitchingForm } from "../../../components"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { addSecurityTicketThunk, changeStatusAddSecurityTicket } from "../store/securityTicketFormSlice"
import CashRegisterForm from "../../CashRegisterForm/components/CashRegisterForm"

const SecurityTicketForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [stepForm, setStepForm] = useState(1)
  const [selectClient, setSelectClient] = useState("")
  const [selectProperty, setSelectProperty] = useState([])
  const [rateTicketSelect, setRateTicketSelect] = useState("")
  const arr = [!!rateTicketSelect, !!selectProperty[0]]
  const permissionForm2 = arr.every((element) => element)

  const stepActive = [!!selectClient, permissionForm2]
  const { SuccessAddSecurityTicket } = useSelector((e) => e.securityTicketForm)

  useEffect(() => {
    if (SuccessAddSecurityTicket === true) {
      dispatch(changeStatusAddSecurityTicket())
      navigate("/operations")
    }
    // eslint-disable-next-line
  }, [SuccessAddSecurityTicket, navigate])

  const finish = (res) => {
    const result = {
      customer: selectClient.id,
      products: selectProperty,
      rate: rateTicketSelect,
      loan_date: res.date,
      loan_amount: res.price_purchase,
      payments: res.payments,
    }

    dispatch(addSecurityTicketThunk(result))
  }

  const isStepForm = (numberForm) => {
    if (numberForm === 1) {
      return <AddUser setSelectClient={setSelectClient} selectClient={selectClient} />
    } else if (numberForm === 2) {
      return (
        <AddProperty
          selectProperty={selectProperty}
          setSelectProperty={setSelectProperty}
          rateTicketSelect={rateTicketSelect}
          setRateTicketSelect={setRateTicketSelect}
        />
      )
    } else if (numberForm === 3) {
      return <CashRegisterForm finish={finish} typeOperation={"security_ticket"} />
    }
  }

  return (
    <div className={st.new_operation}>
      <ContainerForm>
        <BoxUI>
          <SwitchingForm setStepForm={setStepForm} stepForm={stepForm} stepActive={stepActive} />
          {isStepForm(stepForm)}
        </BoxUI>
      </ContainerForm>
    </div>
  )
}

export default SecurityTicketForm
