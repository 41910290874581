import { ButtonHref } from "../.."
import st from "./Field.module.scss"

const FieldForm = (props) => {
  const { label, fun, selectObj } = props

  return (
    <div className={st.field}>
      <label className={st.label}>
        {label}
        <span>*</span>
      </label>

      <div className={st.field}>
        {selectObj ? (
          <div className={st.wrapper}>
            <ButtonHref type="button" onClick={fun}>
              Изменить Паспорт
            </ButtonHref>
            {/* <DeleteForeverIcon className={st.icon} onClick={() => deleteProperty()} /> */}
          </div>
        ) : (
          <ButtonHref type="button" className={st.btn} onClick={fun}>
            Добавить Паспорт
          </ButtonHref>
        )}
      </div>
    </div>
  )
}

export default FieldForm

// .field {
//     width: 300px;

//     .btn {
//       margin-top: 20px;
//     }

//     .item {
//       margin-top: 10px;

//       .wrapper {
//         position: relative;
//         display: inline;

//         .icon {
//           font-size: 28px;
//           position: absolute;
//           top: -6px;
//           right: -34px;
//           cursor: pointer;
//         }
//       }
//     }
//   }
