import st from "./Field.module.scss"

export default function FieldTextEmpty(props) {
  const { label, valueField } = props

  return (
    <div className={st.field}>
      <label className={st.label}>
        {label}
        <span>*</span>
      </label>

      <>{valueField()}</>
    </div>
  )
}
