import React, { useEffect, useState } from "react"
import st from "./Authorization.module.scss"
import fon from "../../../../styles/fon_compressed.mp4"
import { useFormik } from "formik"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { authThunk } from "../../store/authorizationSlice"

const Authorization = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loadedFon, setLoadedFon] = useState(false)
  const { IsAuth } = useSelector((e) => e.authorization)

  const required = "Обязательное поле"
  const checkoutSchema = yup.object().shape({
    login: yup.string().email("Неверный формат email").required(required),
    password: yup.string().required(required),
  })

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    onSubmit: (value) => {
      dispatch(authThunk(value))
    },
    validationSchema: checkoutSchema,
  })

  const getErrorValidate = (name) => {
    if (formik.touched[name] && formik.errors[name]) return formik.errors[name]
  }

  useEffect(() => {
    if (IsAuth) {
      navigate("/")
    }
    // eslint-disable-next-line
  }, [IsAuth])

  return (
    <div className={st.wrapper}>
      <div className={st.title}>CRM</div>
      <div className={st.video_wrapper}>
        <video className={st.video} src={fon} onLoadedMetadata={() => setLoadedFon(true)} autoPlay muted loop></video>
      </div>
      {loadedFon === false ? (
        <div>Loading...</div>
      ) : (
        <div className={st.form}>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <input
              id="login"
              className={st.input}
              type="string"
              placeholder="E-mail"
              autoComplete="off"
              value={formik.values.login}
              onChange={formik.handleChange}
              noValidate="novalidate"
            />
            {<div className={st.error}>{getErrorValidate("login")}</div> || ""}

            <input
              id="password"
              className={st.input}
              type="password"
              placeholder="Пароль"
              autoComplete="new-password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {<div className={st.error}>{getErrorValidate("password")}</div> || ""}

            <button className={st.button} type="submit">
              ВОЙТИ
            </button>
          </form>
        </div>
      )}
    </div>
  )
}

export default Authorization
