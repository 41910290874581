import { Link } from "react-router-dom"
import st from "../TicketCard/TicketCard.module.scss"
import ROUTES, { getAbsoluteURL } from "../../../../application/routes/routes"
import { Table } from "../../../../components"

const TicketCardTablePropery = ({ property }) => {
  const columnsTable1 = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
    },

    {
      field: "product",
      headerName: "Имущество",
      flex: 1,
      renderCell: (props) => {
        const url = getAbsoluteURL(ROUTES.PROPERTY_ID, props.row.id)
        return <Link to={url}>{props.value}</Link>
      },
    },

    {
      field: "subcategory",
      headerName: "Категория",
      flex: 1,
    },

    {
      field: "brand",
      headerName: "Бренд",
      flex: 1,
    },

    // {
    //   field: "quantity",
    //   headerName: "Количество",
    //   type: "number",
    //   headerAlign: "left",
    //   align: "left",
    // },

    {
      field: "assessment_amount",
      headerName: "Сумма оценки",
      flex: 0.5,
      renderCell: (props) => {
        return <div>{`${props.value} ₽`}</div>
      },
    },

    {
      field: "status",
      headerName: "Статус",
      flex: 0.5,
    },

    // {
    //   field: "customer1",
    //   headerName: "Сотрудник",
    //   flex: 1,
    //   // cellClassName: st.bigcell,
    //   // renderCell: (props) => {
    //   //   return <Link to={`/product/${props.row.id}`}>{props.value}</Link>
    //   // },
    // },

    // {
    //   field: "subcategory",
    //   headerName: "Функции",
    //   flex: 0.5,
    // },
  ]

  return (
    <div className={st.tables}>
      <div className={st.wrapper}>
        <div className={st.title}>Залоговое имущество</div>
        <Table className={st.table} columns={columnsTable1} operations={property} />
      </div>
    </div>
  )
}

export default TicketCardTablePropery
