import { createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { abbreviatedThunk } from "../../../shared/utils/utils_abbreviatedThunk"

const _nameStore = "SelectСategory"

export const createCategoryThunk = abbreviatedThunk({ _nameStore, api_request: API.createCategoryAPI })
export const selectCategoryThunk = abbreviatedThunk({ _nameStore, api_request: API.selectCategoryAPI })

const slice = createSlice({
  name: _nameStore,
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {},
})

export default slice.reducer
