import { useDispatch } from "react-redux"
import { FieldReadOnly, ContainerForm, BoxUI, Container600 } from "../../../shared"
import ButtonBase from "../../../shared/UI/ButtonBase/ButtonBase"
import {
  editBrandNameThunk,
  editCategoryThunk,
  editPriceProductThunk,
  editProductNameThunk,
} from "../store/productSlice"
import st from "../style/ProductCard.module.scss"
import FieldTextEdit from "./FieldTextEdit"
import usePopUp from "../../../shared/hooks/usePopUp/usePopUp"
import SelectСategory from "../../../modules/SelectСategory/components/SelectСategory"
import useGetCategories2 from "../../../shared/hooks/useGetCategories2"
import SelectBrand from "./SelectBrand"

const FormNoEdit = ({ pageProduct, onUpdateData }) => {
  const dispatch = useDispatch()
  const {
    categories: fullCategory,
    onUpdate: onUpdateCat,
    categoryLoading: isLoading,
    categoryInit: initialCat,
  } = useGetCategories2()
  const { product, price_on_display, total_quantity, brand } = pageProduct

  const filterCategory = fullCategory.filter((e) => {
    if (pageProduct.category === Number(e.category_id) && Number(e.subcategory_id) === pageProduct.subcategory) {
      return e
    }
    return false
  })

  const currentCategory = filterCategory.length ? filterCategory[0] : ""
  const condition = Number(pageProduct.condition) === 2 ? "Новый" : "Бывший в употреблении"

  const closePopUpMixin = (e) => {
    if (!e.error) {
      onUpdateData()
      closePopUp()
    }
  }

  const onEditCategory = ([e, name]) => {
    const params = { ...e, name }
    dispatch(editCategoryThunk(params)).then((e) => {
      closePopUpMixin(e)
      onUpdateCat()
    })
  }

  const onEditProductName = (e) => {
    const params = { ...e, name: "product" }
    dispatch(editProductNameThunk(params)).then((e) => closePopUpMixin(e))
  }

  const onEditBrandName = (e) => {
    const params = { ...e, name: "brand" }
    dispatch(editBrandNameThunk(params)).then((e) => closePopUpMixin(e))
  }

  const onEditPriceProduct = (e) => {
    const params = { price: e.new_value, id_product: pageProduct.id }
    dispatch(editPriceProductThunk(params)).then((e) => closePopUpMixin(e))
  }

  const [showPopUp, setActivePopUp, closePopUp] = usePopUp([
    {
      name: "category",
      title: `Редактирование "Категории"`,
      content: (
        <FieldTextEdit
          label={"Категория"}
          value={currentCategory.category}
          onSubmit={(e) => onEditCategory([e, "category"])}
        />
      ),
    },
    {
      name: "sub_category",
      title: `Редактирование "Подкатегории"`,
      content: (
        <FieldTextEdit
          label={"Подкатегория"}
          value={currentCategory.subcategory}
          onSubmit={(e) => onEditCategory([e, "subcategory"])}
        />
      ),
    },
    {
      name: "name",
      title: "Наименование",
      content: (
        <FieldTextEdit
          label={"Наименование"}
          value={product}
          onSubmit={onEditProductName}
        />
      ),
    },
    {
      name: "brand",
      title: "Бренд",
      content: (
        <FieldTextEdit
          label={"Бренд"}
          value={brand}
          onSubmit={onEditBrandName}
        />
      ),
    },
    {
      name: "price",
      title: "Цена на витрине",
      content: (
        <FieldTextEdit
          label={"Цена"}
          value={price_on_display}
          onSubmit={onEditPriceProduct}
        />
      ),
    },
    {
      name: "changeCategory",
      title: "Смена категории",
      content: (props) => (
        <Container600>
          <SelectСategory
            id_product={pageProduct.id}
            onUpdateData={() => {
              onUpdateData()
              onUpdateCat()
            }}
            {...props}
          />
        </Container600>
      ),
    },

    {
      name: "changeBrand",
      title: "Смена Бренда",
      content: (props) => (
        <SelectBrand
          id_product={pageProduct.id}
          onUpdateData={onUpdateData}
          value={brand}
          {...props}
        />
      ),
    },
  ])

  if (!initialCat && isLoading) return <div>Loadiang...</div>

  return (
    <ContainerForm>
      <BoxUI>
        <FieldReadOnly
          label="Категория"
          value={currentCategory.category ? currentCategory.category : ""}
          onFieldEdit={() => setActivePopUp("category")}
        />
        <FieldReadOnly
          label="Подкатегория"
          value={currentCategory.subcategory ? currentCategory.subcategory : ""}
          onFieldEdit={() => setActivePopUp("sub_category")}
        />
        <FieldReadOnly
          label="Наименование"
          value={product}
          onFieldEdit={() => setActivePopUp("name")}
        />
        <FieldReadOnly
          label="Бренд"
          value={brand}
          onFieldEdit={() => setActivePopUp("brand")}
        />
        <FieldReadOnly
          label="Цена на витрине (за 1 ед)"
          value={`${price_on_display ? price_on_display : 0} ₽`}
          onFieldEdit={() => setActivePopUp("price")}
        />
        <FieldReadOnly
          label="Остатки"
          value={`${total_quantity} шт.`}
        />
        <FieldReadOnly
          label="Состояние"
          value={condition}
        />

        <div className={st.buttons}>
          <ButtonBase
            sx={["size_1", "style_2"]}
            onClick={() => setActivePopUp("changeCategory")}
          >
            Сменить категорию
          </ButtonBase>

          <ButtonBase
            sx={["size_1", "style_2"]}
            onClick={() => setActivePopUp("changeBrand")}
          >
            Сменить бренд
          </ButtonBase>
        </div>

        {showPopUp}
      </BoxUI>
    </ContainerForm>
  )
}

export default FormNoEdit
