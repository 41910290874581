// style
import "../styles/base.scss"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
// import AppRoutes from "./application/routes/AppRoutes"
import AppRoutes from "./routes/AppRoutes.jsx"
import { getCollapsed } from "../features/globalSlice"
import { useTheme } from "@emotion/react"
import { Outlet, useNavigate } from "react-router-dom"
import { Sidebar } from "../components"
import { NotificationMessages } from "../modules/NotificationMessages"
import { Header } from "../modules/Header"
import { IsAuthStatus } from "../pages/Authorization"

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isCollapsed } = useSelector((e) => e.global)
  const { IsAuth } = useSelector((e) => e.authorization)

  const theme = useTheme()

  if (isCollapsed === "") {
    dispatch(getCollapsed())
    // eslint-disable-next-line
  }

  useEffect(() => {
    dispatch(IsAuthStatus())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (IsAuth === false && IsAuth !== null) {
      navigate("/auth")
    }
    // eslint-disable-next-line
  }, [IsAuth])

  // if (category.length === 0) return <div>Loading...</div>
  if (IsAuth === null) {
    return <div>Loading...</div>
  }

  return (
    <div className={`app ${theme.palette.mode !== "dark" ? "" : "dark"}`}>
      <AppRoutes />
      <NotificationMessages />
    </div>
  )
}

export const Main = () => {
  return (
    <>
      <Sidebar />
      <main className="content">
        <Header />
        <div style={{ padding: 30 }}>
          <Outlet />
        </div>
      </main>
    </>
  )
}

export default App
