import { axiosBase } from "../../../features/globalApi"

export const API = {
  async getArrivalExpense(query) {
    try {
      const response = await axiosBase.get("/finance/arrival_expense/", { params: query })
      return response.data
    } catch (err) {
      return { err: err }
    }
  },
}
