import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"

export const getAccountTransactionsThunk = createAsyncThunk(
  "finance/getAccountTransactionsThunk",
  async (query, thunkAPI) => {
    try {
      const request = await API.getAccountTransactions(query)
      return request
    } catch (err) {
      errorManager(err, thunkAPI.dispatch, addNotification)
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const initialState = {
  operationsIncome: [],
  operationsExpense: [],
}

const accountTransactionsSlice = createSlice({
  name: "accountTransactions",
  initialState: initialState,
  extraReducers: (builder) => {
    // ======================================================
    builder.addCase(getAccountTransactionsThunk.fulfilled, (state, action) => {
      const { income, expense } = action.payload

      if (income && expense) {
        state.operationsIncome = income
        state.operationsExpense = expense
      }
    })
  },
})

export default accountTransactionsSlice.reducer
