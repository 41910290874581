import React, { useEffect, useState } from "react"
import st from ".//TableAccountTransactions.module.scss"
import { Table } from ".."
import { getValueDate } from "../../shared/utils/utils_date"

const TableAccountTransactions = ({ Income, Expense }) => {
  const [calcAmounts, setCalcAmounts] = useState({ arrival: 0, expense: 0 })

  useEffect(() => {
    if (Income) {
      const arrival = Income.reduce((previousValue, currentValue) => previousValue + currentValue.price, 0)
      setCalcAmounts((prev) => ({ ...prev, arrival }))
    }

    if (Expense) {
      const expense = Expense.reduce((previousValue, currentValue) => previousValue + currentValue.price, 0)
      setCalcAmounts((prev) => ({ ...prev, expense }))
    }
  }, [Income, Expense])

  const columnsExpense = [
    {
      field: "date",
      headerName: "Дата операции",
      valueGetter: getValueDate,
      width: "90",
    },

    {
      field: "description",
      headerName: "Описание",
      flex: 1,
    },

    {
      field: "operation",
      headerName: "Тип операции",
      width: "120",
    },

    {
      field: "price",
      headerName: "Сумма",
      width: "70",
    },
  ]

  const columnsIncome = [
    ...columnsExpense,
    {
      field: "income",
      headerName: "Доход",
      width: "70",
    },
  ]

  const columnsExpense2 = [
    ...columnsExpense,
    {
      field: "income",
      headerName: "Затраты",
      width: "70",

      renderCell: (props) => {
        return <div>{props.value * -1}</div>
      },
    },
  ]

  return (
    <div className={st.tables}>
      <div className={st.table1}>
        <div className={st.item}>
          <div className={st.label}>Приход:</div>
          <div className={st.sum}>{calcAmounts.arrival} руб.</div>
        </div>
        <Table columns={columnsIncome} operations={Income} />
      </div>

      <div className={st.table2}>
        <div className={st.item}>
          <div className={st.label}>Расход:</div>
          <div className={st.sum}>{calcAmounts.expense} руб.</div>
        </div>
        <Table columns={columnsExpense2} operations={Expense} />
      </div>
    </div>
  )
}

export default TableAccountTransactions
