import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { blankForThunk } from "../../../shared/utils/utils"

export const deleteBankAccountThunk = createAsyncThunk("bankAccounts/deleteBankAccount", async (query, thunkAPI) => {
  return blankForThunk({
    request: () => API.deleteBankAccountAPI(query),
    thunkAPI: thunkAPI,
  })
})

export const getBankAccountsThunk = createAsyncThunk("bankAccounts/getBankAccounts", async (query, thunkAPI) => {
  return blankForThunk({
    request: () => API.getBankAccountsAPI(query),
    thunkAPI: thunkAPI,
  })
})

export const createBankAccountsThunk = createAsyncThunk("bankAccounts/createBankAccounts", async (query, thunkAPI) => {
  return blankForThunk({
    request: () => API.createBankAccountsAPI(query),
    thunkAPI: thunkAPI,
  })
})

export const updateBankAccountAPIThunk = createAsyncThunk("bankAccounts/updateBankAccount", async (query, thunkAPI) => {
  return blankForThunk({
    request: () => API.updateBankAccountAPI(query),
    thunkAPI: thunkAPI,
  })
})

const BankAccountsSlice = createSlice({
  name: "BankAccounts",
  initialState: {
    bankingAccount: null,
    isCreateBankAccounts: false,
  },
  // reducers: {
  //     changeStatusCreateCategory: (state) => {
  //         state.createCatSuccess = false
  //     },
  // },
  extraReducers: (builder) => {
    // ======================================================
    builder.addCase(getBankAccountsThunk.fulfilled, (state, action) => {
      state.bankingAccount = action.payload
    })

    builder.addCase(createBankAccountsThunk.fulfilled, (state, action) => {
      state.isCreateBankAccounts = true
    })

    builder.addCase(updateBankAccountAPIThunk.fulfilled, (state, action) => {
      const result = state.bankingAccount.map((e) => {
        return e.id === action.payload.id ? action.payload : e
      })
      state.bankingAccount = result
    })

    builder.addCase(deleteBankAccountThunk.fulfilled, (state, action) => {
      const result = state.bankingAccount.filter((e) => {
        return e.id !== Number(action.payload.delete.id)
      })
      state.bankingAccount = result
    })
  },
})

// export const {
//     changeStatusCreateCategory,
// } = CreateСategorySlice.actions

export default BankAccountsSlice.reducer
