import React, { useEffect, useRef, useState } from "react"
import st from "./Loading.module.scss"

const Loading = ({ status }) => {
  const [parent, setParent] = useState(null)
  const loadingRef = useRef()

  useEffect(() => {
    let _parent = parent
    if (!_parent && loadingRef.current) {
      _parent = loadingRef.current.parentNode
      setParent(_parent)
    }

    if (_parent && status === false) _parent.classList.remove(st.loading)
    else if (_parent && status === true) _parent.classList.add(st.loading)
    // eslint-disable-next-line
  }, [status])

  if (!status) return <></>

  return (
    <div
      ref={loadingRef}
      className={st.container}
    >
      <div className={st.preloader} />
    </div>
  )
}

export default Loading
