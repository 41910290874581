import React, { useEffect } from "react"
import st from "./PopUp.module.scss"
import CloseIcon from "@mui/icons-material/Close"

const PopUp = ({ content, close, title, active }) => {
  const contentMod = () => {
    if (typeof content === "object") {
      return content
    } else if (typeof content === "function") {
      return content({ closePopUp: close })
    }
  }

  useEffect(() => {
    const _style = document.querySelector("body")
    _style.style = "overflow: hidden"
    return () => (_style.style = "")
  }, [])

  return (
    <div className={st.pop_up}>
      <div className={st.message}>
        <div className={st.close}>
          <CloseIcon
            className={st.icon}
            onClick={close}
          />
        </div>
        {title ? <div className={st.title}>{title}</div> : ""}

        <div className={st.content}>{contentMod()}</div>
      </div>
    </div>
  )
}

export default PopUp
