import Menu from "../Menu/Menu"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import ROUTES, { getAbsoluteURL } from "../../../../application/routes/routes"
import { Table } from "../../../../components"
import { getTicketsThunk } from "../../../../features/globalSlice"
import { getValueDate } from "../../../../shared/utils/utils_date"

const СatalogTicket = () => {
  const dispatch = useDispatch()
  const { ticket } = useSelector((e) => e.global)

  const onTicketParamsSubmit = (date_list, status) => {
    let params = {}
    for (let item in date_list) {
      if (date_list[item]) {
        params[item] = date_list[item].format("DD.MM.YYYY")
      }
    }
    if (status !== "Все статусы") params = { ...params, ticket_status: status }
    dispatch(getTicketsThunk(params))
  }

  useEffect(() => {
    dispatch(getTicketsThunk())
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: "50",
    },

    {
      field: "number",
      headerName: "Номер договора",
      width: "100",
      renderCell: (props) => {
        const url = getAbsoluteURL(ROUTES.TICKET_ID, props.row.id)
        return <Link to={url}>{props.value}</Link>
      },
    },

    {
      field: "loan_amount",
      headerName: "Сумма займа",
      width: "100",
    },

    {
      field: "customer",
      headerName: "Клиент",
      flex: 1.2,

      renderCell: (props) => {
        const url = getAbsoluteURL(ROUTES.CUSTOMER_ID, props.row.customer_id)
        return <Link to={url}>{props.value}</Link>
      },
    },

    {
      field: "amount_interest_paid",
      headerName: "Оплаченные проценты",
      flex: 1,
    },

    {
      field: "loan_date",
      headerName: "Дата займа",
      valueGetter: getValueDate,
      width: "100",
    },

    {
      field: "сlosing_date",
      headerName: "Предполагаемая дата закрытия",
      valueGetter: getValueDate,
      width: "100",
    },

    {
      field: "ticket_status",
      headerName: "Состояние",
      flex: 1,
    },

    {
      field: "employee",
      headerName: "Сотрудник",
      flex: 1.2,
    },
  ]

  return (
    <div>
      <Menu onTicketParamsSubmit={onTicketParamsSubmit} />
      <Table className="mt20" columns={columns} operations={ticket} />
    </div>
  )
}

export default СatalogTicket
