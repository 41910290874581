import st from "./Catalog.module.scss"
import { Link, useParams } from "react-router-dom"
import image from "../../../../data/images/image.png"
import { Title } from "../../../../components"
import { getSubCategory } from "../../../../shared/utils/utils"
import { useGetCategories } from "../../../../shared"

const Catalog = () => {
  const url_params = useParams()
  let category = useGetCategories()[0]
  const subCategory = getSubCategory({ ...url_params, category: category })

  category = subCategory ? subCategory : category

  const getUri = (id) => {
    const _uri = url_params.subId ? `${url_params.subId}` : `${id}`
    return `${_uri}`
  }

  return (
    <div className={st.catalog}>
      <Title title="Каталог" subtitle="Выбор категории"></Title>

      {category.length === 0 ? <div>Категории ещё не созданы...</div> : ""}

      <div className={st.items}>
        {category.map((e) => {
          return (
            <Link to={getUri(e.slug)} key={e.id} className={st.item}>
              <div className={st.image}>
                <img src={e.image || image} alt="catalog" />
              </div>
              <div className={st.title}>{e.name}</div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Catalog
