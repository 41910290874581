import { ContainerForm, FieldReadOnly } from "../../../../shared"

const FormNoEdit = (props) => {
  const { propertyId } = props

  return (
    <ContainerForm>
      <FieldReadOnly label="Уникальный идентификатор" value={propertyId.id} />
      <FieldReadOnly label="Категория" value={propertyId.category} />
      <FieldReadOnly label="Подкатегория" value={propertyId.subcategory} />
      <FieldReadOnly label="Имущество" value={propertyId.product} />
      <FieldReadOnly label="Бренд" value={propertyId.brand} />
      <FieldReadOnly label="Сумма оценки" value={`${propertyId.assessment_amount} ₽`} />
      <FieldReadOnly label="Владелец" value={propertyId.customer} />
      <FieldReadOnly label="Статус" value={propertyId.status} />
      <FieldReadOnly label="Договор" value={propertyId.security_ticket} />
    </ContainerForm>
  )
}

export default FormNoEdit
