import { axiosBase } from "../../../features/globalApi"

export const API = {
  async getTicketStatus() {
    try {
      const response = await axiosBase.get("/ticketstatus/")
      return response.data
    } catch (err) {
      return { err: err }
    }
  },
}
