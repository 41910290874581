import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import ROUTES, { getAbsoluteURL, getAbsoluteURL2 } from "../../../application/routes/routes"
import { Table } from "../../../components"
import { getEmployeeThunk } from "../store/teamStore"
// import { getBankAccountsThunk } from "../store/BankAccounts"

const TableBankAccounts = () => {
  const dispatch = useDispatch()
  const { employees } = useSelector((e) => e.team)

  useEffect(() => {
    dispatch(getEmployeeThunk())
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: "40",
    },

    {
      field: "first_name",
      headerName: "Имя",
      flex: 1,

      renderCell: (props) => {
        const url = getAbsoluteURL2(ROUTES.EMPLOYEE, props.row.id)
        const value = `${props.row.first_name} ${props.row.last_name}`
        return <Link to={url}>{value}</Link>
      },
    },

    {
      field: "email",
      headerName: "Email",
      flex: 1,

      renderCell: (props) => {
        const url = getAbsoluteURL2(ROUTES.EMPLOYEE, props.row.id)
        return <Link to={url}>{props.value}</Link>
      },
    },
    {
      field: "active",
      headerName: "Действующий сотрудник",
      flex: 1,
    },
  ]

  if (employees === null) return <div>Loading...</div>

  return <Table className="mt20" c columns={columns} operations={employees} />
}

export default TableBankAccounts
