import { createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { abbreviatedThunk } from "../../../shared/utils/utils_abbreviatedThunk"

const _nameStore = "DataTransfer"

export const uploadingCustomerThunk = abbreviatedThunk({ _nameStore, api_request: API.uploadingCustomer })
export const uploadingProductThunk = abbreviatedThunk({ _nameStore, api_request: API.uploadingProduct })
export const uploadingSecurityTicketThunk = abbreviatedThunk({ _nameStore, api_request: API.securityTicket })

const employeeSlice = createSlice({
  name: _nameStore,
  initialState: {
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadingProductThunk.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(uploadingProductThunk.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(uploadingProductThunk.rejected, (state, action) => {
      state.loading = false
    })

    builder.addCase(uploadingCustomerThunk.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(uploadingCustomerThunk.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(uploadingCustomerThunk.rejected, (state, action) => {
      state.loading = false
    })

    builder.addCase(uploadingSecurityTicketThunk.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(uploadingSecurityTicketThunk.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(uploadingSecurityTicketThunk.rejected, (state, action) => {
      state.loading = false
    })
  },
})

export const { changeStatusRequest } = employeeSlice.actions

export default employeeSlice.reducer
