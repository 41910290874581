import { axiosBase } from "../../../features/globalApi"

export const API = {
  async getProperty(query) {
    const response = await axiosBase.get("/property/", { params: query })
    return response.data
  },

  async getPropertyStatus() {
    const response = await axiosBase.get("/propertystatus/")
    return response.data
  },
}
