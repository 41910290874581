import React from "react"
import { ButtonHref, FieldSelect, FieldText } from "../../../shared"
import st from "../style/CashRegisterForm.module.scss"
import { validationNumberField } from "../../../shared/utils/utils"

const MixedPayment = (props) => {
  const { bankAccounts, amount, field_id, deletePaymentMethod, number, changePaymentMethod, paymentMethodId } = props
  //   console.log(value, field_id)

  return (
    <div className={st.mixed_payment}>
      <FieldSelect
        label={`Расчет №${number}`}
        fun={(e) => changePaymentMethod({ field_id: e.target.name, paymentMethodId: e.target.value, amount })}
        list={bankAccounts}
        id={field_id}
        value={paymentMethodId}
        // error={{ touched: formik.touched.payment_method, message: formik.errors.payment_method }}
      ></FieldSelect>

      <FieldText
        label="Сумма"
        id={`price_purchase2_${field_id}`}
        placeholder="Пример: 300р"
        value={amount}
        onChange={(e) => {
          const amount = validationNumberField({
            value: e.target.value,
            length: 6,
          })

          return changePaymentMethod({ field_id, paymentMethodId, amount: amount })
        }}
      ></FieldText>

      <div className={st.btn_delete}>
        <ButtonHref onClick={deletePaymentMethod} type="button">
          удалить
        </ButtonHref>
      </div>
    </div>
  )
}

export default MixedPayment
