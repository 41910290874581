import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import ROUTES, { getAbsoluteURL } from "../../../../application/routes/routes"
import { Link } from "react-router-dom"
import { searchBigThunk } from "../../../../modules/Header"
import { Table } from "../../../../components"

const SearchData = () => {
  const dispatch = useDispatch()
  const { searchValue, searchGroup } = useSelector((e) => e.header)

  useEffect(() => {
    dispatch(searchBigThunk({ q: searchValue, data: "products" }))
    // eslint-disable-next-line
  }, [searchValue])

  const columnsExpense = [
    {
      field: "id_product",
      headerName: "id",
      flex: "100px",
    },

    {
      field: "name",
      headerName: "Продукт",
      flex: 1,
      renderCell: (props) => {
        const url = getAbsoluteURL(ROUTES.PRODUCT_ID, props.row.id_product)
        return <Link to={url}>{props.value}</Link>
      },
    },
  ]

  return (
    <>
      <div>
        <Table columns={columnsExpense} operations={searchGroup.products} getRowId={(row) => row.id_product} />
      </div>
    </>
  )
}

export default SearchData
