import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import AddUserForm from "../AddUserForm/AddUserForm"

import SelectUser from "../SelectUser/SelectUser"

const AddUser = (props) => {
  const { setSelectClient } = props
  const dispatch = useDispatch()
  const [formAddOpen, setFormAddOpen] = useState(false)

  useEffect(() => {
    if (formAddOpen) setSelectClient("")
  }, [formAddOpen, dispatch, setSelectClient])

  const changeStateForm = () => {
    setFormAddOpen((isOpen) => !isOpen)
  }

  return (
    <>
      {formAddOpen ? (
        <AddUserForm setFormAddOpen={setFormAddOpen} />
      ) : (
        <SelectUser {...props} changeStateForm={changeStateForm} />
      )}
    </>
  )
}

export default AddUser
