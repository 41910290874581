import React from "react"
import { Line } from "react-chartjs-2"
import { faker } from "@faker-js/faker"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

// const labels = ["1", "2", "3", "4", "5", "6", "7", "8"]
// const labels2 = array(31)

const labels = Array.from(Array(31 + 1).keys()).slice(1)
// console.log(my_array)

const AreaChart = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
    maintainAspectRatio: false,
  }

  const data = {
    labels: labels,
    datasets: [
      {
        fill: true,
        label: "Прибыль",
        data: labels.map(() => faker.datatype.number({ min: 1000, max: 20000 })),
        // data: [8137119, 9431691, 10266674, 1216410, 1371390, 1477380, 1477380],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  }

  return <Line options={options} data={data} />
}

export default AreaChart
