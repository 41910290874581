import { createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { abbreviatedThunk } from "../../../shared/utils/utils_abbreviatedThunk"

const _nameStore = "employee"

export const getEmployeeIdThunk = abbreviatedThunk({ _nameStore, api_request: API.getEmployeeId })

export const passwordChangeEmployeeThunk = abbreviatedThunk({
  _nameStore,
  api_request: API.passwordChangeEmployee,
  messageSuccess: "Пароль успешно изменен",
})

export const loginChangeEmployeeThunk = abbreviatedThunk({
  _nameStore,
  api_request: API.loginChangeEmployee,
  messageSuccess: "Логин успешно изменен",
})

export const dataChangeEmployeeThunk = abbreviatedThunk({
  _nameStore,
  api_request: API.dataChangeEmployee,
  messageSuccess: "Данные сотрудника успешно изменены",
})

const employeeSlice = createSlice({
  name: _nameStore,
  initialState: {
    employeeId: null,
    requestCompleted: {},
  },
  reducers: {
    changeStatusRequest: (state, action) => {
      state.requestCompleted[action.payload] = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployeeIdThunk.fulfilled, (state, action) => {
      state.employeeId = action.payload
    })
    // ======================================================
    builder.addCase(passwordChangeEmployeeThunk.fulfilled, (state, action) => {
      state.requestCompleted = { ...state.requestCompleted, passwordChange: true }
    })
    // ======================================================
    builder.addCase(loginChangeEmployeeThunk.fulfilled, (state, action) => {
      state.requestCompleted = { ...state.requestCompleted, loginChange: true }
    })
    // ======================================================
    builder.addCase(dataChangeEmployeeThunk.fulfilled, (state, action) => {
      state.requestCompleted = { ...state.requestCompleted, dataChange: true }
    })
  },
})

export const { changeStatusRequest } = employeeSlice.actions

export default employeeSlice.reducer
