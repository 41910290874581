import React from "react"
import { BoxUI, ContainerForm, FieldReadOnly } from "../../shared"
import st from "./BaseFormFieldReadOnly.module.scss"

const BaseFormFieldReadOnly = ({ fields, buttons }) => {
  return (
    <ContainerForm>
      <BoxUI>
        {fields.map((e) => {
          return <FieldReadOnly key={e.id} label={e.label} value={e.value} />
        })}

        <div className={st.btn_wrapper}>{buttons()}</div>
      </BoxUI>
    </ContainerForm>
  )
}

export default BaseFormFieldReadOnly
