import { MenuItem, Select } from "@mui/material"
import st from "./Field.module.scss"

export default function FieldSelect(props) {
  const { label, value, fun, list, id, error } = props
  const id_vs_name = id ? { name: id, id: id } : ""

  let error_p
  if (error) {
    error_p = !!error.touched && !!error.message
  }
  return (
    <div className={st.field}>
      <label className={st.label}>
        {label}
        <span>*</span>
      </label>

      <Select
        error={error_p}
        size="small"
        autoComplete="off"
        className={st.input}
        value={value}
        onChange={(e) => fun(e)}
        {...id_vs_name}
      >
        {list.map((e) => {
          return (
            <MenuItem key={e.id} value={e.id}>
              {e.name}
            </MenuItem>
          )
        })}
      </Select>
    </div>
  )
}
