import { Title } from "../../../components"
import BaseFormFields from "../../../components/BaseFormFields/BaseFormFields"
import { useDispatch, useSelector } from "react-redux"
import { changeStatusRequest, loginChangeEmployeeThunk } from "../store/employeeStore"
import { useEffect } from "react"

const EmployeeEditEmail = ({ data, closeForm, userId }) => {
  const dispatch = useDispatch()
  const { requestCompleted } = useSelector((e) => e.employee)

  const fields = [{ id: "email", label: "Почта", placeholder: "aleksandr_ivanov@gmail.com", validation: "email" }]

  const loginChangeFun = (props) => {
    const params = { id: userId, email: props.email }
    dispatch(loginChangeEmployeeThunk(params))
  }

  useEffect(() => {
    if (requestCompleted.loginChange) {
      dispatch(changeStatusRequest("loginChange"))
      closeForm()
    }
    // eslint-disable-next-line
  }, [requestCompleted])

  return (
    <div>
      <Title title="" subtitle={"Изменение логина и почты"}></Title>
      <BaseFormFields fields={fields} closeForm={closeForm} SubmitForm={loginChangeFun} />
    </div>
  )
}

export default EmployeeEditEmail
