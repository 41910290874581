import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCategoryThunk } from "../../features/globalSlice"

export default function useGetCategories2() {
  const dispatch = useDispatch()
  const { uniqCategories, categories, categoryIsLoading, categoryInitialDownload } = useSelector((e) => e.global)

  const onUpdate = () => {
    dispatch(getCategoryThunk())
  }

  useEffect(() => {
    if (categories.length === 0) {
      onUpdate()
    }
    // eslint-disable-next-line
  }, [])

  return {
    uniqCategories,
    categories,
    onUpdate,
    categoryLoading: categoryIsLoading,
    categoryInit: categoryInitialDownload,
  }
}
