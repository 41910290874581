import { axiosBase } from "../../../features/globalApi"

export const API = {
  async getPropertyCard(query) {
    try {
      const response = await axiosBase.get(`/property/${query.id}/`)
      return response.data
    } catch (err) {
      return { err: err }
    }
  },
}
