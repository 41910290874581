import st from "./Field.module.scss"
import { DatePicker } from "@mui/x-date-pickers"

export default function FieldDate(props) {
  const { label, value, fun, id, error } = props

  const id_vs_name = id ? { name: id, id: id } : ""

  let error_p
  if (error) {
    error_p = !!error.touched && !!error.message
  }

  return (
    <div className={st.field}>
      <label className={st.label}>{label}</label>
      <DatePicker
        // helperText={error_p && error.message}
        className={st.input}
        slotProps={{
          textField: {
            size: "small",
            ...id_vs_name,
            error: error_p,
            helperText: error_p && error.message,
          },
        }}
        disableFuture
        onChange={fun}
        value={value}
      />
    </div>
  )
}
