import React from "react"
import st from "./ButtonBase.module.scss"
import shadow from "./ButtonShadow.module.scss"

const ButtonBase = (props) => {
  const { children, className, sx = [], ...other } = props

  let paramStyle = { style: st.style_1, size: st.size_1 }
  for (let item of sx) {
    if (st[item]) {
      if (item.includes("style")) paramStyle["style"] = st[item]
      if (item.includes("size")) paramStyle["size"] = st[item]
    }
  }

  const baseStyle = `${shadow.shadow} ${st.btn} ${Object.values(paramStyle).join(" ")}`
  const _className = className ? `${baseStyle} ${className}` : baseStyle

  return (
    <button {...other} className={_className}>
      {children}
    </button>
  )
}

export default ButtonBase
