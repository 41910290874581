import React, { useEffect, useState } from "react"
import { ButtonBase } from "../../../shared"
import FieldSelectPlus from "../../../shared/UI/Field/FieldSelectPlus"
import st from "../style/CreateСategory.module.scss"
import { useDispatch } from "react-redux"
import useGetCategories2 from "../../../shared/hooks/useGetCategories2"
import { selectCategoryThunk } from "../store/slice"

const SelectСategory = ({ id_product, closePopUp, onUpdateData }) => {
  const dispatch = useDispatch()
  const { uniqCategories, categories, categoryLoading: isLoading } = useGetCategories2()
  const [categoryList, setCategoryList] = useState({ list: [], value: "" })
  const [subCategoryList, setSubCategoryList] = useState({ list: [], value: "" })

  useEffect(() => {
    setCategoryList({ list: uniqCategories, value: "" })
    // eslint-disable-next-line
  }, [categories])

  useEffect(() => {
    if (categoryList.value) {
      let result = categories.filter((e) => e.category_id === categoryList.value)
      setSubCategoryList({ list: result, value: "" })
    }
    // eslint-disable-next-line
  }, [categoryList.value])

  const cleanText = (text) => {
    return text.toLowerCase().trim()
  }

  const addCategory = (value) => {
    const _t = categoryList.list.filter((e) => cleanText(e.name) === cleanText(value))
    let params = null
    if (_t.length > 0) {
      params = { list: categoryList.list, value: _t[0].id }
    } else {
      const item = { id: `a100`, name: value }
      params = { list: [...categoryList.list.filter((e) => e.id !== "a100"), item], value: "a100" }
    }
    setCategoryList(params)
  }

  const addSubCategory = (value) => {
    const _t = subCategoryList.list.filter((e) => cleanText(e.subcategory) === cleanText(value))
    let params = null
    if (_t.length > 0) {
      params = { list: subCategoryList.list, value: _t[0].subcategory_id }
    } else {
      const name = categoryList.list.filter((e) => e.id === categoryList.value)[0]
      const item = { category_id: categoryList.value, category: name.name, subcategory_id: `a100`, subcategory: value }
      params = { list: [...subCategoryList.list.filter((e) => e.id !== "a100"), item], value: "a100" }
    }
    setSubCategoryList(params)
  }

  if (isLoading) return <div>Loadiang...</div>

  const selectCategory = (id) => {
    setCategoryList((e) => {
      return { ...e, value: id }
    })
  }

  const selectSubCategory = (id) => {
    setSubCategoryList((e) => {
      return { ...e, value: id }
    })
  }

  const onSave = () => {
    const result = subCategoryList.list.filter((e) => e.subcategory_id === subCategoryList.value)[0]
    const params = { id_product, category: result.category, sub_category: result.subcategory }

    dispatch(selectCategoryThunk(params)).then((e) => {
      if (!e.error) {
        onUpdateData()
        closePopUp()
      }
    })
  }

  return (
    <>
      <FieldSelectPlus
        label="Категория"
        fun={selectCategory}
        addFun={addCategory}
        list={categoryList.list}
        id="category"
        value={categoryList.value}
      ></FieldSelectPlus>

      {categoryList.value && (
        <FieldSelectPlus
          label="Подкатегория"
          fun={selectSubCategory}
          addFun={addSubCategory}
          list={subCategoryList.list}
          id="subCategory"
          value={subCategoryList.value}
          cycleKeys={{ id: "subcategory_id", name: "subcategory" }}
        ></FieldSelectPlus>
      )}

      <div className={st.btn}>
        {subCategoryList.value && <ButtonBase onClick={onSave}>Сохранить изменения</ButtonBase>}
      </div>
    </>
  )
}

export default SelectСategory
