import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { FieldSearchProduct } from "../../../../shared"
import { getSaleProductsThunk } from "../../store/selectProductSlice"
import CategorySelect from "../../../../components/CategorySelect/CategorySelect"
import Preloader from "../../../../shared/UI/Preloader/Preloader"
// import st from "./SelectProduct.module.scss"

const SelectProduct = ({ selectProduct, setSelectProduct }) => {
  const { saleProducts, isLoadingSaleProducts } = useSelector((e) => e.selectProduct)
  const dispatch = useDispatch()

  const product = selectProduct ? selectProduct : { category: "", subCategory: "", product: "" }
  const [selectValue, setSelectValue] = useState(product)

  useEffect(() => {
    if (selectValue.product) {
      setSelectProduct(selectValue)
    }
    // eslint-disable-next-line
  }, [selectValue.product, setSelectProduct])

  const onSubProductChange = (e, data) => {
    if (typeof data === "object" && data) {
      let condition = null
      for (let item of saleProducts) {
        if (item.id === data.id) {
          condition = item.condition === "Новый" ? 2 : 1
          break
        }
      }
      // console.log(condition)
      setSelectValue({ ...selectValue, product: data, condition: condition })
    } else if (data === "") {
      setSelectValue({ ...selectValue, product: "" })
      setSelectProduct("")
    }
  }

  const onChangeCategory = ({ category, subCategory }) => {
    setSelectValue({ product: "", category: category, subCategory: subCategory })
    setSelectProduct("")
    if (category && subCategory) {
      dispatch(getSaleProductsThunk({ cat: category, subCat: subCategory }))
    }
  }

  let scene = 2
  if (isLoadingSaleProducts === true) scene = 0
  else if (saleProducts !== null && saleProducts.length === 0) scene = 1

  return (
    <div>
      <CategorySelect onChange={onChangeCategory} cat={selectProduct.category} subCat={selectProduct.subCategory} />

      {selectValue.subCategory && (
        <>
          {scene === 0 && <Preloader />}
          {scene === 1 && "Нет товаров для продажи..."}
          {scene === 2 && (
            <FieldSearchProduct
              id="product"
              label="Наименование"
              list={saleProducts}
              value={selectValue.product}
              fun={onSubProductChange}
            ></FieldSearchProduct>
          )}
        </>
      )}
    </div>
  )
}

export default SelectProduct
