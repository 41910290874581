import st from "./Base.module.scss"
import { useSelector } from "react-redux"
import Message from "../Message/Message"

const Base = () => {
  const { listNotification } = useSelector((e) => e.notificationMessage)

  return (
    // TODO тут возможно будут проблемы из за возврата map
    <div className={st.wrapper}>
      {listNotification.map((message) => {
        if (message.show) {
          return <Message key={message.id} message={message} />
        }
        return ""
      })}
    </div>
  )
}

export default Base
