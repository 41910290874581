import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

const useDateQueryURL = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const read = () => {
    const _start = searchParams.get("start")
    const _end = searchParams.get("end")

    const data = dayjs(new Date())
    const params = { start: data.format("DD.MM.YYYY"), end: data.format("DD.MM.YYYY") }

    if (_start && _end === null) return { start: _start, end: params.end }
    if (!_start || !_end) return { start: params.start, end: params.end }

    return { start: _start, end: _end }
  }

  const [date, setDate] = useState(() => read())

  useEffect(() => {
    setSearchParams(date)
    // eslint-disable-next-line
  }, [date])

  return [date, setDate]
}

export default useDateQueryURL
