import { axiosBase } from "../../../features/globalApi"

export const API = {
  async products(props = {}) {
    const { cat, subCat } = props

    const response = await axiosBase.get("/products/", { params: { cat: cat, subcat: subCat } })
    return response.data
  },
}
