import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import st from "../style/Base.module.scss"
import { useSelector } from "react-redux"

const AdditionalMenu = ({ onChangeFilter, select, setSelect }) => {
  const { employees, type_operation, bank_account } = useSelector((e) => e.actionLog)

  const handleChange = (e) => {
    setSelect({ ...select, [e.target.name]: e.target.value })
    onChangeFilter({ ...select, [e.target.name]: e.target.value })
  }

  return (
    <div className={st.wrapper}>
      <div className={st.column}>
        <div className={st.item}>
          <div className={st.title}>Сотрудники:</div>
          <Select
            className={st.select}
            name="employees"
            size="small"
            value={select.employees || employees[0].value}
            onChange={handleChange}
          >
            {employees.map((e) => {
              return (
                <MenuItem key={e.id} value={e.value}>
                  {e.value}
                </MenuItem>
              )
            })}
          </Select>
        </div>

        <div className={st.item}>
          <div className={st.title}>Вид операции:</div>
          <Select
            className={st.select}
            name="type_operation"
            size="small"
            value={select.type_operation || type_operation[0].value}
            onChange={handleChange}
          >
            {type_operation.map((e) => {
              return (
                <MenuItem key={e.id} value={e.value}>
                  {e.value}
                </MenuItem>
              )
            })}
          </Select>
        </div>
      </div>

      <div className={st.column}>
        <div className={st.item}>
          <div className={st.title}>Способ расчета:</div>

          <Select
            className={st.select}
            name="bank_account"
            size="small"
            value={select.bank_account || bank_account[0].value}
            onChange={handleChange}
          >
            {bank_account.map((e) => {
              return (
                <MenuItem key={e.id} value={e.value}>
                  {e.value}
                </MenuItem>
              )
            })}
          </Select>
        </div>
      </div>
    </div>
  )
}

export default AdditionalMenu
