import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../NotificationMessages"

export const getRateTicketThunk = createAsyncThunk("securityTicketForm/getRateTicket", async (_, thunkAPI) => {
  try {
    const request = await API.getRateTicket()
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

export const addSecurityTicketThunk = createAsyncThunk(
  "securityTicketForm/addSecurityTicket",
  async (query, thunkAPI) => {
    try {
      const request = await API.addSecurityTicket(query)
      thunkAPI.dispatch(addNotification("Залоговый билет добавлен."))
      return request
    } catch (err) {
      errorManager(err, thunkAPI.dispatch, addNotification)
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const securityTicketFormSlice = createSlice({
  name: "securityTicketForm",
  initialState: {
    rateTicket: [],
    SuccessAddSecurityTicket: false,
  },
  reducers: {
    changeStatusAddSecurityTicket: (state) => {
      state.SuccessAddSecurityTicket = false
    },
  },
  extraReducers: (builder) => {
    // ======================================================
    builder.addCase(getRateTicketThunk.fulfilled, (state, action) => {
      state.rateTicket = action.payload
    })
    // ======================================================
    builder.addCase(addSecurityTicketThunk.fulfilled, (state, action) => {
      state.SuccessAddSecurityTicket = true
    })
  },
})

export const { changeStatusAddSecurityTicket } = securityTicketFormSlice.actions

export default securityTicketFormSlice.reducer
