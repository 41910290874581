import st from "./Preloader.module.scss"

const Preloader = () => {
  return (
    <div className={st.container}>
      <div className={st.preloader} />
    </div>
  )
}

export default Preloader
