import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    listNotification: [],
}

const NotificationMessageSlice = createSlice({
    name: 'NotificationMessageSlice',
    initialState: initialState,
    reducers: {
        addNotification: (state, data) => {
            const params = { id: Date.now(), name: data.payload, show: true }
            state.listNotification.push(params)
        },

        disableDisplaynNotification: (state, data) => {
            const message = data.payload
            for (let i in state.listNotification) {
                if (state.listNotification[i].id === message.id) {
                    state.listNotification[i].show = false
                }
            }
        },
    },
})

export const {
    addNotification,
    disableDisplaynNotification,
} = NotificationMessageSlice.actions

export default NotificationMessageSlice.reducer