import { MenuItem, Select, TextField } from "@mui/material"
import st from "./Field.module.scss"
import { useState } from "react"

export default function FieldSelectPlus(props) {
  const [typeForm, setTypeForm] = useState(1)
  const [valueField, setValueField] = useState("")

  const { label, value, fun, addFun, list, id, error, cycleKeys = {} } = props
  const id_vs_name = id ? { name: id, id: id } : ""
  let error_p

  if (error) {
    error_p = !!error.touched && !!error.message
  }

  const _id = cycleKeys.id ? cycleKeys.id : "id"
  const _name = cycleKeys.name ? cycleKeys.name : "name"

  const saveBTN = (e) => {
    addFun(valueField)
    setValueField("")
    setTypeForm(1)
  }

  const addBTN = () => {
    setTypeForm(2)
    fun("")
  }

  const textInput = () => {
    return (
      <>
        <TextField
          helperText={error_p && error.message}
          error={error_p}
          size="small"
          autoComplete="off"
          className={st.input}
          value={valueField}
          onChange={(e) => {
            return setValueField(e.target.value)
          }}
          variant="outlined"
          {...id_vs_name}
        />
        <button
          onClick={saveBTN}
          className={st.btn_plus}
        >
          ✓
        </button>
        <button
          onClick={() => setTypeForm(1)}
          className={`${st.btn_plus} ${st.btn_cancel}`}
        >
          ☓
        </button>
      </>
    )
  }

  const selectInput = () => {
    return (
      <>
        <Select
          error={error_p}
          size="small"
          autoComplete="off"
          className={st.input}
          value={value}
          onChange={(e) => fun(e.target.value)}
          {...id_vs_name}
        >
          {list.map((e) => {
            return (
              <MenuItem
                key={e[_id]}
                value={e[_id]}
              >
                {e[_name]}
              </MenuItem>
            )
          })}
        </Select>
        <button
          onClick={addBTN}
          className={st.btn_plus}
        >
          +
        </button>
      </>
    )
  }

  return (
    <div className={st.field}>
      <label className={st.label}>
        {label}
        <span>*</span>
      </label>

      <div className={st.field_btn}>{typeForm === 1 ? selectInput() : textInput()}</div>
    </div>
  )
}
