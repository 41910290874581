import { useState } from "react"
import { SwitchingForm } from "../../../components"
import { ContainerForm } from "../../../shared"
import { AddUser } from "../../AddUser"
import { useDispatch } from "react-redux"
import { addOperationThunk } from "../store/purchaseProductSlice"
import CashRegisterForm from "../../CashRegisterForm/components/CashRegisterForm"

const PurchaseOperationSimple = ({ finalAction, idProduct }) => {
  const dispatch = useDispatch()
  const [stepForm, setStepForm] = useState(1)
  const [selectClient, setSelectClient] = useState("")
  const selectProduct = { id: idProduct }
  const stepActive = [!!selectClient, !!selectProduct]

  const finish = (res) => {
    const result = { ...res, customer: selectClient.id, product: [{ product_card: selectProduct.id }] }
    dispatch(addOperationThunk(result)).then((e) => {
      if (!e.error) finalAction()
    })
  }

  const isStepForm = (numberForm) => {
    if (numberForm === 1) {
      return <AddUser setSelectClient={setSelectClient} selectClient={selectClient} />
    } else if (numberForm === 2) {
      return <CashRegisterForm finish={finish} typeOperation={"buy"} />
    }
  }

  return (
    <div>
      <ContainerForm>
        <div>
          <SwitchingForm setStepForm={setStepForm} stepForm={stepForm} stepActive={stepActive} />
          {isStepForm(stepForm)}
        </div>
      </ContainerForm>
    </div>
  )
}

export default PurchaseOperationSimple
