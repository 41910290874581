import React, { useState } from "react"
import { ButtonBase, Container600, FieldText } from "../../../shared"

const FieldTextEdit = ({ label, value, onSubmit }) => {
  const [valueField, setValueField] = useState(value)

  const _onSubmit = () => {
    onSubmit({ new_value: valueField, current_value: value })
  }

  return (
    <Container600>
      <FieldText
        label={label}
        value={valueField}
        fun={(e) => setValueField(e.currentTarget.value)}
      />

      <ButtonBase
        onClick={_onSubmit}
        className="mt20"
      >
        Сохранить
      </ButtonBase>
    </Container600>
  )
}

export default FieldTextEdit
