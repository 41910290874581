import { axiosBase } from "../../../features/globalApi"

export const API = {
  async getBankAccountsAPI() {
    const response = await axiosBase.get("/finance/bank_accounts/")
    return response.data
  },

  async createBankAccountsAPI(params) {
    const response = await axiosBase.post("/finance/bank_accounts/", params)
    return response.data
  },

  async updateBankAccountAPI(params) {
    const response = await axiosBase.put("/finance/bank_accounts/", params)
    return response.data
  },

  async deleteBankAccountAPI(params) {
    const response = await axiosBase.delete("/finance/bank_accounts/", { data: params })
    return response.data
  },
}
