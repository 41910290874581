import { useEffect } from "react"
import { getCategoriesThunk } from "../../features/globalSlice"
import { useDispatch, useSelector } from "react-redux"

export default function useGetCategories(update = false) {
  const dispatch = useDispatch()
  const { category } = useSelector((e) => e.global)

  useEffect(() => {
    if (update) {
      dispatch(getCategoriesThunk())
    }
    // eslint-disable-next-line
  }, [update])

  const onUpdate = () => {
    dispatch(getCategoriesThunk())
  }

  useEffect(() => {
    if (category.length === 0 && update === false) {
      dispatch(getCategoriesThunk())
    }
    // eslint-disable-next-line
  }, [])

  return [category, onUpdate]
}
