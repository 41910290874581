import st from "../style/ProductCard.module.scss"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useGetCategories } from "../../../shared"
import { Title } from "../../../components"
import { changeStatusRedirect, getProductThunk } from "../store/productSlice"
import FormNoEdit from "./FormNoEdit"
import ROUTES from "../../../application/routes/routes"
import Actions from "./Actions"
import useDeleteOperation from "../../../shared/hooks/useDeleteOperation"
import PurchaseTable from "./PurchaseTable"
import SalesTable from "./SalesTable"

const Product = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const url_params = useParams()
  const category = useGetCategories()[0]
  const { pageProduct, batches, purchaseHistory, redirect } = useSelector((e) => e.product)

  const onUpdateData = () => dispatch(getProductThunk({ id: url_params.id }))
  const [popUpOpenFun, popUpOpenDeleteOperation] = useDeleteOperation({
    finalAction: onUpdateData,
  })

  useEffect(() => {
    onUpdateData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (redirect) {
      dispatch(changeStatusRedirect())
      navigate(ROUTES.CATALOG)
    }
  }, [redirect, navigate, dispatch])

  if (pageProduct === null || category.length === 0) {
    return "Londing..."
  }

  return (
    <div className={st.product}>
      {popUpOpenDeleteOperation}
      <Title
        title="Страница товара"
        subtitle={pageProduct.product}
      ></Title>

      <FormNoEdit
        pageProduct={pageProduct}
        onUpdateData={onUpdateData}
      />

      <Actions
        idProduct={pageProduct.id}
        total_quantity={pageProduct.total_quantity}
        condition={pageProduct.condition}
        sales={purchaseHistory.length}
        onUpdateData={onUpdateData}
      />

      <div className={st.tables}>
        <PurchaseTable
          popUpOpenFun={popUpOpenFun}
          data={batches}
        />
        <SalesTable
          popUpOpenFun={popUpOpenFun}
          data={purchaseHistory}
        />
      </div>
    </div>
  )
}

export default Product
