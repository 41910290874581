import st from "./BoxUI.module.scss"

const Box = (props) => {
  const { children, className, refchild } = props

  return (
    <div {...props} ref={refchild} className={className ? `${st.boxUI} ${className}` : st.boxUI}>
      {children}
    </div>
  )
}

export default Box
