import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import CreateProduct from "./FormCreateProduct"
import FieldElementList from "../../../shared/UI/Field/FieldElementList"

const AddProduct = (props) => {
  const dispatch = useDispatch()
  const { selectProperty, setSelectProduct } = props

  const [isPropertyForm, setIsPropertyForm] = useState(false)
  const [selectPropertyEdit, setSelectPropertyEdit] = useState(null)

  useEffect(() => {
    // dispatch(getRateTicketThunk())
  }, [dispatch])

  const closeFormAddProperty = () => {
    setIsPropertyForm(false)
    setSelectPropertyEdit(null)
  }

  const openFormAddProperty = () => {
    setIsPropertyForm(true)
  }

  const addPropertyFun = (item) => {
    setSelectProduct((e) => {
      const numberId = e.length
      return [...e, { ...item, id: numberId }]
    })
    setIsPropertyForm(false)
  }

  const updatePropertyFun = (item) => {
    for (let i in selectProperty) {
      if (selectProperty[i].id === item.id) {
        selectProperty[i] = item
      }
    }
    setSelectPropertyEdit(null)
    setIsPropertyForm(false)
  }

  const deleteProduct = (id) => {
    const property_list = selectProperty.filter((e, i) => i !== id)
    setSelectProduct(property_list)
  }

  const openFormEditProperty = (obj) => {
    setSelectPropertyEdit(obj)
    openFormAddProperty(true)
  }

  return (
    <>
      {isPropertyForm ? (
        <CreateProduct
          initialForm={selectPropertyEdit}
          closeFormAddProperty={closeFormAddProperty}
          submitForm={selectPropertyEdit === null ? addPropertyFun : updatePropertyFun}
        />
      ) : (
        <div>
          <FieldElementList
            label="Закупаемые товары"
            addButton="Добавить продукт"
            openFormAddElement={openFormAddProperty}
            openFormEditElement={openFormEditProperty}
            listElements={selectProperty}
            deleteElement={deleteProduct}
          />
        </div>
      )}
    </>
  )
}

export default AddProduct
