import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './application/App';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './features/store'
import { ContextProvider } from './theme';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ContextProvider >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ContextProvider>
  </Provider>

);

