import React from "react"
import st from "../style/ProductCard.module.scss"
import { Table } from "../../../components"
import DeleteIconTable from "../../../shared/UI/DeleteIconTable/DeleteIconTable"

const SalesTable = ({ popUpOpenFun, data }) => {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.1,
    },

    {
      field: "date",
      headerName: "Дата, время записи",
      // type: "date",
      flex: 1,
    },

    {
      field: "type_operation",
      headerName: "Тип операции",
      flex: 1,
    },

    {
      field: "customer",
      headerName: "Клиент",
      flex: 1.1,
    },

    {
      field: "quantity",
      headerName: "Количество",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },

    {
      field: "amount",
      headerName: "Цена за шт",
      flex: 1,
    },

    {
      field: "total_amount",
      headerName: "Сумма",
      flex: 1,
    },

    {
      field: "employee",
      headerName: "Сотрудник",
      flex: 1,
      // cellClassName: st.bigcell,
      // renderCell: (props) => {
      //   return <Link to={`/product/${props.row.id}`}>{props.value}</Link>
      // },
    },

    {
      field: "subcategory",
      headerName: "Fun",
      flex: 0.1,

      renderCell: (props) => {
        return <DeleteIconTable deleteOperation={() => popUpOpenFun(props.row.operation)} />
      },
    },
  ]

  return (
    <div className={st.wrapper}>
      <div className={st.title}>История продаж</div>
      <Table className={st.table} columns={columns} operations={data} />
    </div>
  )
}

export default SalesTable
