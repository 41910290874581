import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from '../api/api'


export const getArrivalExpenseThunk = createAsyncThunk('finance/getArrivalExpense', async (query, thunkAPI) => {
    const res = API.getArrivalExpense(query)
    return !res.err ? res : thunkAPI.rejectWithValue('ОШИБКА!')
})


const initialState = {
    operationsIncome: [],
    operationsExpense: [],
}

const arrivalExpenseOperationSlice = createSlice({
    name: 'arrivalExpenseOperation',
    initialState: initialState,
    extraReducers: (builder) => {
        // ======================================================
        builder.addCase(getArrivalExpenseThunk.fulfilled, (state, action) => {
            const { income, expense } = action.payload
            if (income && expense) {
                state.operationsIncome = income
                state.operationsExpense = expense
            }
        })

    }
})

export default arrivalExpenseOperationSlice.reducer