import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from '../api/api'
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"

export const getPropertyThunk = createAsyncThunk('propertyList/getProperty', async (query, thunkAPI) => {
    try {
        const request = await API.getProperty(query)
        return request
    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})

export const getPropertyStatusThunk = createAsyncThunk('propertyList/getPropertyStatus', async (_, thunkAPI) => {
    try {
        const request = await API.getPropertyStatus()
        return request
    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})

const propertyListSlice = createSlice({
    name: 'propertyList',
    initialState: {
        property: [],
        propertyStatus: [{ id: 0, name: 'Все статусы' }],
    },

    extraReducers: (builder) => {
        builder.addCase(getPropertyThunk.fulfilled, (state, action) => {
            state.property = action.payload
        })
        // ======================================================
        builder.addCase(getPropertyStatusThunk.fulfilled, (state, action) => {
            state.propertyStatus = [{ id: 0, name: 'Все статусы' }, ...action.payload]
        })
    }


})

export default propertyListSlice.reducer