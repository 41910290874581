import { createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { abbreviatedThunk } from "../../../shared/utils/utils_abbreviatedThunk"

const _nameStore = "product"

export const addSaleThunk = abbreviatedThunk({ _nameStore, api_request: API.addSell })

const saleProductSlice = createSlice({
  name: "saleProduct",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {},
})

export default saleProductSlice.reducer
