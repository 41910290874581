import st from "./Field.module.scss"
import { Autocomplete, TextField } from "@mui/material"

export default function FieldSearchProduct(props) {
  const { label, value, fun, id, list } = props

  const id_vs_name = id ? { name: id, id: id } : ""

  return (
    <div className={st.field}>
      <label className={st.label}>{label}</label>
      <Autocomplete
        selectOnFocus
        {...id_vs_name}
        value={value}
        onChange={fun}
        // onChange={(event, newValue) => {
        //   debugger
        // }}
        onInputChange={fun}
        size="small"
        className={st.input}
        freeSolo={true}
        options={list.map((option) => {
          return { key: option.id, label: option.product, id: option.id }
        })}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )
        }}
        renderInput={(params) => {
          return <TextField {...params} {...id_vs_name} />
        }}
      />
    </div>
  )
}
