import React, { useEffect, useState } from "react"
import { BoxUI, ButtonBase, Container600 } from "../../../shared"
import { uploadingCustomerThunk, uploadingProductThunk, uploadingSecurityTicketThunk } from "../store/store"
import { useDispatch, useSelector } from "react-redux"
import Loading from "../../../components/Loading/Loading"

const DataTransfer = () => {
  const dispatch = useDispatch()
  const { loading } = useSelector((e) => e.DataTransfer)
  const [fileCustomer, setFileCustomer] = useState()
  const [fileProduct, setFileProduct] = useState()
  const [fileSecurityTicket, setFileSecurityTicket] = useState()
  const [stepRequest, setStepRequest] = useState(0)

  const handleFileUpload = () => {
    setStepRequest(1)
  }

  useEffect(() => {
    const formData = new FormData()
    if (stepRequest === 1) {
      if (fileCustomer) {
        formData.append("customers", fileCustomer.file)
        dispatch(uploadingCustomerThunk(formData)).then((e) => {
          fileCustomer.value.value = ""
          setStepRequest((e) => e + 1)
        })
      } else setStepRequest((e) => e + 1)
    } else if (stepRequest === 2) {
      if (fileProduct) {
        formData.append("product", fileProduct.file)
        dispatch(uploadingProductThunk(formData)).then((e) => {
          fileProduct.value.value = ""
          setStepRequest((e) => e + 1)
        })
      } else setStepRequest((e) => e + 1)
    } else if (stepRequest === 3) {
      if (fileSecurityTicket) {
        formData.append("file", fileSecurityTicket.file)
        dispatch(uploadingSecurityTicketThunk(formData)).then((e) => {
          fileSecurityTicket.value.value = ""
          setStepRequest((e) => e + 1)
        })
      } else setStepRequest((e) => e + 1)
    }
    // eslint-disable-next-line
  }, [stepRequest])

  return (
    <Container600>
      <BoxUI>
        <Loading status={loading} />
        <div className="mt20">
          <div>Загрузка клиентов</div>
          <input
            className="mt10"
            type="file"
            onChange={(e) => {
              setFileCustomer({ file: e.target.files[0], value: e.target })
              setStepRequest(0)
            }}
            accept=".xls,.xlsx"
          />
        </div>

        <div className="mt20">
          <div>Загрузка товаров</div>
          <input
            className="mt10"
            type="file"
            onChange={(e) => {
              setFileProduct({ file: e.target.files[0], value: e.target })
              setStepRequest(0)
            }}
            accept=".xls,.xlsx"
          />
        </div>

        <div className="mt20">
          <div>Загрузка залоговых билетов</div>
          <input
            className="mt10"
            type="file"
            onChange={(e) => {
              setFileSecurityTicket({ file: e.target.files[0], value: e.target })
              setStepRequest(0)
            }}
            accept=".xls,.xlsx"
          />
        </div>

        <div className="mt20">
          {stepRequest > 0 && stepRequest < 4 && "Loading..."}
          {stepRequest === 4 && "Загрузка завершена"}
          {stepRequest === 0 && <ButtonBase onClick={handleFileUpload}>Загрузить</ButtonBase>}
        </div>
      </BoxUI>
    </Container600>
  )
}

export default DataTransfer
