import React, { useState } from "react"
import { BoxUI, ContainerForm } from "../../../shared"
import { SwitchingForm } from "../../../components"
import { AddUser } from "../../AddUser"
import AddProduct from "./AddProduct"
import FormCashRegister from "./FormCashRegister"
import { addPartyProductThunk } from "../store/store"
import { useDispatch } from "react-redux"
import useRedirect from "../../../shared/hooks/useRedirect"

const Base = () => {
  const dispatch = useDispatch()
  const [stepForm, setStepForm] = useState(1)
  const [selectClient, setSelectClient] = useState("")
  const [selectProduct, setSelectProduct] = useState([])
  useRedirect()

  const stepActive = [!!selectClient, !!selectProduct]

  const sendingForm = (props) => {
    const products = selectProduct.map((e) => {
      delete e.id
      return e
    })

    const params = {
      ...props,
      customer: selectClient.id,
      product: products,
    }

    dispatch(addPartyProductThunk(params))
  }

  const isStepForm = (numberForm) => {
    if (numberForm === 1) {
      return <AddUser setSelectClient={setSelectClient} selectClient={selectClient} />
    } else if (numberForm === 2) {
      return <AddProduct setSelectProduct={setSelectProduct} selectProperty={selectProduct} />
    } else if (numberForm === 3) {
      return <FormCashRegister selectProduct={selectProduct} sendingForm={sendingForm} />
    }
  }

  return (
    <div>
      <ContainerForm>
        <BoxUI>
          <SwitchingForm setStepForm={setStepForm} stepForm={stepForm} stepActive={stepActive} />
          {isStepForm(stepForm)}
        </BoxUI>
      </ContainerForm>
    </div>
  )
}

export default Base
