import st from "./Finance.module.scss"
import Header from "../../../../components/Title/Title"
import ROUTES from "../../../../application/routes/routes"
import { Outlet } from "react-router-dom"
import { ButtonLink } from "../../../../shared"

const FinancePage = (props) => {
  const { subtitle = "" } = props

  return (
    <div>
      <Header title="Касса и банк" subtitle={subtitle}></Header>

      <div className={st.items}>
        <ButtonLink link={ROUTES.ARRIVAL_EXPENSE_DATE}>Операции по датам</ButtonLink>
        <ButtonLink link={ROUTES.ARRIVAL_EXPENSE_OPERATIONS}>Приходно-расходные операции</ButtonLink>
        <ButtonLink link={ROUTES.ACCOUNT_TRANSACTIONS}>Операции по расчетному счету</ButtonLink>
      </div>
      <div className={st.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default FinancePage
