import { mockTransactions } from "../../data/mockData"
import Header from "../../components/Title/Title"
import st from "./dashboard.module.scss"
import { BoxUI } from "../../shared"
import AreaChart from "../../components/Chart/AreaChart/AreaChart"
import StatBox from "../../components/StatBox/StatBox"

// icon
import EmailIcon from "@mui/icons-material/Email"
import PointOfSaleIcon from "@mui/icons-material/PointOfSale"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import TrafficIcon from "@mui/icons-material/Traffic"

const Dashboard = () => {
  return (
    <div className={st.dashboard}>
      <div>
        <Header title="Панель мониторинга" subtitle="Добро пожаловать!"></Header>
      </div>

      <div className={st.row1}>
        <BoxUI className={st.item}>
          <StatBox
            title="12,361"
            subtitle="Отправленные Email"
            progress="0.75"
            increase="14"
            icon={<EmailIcon className={st.icon} />}
          />
        </BoxUI>

        <BoxUI className={st.item}>
          <StatBox
            title="431,225"
            subtitle="Полученно с продажи"
            progress="0.5"
            increase="21"
            icon={<PointOfSaleIcon className={st.icon} />}
          />
        </BoxUI>

        <BoxUI className={st.item}>
          <StatBox
            title="32,441"
            subtitle="Новые клиенты"
            progress="0.30"
            increase="5"
            icon={<PersonAddIcon className={st.icon} />}
          />
        </BoxUI>

        <BoxUI className={st.item}>
          <StatBox
            title="1 325 134"
            subtitle="Входящий трафик"
            progress="0.80"
            increase="43"
            icon={<TrafficIcon className={st.icon} />}
          />
        </BoxUI>
      </div>

      {/* ROW 2*/}
      <div className={st.row2}>
        <BoxUI className={st.chart}>
          <div>
            <h5 className={st.title}>Полученный доход в текущем месяце</h5>
            <h5 className={st.amount}>59 342р</h5>
          </div>
          <div className={st.charContainer}>
            <AreaChart />
          </div>
        </BoxUI>

        <BoxUI className={st.topCustomer}>
          <div className={st.container}>
            <div className={st.transactions}>
              {mockTransactions.map((transaction, i) => {
                return (
                  <div key={`${transaction.txId}-${i}`} className={st.item}>
                    <div>
                      <p className={st.name}>{transaction.txId}</p>
                      <p className={st.text}>{transaction.user}</p>
                    </div>

                    <div className={st.text}>{transaction.date}</div>
                    <div className={st.price}>${transaction.cost}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </BoxUI>
      </div>
    </div>
  )
}

export default Dashboard
