import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from '../api/api'
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"

export const getCustomerThunk = createAsyncThunk('customerCard/getCustomer', async (query, thunkAPI) => {
    try {
        const request = await API.getCustomer(query)
        return request
    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})

export const getProductPurchaseCustomerThunk = createAsyncThunk('customerCard/getProductPurchaseCustomer', async (query, thunkAPI) => {
    try {
        const request = await API.getProductPurchaseCustomer(query)
        return request
    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})

export const getProductSaleCustomerThunk = createAsyncThunk('customerCard/getProductSaleCustomer', async (query, thunkAPI) => {
    try {
        const request = await API.getProductSaleCustomer(query)
        return request
    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})

const customerCardSlice = createSlice({
    name: 'customerCard',
    initialState: {
        customerCard: null,
        loadingStatus: { customerCard: true },
        productPurchaseCustomer: [],
        productSaleCustomer: [],
    },

    extraReducers: (builder) => {
        // ======================================================
        builder.addCase(getCustomerThunk.pending, (state) => {
            state.loadingStatus = { ...state.loadingStatus, customerCard: true }
        })
        builder.addCase(getCustomerThunk.fulfilled, (state, action) => {
            state.customerCard = action.payload
            state.loadingStatus = { ...state.loadingStatus, customerCard: false }
        })

        // ======================================================
        builder.addCase(getProductPurchaseCustomerThunk.fulfilled, (state, action) => {
            state.productPurchaseCustomer = action.payload
        })

        // ======================================================
        builder.addCase(getProductSaleCustomerThunk.fulfilled, (state, action) => {
            state.productSaleCustomer = action.payload
        })
    }
})

export default customerCardSlice.reducer