import st from "../AddUser/AddUser.module.scss"
import { useFormik } from "formik"
import { convertDate } from "../../../../shared/utils/utils_date"
import * as yup from "yup"
import { useDispatch } from "react-redux"
import { useState } from "react"
import AddPassportForm from "../AddPassportForm/AddPassportForm"
import { ButtonElem, ButtonHref, FieldDate, FieldForm, FieldText } from "../../../../shared"
import { addCustomerThunk, updateCustomerThunk } from "../../store/addUserSlice"

const AddUserForm = (props) => {
  const {
    setFormAddOpen = () => null,
    customer,
    nameButtomSubmit = "Создать пользователя",
    buttomCancel = true,
  } = props

  const dispatch = useDispatch()

  const [stepForm, setStepForm] = useState(1)

  const required = "Обязательное поле"
  const checkoutSchema = yup.object().shape({
    first_name: yup.string().required(required),
    last_name: yup.string().required(required),
    patronymic: yup.string().required(required),
    date_birth: yup.string().required(required),
  })

  const preparationInitData = () => {
    let customerE = null
    let passport = null

    if (customer) {
      if (customer.passport) {
        passport = { ...customer.passport, date_issue: convertDate(customer.passport.date_issue) }
      }

      customerE = {
        ...customer,
        date_birth: convertDate(customer.date_birth),
        passport: passport,
      }
    }

    return customerE
  }

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      patronymic: "",
      passport: "",
      date_birth: "",
      ...preparationInitData(),
    },
    onSubmit: (value) => {
      const data = {
        ...value,
        date_birth: convertDate(value.date_birth),
        passport: value.passport.date_issue
          ? { ...value.passport, date_issue: convertDate(value.passport.date_issue) }
          : null,
      }

      if (data.id) dispatch(updateCustomerThunk(data))
      else dispatch(addCustomerThunk(data))
      setFormAddOpen(false)
    },

    validationSchema: checkoutSchema,
  })

  const activeteFormPassport = () => {
    setStepForm(2)
  }

  const activeteFormUser = () => {
    setStepForm(1)
  }

  if (stepForm === 1) {
    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <FieldText
            label="Имя"
            id="first_name"
            placeholder="Пример: Иван"
            error={{ touched: formik.touched.first_name, message: formik.errors.first_name }}
            value={formik.values.first_name}
            onChange={(e) => formik.setFieldValue("first_name", e.target.value)}
          ></FieldText>

          <FieldText
            label="Фамилия"
            id="last_name"
            placeholder="Пример: Иванович"
            error={{ touched: formik.touched.last_name, message: formik.errors.last_name }}
            value={formik.values.last_name}
            onChange={(e) => formik.setFieldValue("last_name", e.target.value)}
          ></FieldText>

          <FieldText
            label="Отчество"
            id="patronymic"
            placeholder="Пример: Пупкин"
            error={{ touched: formik.touched.patronymic, message: formik.errors.patronymic }}
            value={formik.values.patronymic}
            onChange={(e) => formik.setFieldValue("patronymic", e.target.value)}
          ></FieldText>

          <FieldDate
            label="Дата рождения"
            id="date_birth"
            error={{ touched: formik.touched.date_birth, message: formik.errors.date_birth }}
            value={formik.values.date_birth}
            fun={(value) => formik.setFieldValue("date_birth", value)}
          ></FieldDate>

          <FieldForm
            label="Паспорт"
            selectObj={formik.values.passport}
            fun={activeteFormPassport}
            // valueField={() => PropertyListField(openFormAddProperty, selectProperty, setSelectPropertyId)}
          ></FieldForm>

          <div className={st.btn_wrapper}>
            <ButtonElem type="submit">{nameButtomSubmit}</ButtonElem>
            {buttomCancel ? (
              <ButtonHref type="button" onClick={() => setFormAddOpen(false)}>
                Отмена
              </ButtonHref>
            ) : (
              <></>
            )}
          </div>
        </form>
      </>
    )
  } else if (stepForm === 2) {
    return (
      <AddPassportForm
        openForm={activeteFormUser}
        SubmitForm={(e) => formik.setFieldValue("passport", e)}
        DataForm={formik.values.passport}
      />
    )
  }
}

export default AddUserForm
