import { axiosBase } from "../../../features/globalApi"

export const API = {
  async getCustomer(query) {
    const response = await axiosBase.get(`/customer/${query.id}/`)
    return response.data
  },

  async getProductPurchaseCustomer(props = {}) {
    const response = await axiosBase.get(`/product/sold/`, { params: props })
    return response.data
  },

  async getProductSaleCustomer(props = {}) {
    const response = await axiosBase.get(`/productbatches/`, { params: props })
    return response.data
  },
}
