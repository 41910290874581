import { createSlice } from "@reduxjs/toolkit"
import { globalAPI } from "./globalApi"
import { abbreviatedThunk } from "../shared/utils/utils_abbreviatedThunk"

const _nameStore = "global"

export const getCategoriesThunk = abbreviatedThunk({ _nameStore, api_request: globalAPI.category })
export const getTicketsThunk = abbreviatedThunk({ _nameStore, api_request: globalAPI.getTickets })
export const getProductNameThunk = abbreviatedThunk({ _nameStore, api_request: globalAPI.getProductName })
export const getBankAccountsLiteThunk = abbreviatedThunk({ _nameStore, api_request: globalAPI.getBankAccountsLiteAPI })
export const getCategoryThunk = abbreviatedThunk({ _nameStore, api_request: globalAPI.category2 })

const initialState = {
  isCollapsed: "",
  category: [],
  categoryIsLoading: false,
  categoryInitialDownload: false,
  ticket: [],
  product_name: [],
  bank_accounts: [],
  redirectURL: null,
  uniqCategories: [],
  categories: [],
}

const globalSlice = createSlice({
  name: _nameStore,
  initialState: initialState,
  reducers: {
    redirectAdd: (state, data) => {
      state.redirectURL = data.payload
    },
    redirectClear: (state, data) => {
      state.redirectURL = null
    },
    changeCollapsed: (state, data) => {
      const isCollapsed = !state.isCollapsed
      state.isCollapsed = isCollapsed
      localStorage.setItem("isCollapsed", isCollapsed)
    },

    getCollapsed: (state, data) => {
      const _isCollapsed = localStorage.getItem("isCollapsed")
      state.isCollapsed = _isCollapsed === "false" ? false : true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoriesThunk.pending, (state) => {
      // state.categoryIsLoading = true
    })
    // ======================================================
    builder.addCase(getCategoriesThunk.fulfilled, (state, action) => {
      state.category = action.payload
      // state.categoryIsLoading = false
    })
    // ======================================================
    builder.addCase(getTicketsThunk.fulfilled, (state, action) => {
      state.ticket = action.payload
    })
    // ======================================================
    builder.addCase(getProductNameThunk.fulfilled, (state, action) => {
      state.product_name = action.payload
    })
    // ======================================================
    builder.addCase(getBankAccountsLiteThunk.fulfilled, (state, action) => {
      state.bank_accounts = action.payload
    })
    // get category
    builder.addCase(getCategoryThunk.pending, (state) => {
      state.categoryIsLoading = true
    })
    builder.addCase(getCategoryThunk.fulfilled, (state, action) => {
      state.categoryIsLoading = false
      const uniqCategory = {}
      action.payload.map((e) => {
        const { category_id, category, slug_category } = e
        uniqCategory[category_id] = { id: category_id, name: category, slug_category }
        return null
      })

      state.uniqCategories = Object.values(uniqCategory)

      state.categories = action.payload
      state.categoryInitialDownload = true
    })
  },
})

export const { changeCollapsed, getCollapsed, redirectAdd, redirectClear } = globalSlice.actions

export default globalSlice.reducer
