import React, { useEffect, useRef, useState } from "react"
import { ButtonHref, FieldDate, TimeFieldUI } from "../../shared"
import st from "./DatetimeBox.module.scss"
import dayjs from "dayjs"

const DatetimeBox = ({ date, time, setdate, setTime }) => {
  const [additionalParams, setAdditionalParams] = useState(false)
  const localDate = useRef("")
  const localTime = useRef("")

  useEffect(() => {
    const fieldDate = localStorage.getItem("fieldDate")
    const fieldTime = localStorage.getItem("fieldTime")

    if (fieldDate && fieldTime) {
      localDate.current = dayjs(Number(fieldDate))
      localTime.current = dayjs(Number(fieldTime))
    }
  }, [])

  useEffect(() => {
    if (additionalParams) {
      if (localDate.current !== date || localTime.current !== time) {
        localStorage.setItem("fieldDate", Number(date))
        localStorage.setItem("fieldTime", Number(time))
        localDate.current = date
        localTime.current = time
      }
    }
  }, [date, time])

  const openFields = () => {
    setAdditionalParams(!additionalParams)

    if (!additionalParams) {
      if (!date && !time) {
        setdate(localDate.current)
        setTime(localTime.current)
      }
    } else {
      setdate("")
      setTime("")
    }
  }

  return (
    <div className={st.DatetimeBox}>
      <ButtonHref onClick={openFields}>Дополнительные параметры</ButtonHref>
      {additionalParams ? (
        <>
          <FieldDate label="Дата операции" id="date" value={date} fun={setdate}></FieldDate>
          <TimeFieldUI label="Время операции" id="time" value={time} fun={setTime}></TimeFieldUI>
        </>
      ) : (
        ""
      )}
    </div>
  )
}

export default DatetimeBox
