import React from "react"
import st from "./ButtonLink.module.scss"
import { Link, useLocation } from "react-router-dom"

const Test123 = (link) => {
  const url_params = useLocation()
  const selected = url_params.pathname
  const result = selected.indexOf(link)
  return result === 0 ? true : false
}

const ButtonLink = (props) => {
  const { children, link, className } = props
  // const match = useMatch(link)

  const isActive = Test123(link)

  let _className = className ? `${st.btn} ${className}` : st.btn
  // _className = match ? `${_className} ${st.active}` : _className
  _className = isActive ? `${_className} ${st.active}` : _className

  return (
    <Link to={link} {...props} className={_className}>
      {children}
    </Link>
  )
}

export default ButtonLink
