import React, { useState } from "react"
import MenuTeam from "./MenuTeam"
import TableTeam from "./TableTeam"
import FormCreateTeam from "./FormCreateTeam"

const Team = () => {
  const [showTable, showForm] = ["showTable", "showForm"]
  const [activeElement, setActiveElement] = useState(showTable)

  return (
    <div>
      {activeElement === showTable && (
        <>
          <MenuTeam activeElement={() => setActiveElement(showForm)} />
          <TableTeam />
        </>
      )}

      {activeElement === showForm && <FormCreateTeam closeForm={() => setActiveElement(showTable)} />}
    </div>
  )
}

export default Team
