import { axiosBase } from "../../../features/globalApi"

export const API = {
  async getTicketCard(query) {
    const response = await axiosBase.get(`/ticket/${query.id}/`)
    return response.data
  },

  async closeTicket(query) {
    const response = await axiosBase.put(`/operation/ticket_close/${query.id}/`, query)
    return response.data
  },

  async surchargeTicket(query) {
    const response = await axiosBase.put(`/operation/ticket_surcharge/${query.id}/`, query)
    return response.data
  },

  async withdrawalTicket(query) {
    const response = await axiosBase.put(`/operation/ticket_withdrawal/${query.id}/`, { date: query.date })
    return response.data
  },
}
