import st from "../style/CashRegisterForm.module.scss"
import { useFormik } from "formik"
import * as yup from "yup"
import dayjs from "dayjs"
import { validationNumberField } from "../../../shared/utils/utils"
import { convertDate, convertTime } from "../../../shared/utils/utils_date"
import { useState } from "react"
import { ButtonElem, FieldDate, TimeFieldUI, FieldText, FieldSelect, ButtonHref } from "../../../shared"
import useGetBankAccounts from "../../../shared/hooks/useGetBankAccounts"
import MixedPayment from "./MixedPayment"
import { addNotification } from "../../NotificationMessages"
import { useDispatch } from "react-redux"

const CashRegisterForm = ({ finish, typeOperation, otherProps, countBuy = true }) => {
  const dispatch = useDispatch()
  const [mixedPaymentList, setMixedPaymentList] = useState([{ field_id: "method1", paymentMethodId: "", amount: "" }])
  let textNoticeFoter = null
  let field_1 = ""

  const [btnForm, setBtnForm] = useState(true)
  const bankAccounts = useGetBankAccounts()

  // ------------------------------
  // Валидатор
  // ------------------------------
  const required = "Обязательное поле"
  let listSchema = {}

  const displayCounteBuy = true ? (typeOperation === "sell" || typeOperation === "buy") && countBuy : false
  if (displayCounteBuy) listSchema = { ...listSchema, quantity: yup.string().required(required) }

  listSchema = {
    ...listSchema,
    date: yup.string().required(required),
    price_purchase: yup.number().required(required),
    payment_method: yup.string().required(required),
  }
  const checkoutSchema = yup.object().shape(listSchema)

  const formik = useFormik({
    initialValues: {
      quantity: "",
      price_purchase: "",
      payment_method: "",
      date: dayjs(new Date()),
      time: dayjs(new Date()),
    },
    onSubmit: (value) => {
      setBtnForm(false)
      const dateTime = `${convertDate(value.date)} ${convertTime(value.time)}`
      const quantity = value.quantity || 1

      let payments = null

      if (value.payment_method !== "PaymentDifferentWays" || mixedPaymentList.length === 0) {
        payments = [
          {
            payment_method: value.payment_method,
            amount: quantity * value.price_purchase,
          },
        ]
      } else if (value.payment_method === "PaymentDifferentWays") {
        payments = mixedPaymentList.map((e) => {
          return { payment_method: e.paymentMethodId, amount: Number(e.amount) }
        })
      }

      const result = {
        quantity: quantity,
        date: dateTime,
        payments: payments,
      }

      const payment_balance = mixedPaymentList.reduce((previous, current) => {
        return previous + Number(current.amount)
      }, 0)

      if (Number(value.price_purchase) * quantity === payment_balance) {
        finish(result)
        setBtnForm(true)
      } else if (value.payment_method !== "PaymentDifferentWays") {
        finish(result)
        setBtnForm(true)
      } else {
        dispatch(addNotification("Неправильно внесена сумма в способ расчета!"))
        setBtnForm(true)
      }
    },
    validationSchema: checkoutSchema,
  })

  const genFoterText = ({ name1, name2 }) => {
    let textNoticeFoter = ""
    const _price = Number(formik.values.price_purchase)
    const _quantity = formik.values.quantity || 1
    const _total_amount = _price * _quantity

    if (_price && _quantity) {
      const payment_balance = mixedPaymentList.reduce((previous, current) => {
        return previous + Number(current.amount)
      }, 0)

      textNoticeFoter = (
        <>
          <div>{`${name1}: ${_price * _quantity} ₽`}</div>
          {formik.values.payment_method === "PaymentDifferentWays" && (
            <div>{`${name2}: ${_total_amount - payment_balance} ₽`}</div>
          )}
        </>
      )
    }

    return textNoticeFoter
  }

  if (typeOperation === "sell") {
    field_1 = "Цена продажи (за 1 штуку)"
    textNoticeFoter = genFoterText({ name1: "Всего к оплате", name2: "Осталось внести" })
  } else if (typeOperation === "buy") {
    field_1 = "Цена покупки (за 1 штуку)"
    textNoticeFoter = genFoterText({ name1: "Всего к выдаче", name2: "Осталось выдать" })
  } else if (typeOperation === "security_ticket") {
    field_1 = "Сумма займа"
  } else if (typeOperation === "redemption") {
    field_1 = "Сумма выкупа"
    textNoticeFoter = `Рекомендуемый платеж для закрытия билета ${otherProps.amoun}₽`
  } else if (typeOperation === "surcharge") {
    field_1 = "Сумма процентов к оплате"
    textNoticeFoter = `Рекомендуемый платеж для перезалога ${otherProps.procent_amount}₽`
  }

  const addPaymentMethod = () => {
    const newIdMethod = `method${mixedPaymentList.length + 1}`
    setMixedPaymentList([...mixedPaymentList, { field_id: newIdMethod, paymentMethodId: "", amount: "" }])
  }

  const deletePaymentMethod = (field_id) => {
    const result = mixedPaymentList.filter((elem) => elem.field_id !== field_id)
    setMixedPaymentList(result)
  }

  const changePaymentMethod = ({ field_id, paymentMethodId, amount }) => {
    const result = mixedPaymentList.map((e) => {
      if (e.field_id === field_id) {
        return { field_id: field_id, paymentMethodId: paymentMethodId, amount: amount }
      }
      return e
    })
    setMixedPaymentList(result)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <>
        <FieldText
          label={field_1}
          id="price_purchase"
          placeholder="Пример: 300 ₽"
          error={{ touched: formik.touched.price_purchase, message: formik.errors.price_purchase }}
          value={formik.values.price_purchase}
          onChange={(props) => {
            const result = validationNumberField({ value: props.target.value, length: 6 })
            formik.setFieldValue("price_purchase", result)
          }}
        ></FieldText>

        {displayCounteBuy && (
          <FieldText
            label="Количество"
            name="_quantity"
            id="quantity"
            placeholder="Пример: 10 шт"
            error={{ touched: formik.touched.quantity, message: formik.errors.quantity }}
            value={formik.values.quantity}
            onChange={(props) => {
              const result = validationNumberField({ value: props.target.value, length: 3 })
              formik.setFieldValue("quantity", result)
            }}
          ></FieldText>
        )}

        <FieldSelect
          label="Способ расчета"
          fun={formik.handleChange}
          list={[...bankAccounts, { id: "PaymentDifferentWays", name: "Оплата разными способами" }]}
          id="payment_method"
          value={formik.values.payment_method}
          error={{ touched: formik.touched.payment_method, message: formik.errors.payment_method }}
        ></FieldSelect>

        {formik.values.payment_method === "PaymentDifferentWays" && (
          <div className={st.wrapper_mixed_payment}>
            {mixedPaymentList.map((e, number) => {
              const props = {
                deletePaymentMethod: () => deletePaymentMethod(e.field_id),
                changePaymentMethod: changePaymentMethod,
                bankAccounts,
                number: number + 1,
                value: e.value,
                field_id: e.field_id,
                paymentMethodId: e.paymentMethodId,
                amount: e.amount,
              }

              return <MixedPayment key={e.field_id} {...props} />
            })}

            <ButtonHref className={st.add_mixed_payment} type="button" onClick={addPaymentMethod}>
              добавить способ оплаты
            </ButtonHref>
          </div>
        )}

        <FieldDate
          label="Дата операции"
          id="date"
          error={{ touched: formik.touched.date, message: formik.errors.date }}
          value={formik.values.date}
          fun={(value) => formik.setFieldValue("date", value)}
        ></FieldDate>

        <TimeFieldUI
          label="Время операции"
          id="time"
          fun={(e) => formik.setFieldValue("time", e)}
          value={formik.values.time}
        ></TimeFieldUI>

        <div className={st.footer}>
          {btnForm ? (
            <>
              <ButtonElem className={"mt20"} type="submit">
                Сохранить
              </ButtonElem>
              {textNoticeFoter && <div className={st.notice}>{textNoticeFoter}</div>}
            </>
          ) : (
            <div>Данные отправлены на сервер...</div>
          )}
        </div>
      </>
    </form>
  )
}

export default CashRegisterForm
