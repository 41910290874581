import { TimeField } from "@mui/x-date-pickers"
import st from "./Field.module.scss"

const TimeFieldUI = (props) => {
  const { className, label, value, fun, type, id, error, ...other } = props

  const id_vs_name = id ? { name: id, id: id } : ""

  let error_p
  if (error) {
    error_p = !!error.touched && !!error.message
  }

  return (
    <div className={st.field}>
      <label className={st.label}>
        {label}
        <span>*</span>
      </label>

      <TimeField
        helperText={error_p && error.message}
        error={error_p}
        size="small"
        autoComplete="off"
        className={st.input}
        value={value}
        type={type || "text"}
        onChange={fun}
        variant="outlined"
        {...id_vs_name}
        {...other}
      />
    </div>
  )
}

export default TimeFieldUI
