import ButtonHref from "../ButtonHref/ButtonHref"
import st from "./Field.module.scss"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

export default function FieldElementList(props) {
  const { label, addButton, openFormAddElement, openFormEditElement, listElements, deleteElement } = props

  return (
    <div className={st.field}>
      <label className={st.label}>
        {label}
        <span>*</span>
      </label>

      <div className={st.element_list}>
        {listElements.map((obj, i) => {
          return (
            <div key={obj.id} className={st.element}>
              <div className={st.wrapper}>
                <ButtonHref onClick={() => openFormEditElement(obj)}>{`${i + 1}. ${obj.product}`}</ButtonHref>
                <DeleteForeverIcon className={st.icon} onClick={() => deleteElement(i)} />
              </div>
            </div>
          )
        })}

        <ButtonHref className={st.btn_add_elem} onClick={() => openFormAddElement(true)}>
          {addButton || "Добавить элемент"}
        </ButtonHref>
      </div>
    </div>
  )
}
