import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from '../api/api'
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"

export const getProductsThunk = createAsyncThunk('Products/getProducts', async (query, thunkAPI) => {
    try {
        const request = await API.products(query)
        return request
    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})

const productListSlice = createSlice({
    name: 'productList',
    initialState: {
        products: [],
    },

    extraReducers: (builder) => {
        // ======================================================
        builder.addCase(getProductsThunk.fulfilled, (state, action) => {
            state.products = action.payload
        })
    }
})

export default productListSlice.reducer