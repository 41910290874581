import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { addNotification } from "../../../modules/NotificationMessages"
import { errorManager } from "../../../shared/utils/utils"

export const getOperationThunk = createAsyncThunk("operations/getOperation", async (query, thunkAPI) => {
  try {
    const request = await API.getOperation(query)
    thunkAPI.dispatch(addNotification("Запрос на получения операций выполнен."))
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

export const deleteOperationsThunk = createAsyncThunk("operations/deleteOperations", async (query, thunkAPI) => {
  try {
    const request = await API.deleteOperations(query)
    // thunkAPI.dispatch(addNotification("Операция удалена!"))
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

const operationsSlice = createSlice({
  name: "operations",
  initialState: {
    operations: [],
    remote_operations: [],
    employees: [],
    operation: [],
  },
  extraReducers: (builder) => {
    // ======================================================
    builder.addCase(getOperationThunk.fulfilled, (state, action) => {
      state.operations = action.payload

      let employees = []
      let operation = []

      action.payload.map((e) => {
        employees.push(e.employee)
        operation.push(e.operation)
        return 0
      })

      employees = ["Все", ...new Set(employees)]
      operation = ["Все", ...new Set(operation)]

      state.employees = employees.map((e, i) => ({ id: i, value: e }))
      state.operation = operation.map((e, i) => ({ id: i, value: e }))
    })
    // ======================================================
    builder.addCase(deleteOperationsThunk.fulfilled, (state, action) => {
      const id = action.payload.delete.operation_id
      const operations = state.operations
      state.operations = operations.filter((e) => e.id !== id)
      state.remote_operations.push(id)
    })
    // ======================================================
  },
})

// export const {
// } = operationsSlice.actions

export default operationsSlice.reducer
