const ROUTES = {
  HOME: "/",

  // Настройки
  SETTINGS_PAGE: "setting",
  BANK_ACCOUNTS: "/setting/bankaccounts",
  BANK_ACCOUNT: "/setting/bankaccounts/:id",

  TEAM: "/setting/team",
  EMPLOYEE: "/setting/team/employee/:id",
  INVOICES: "/setting/invoices",
  FAQ: "/setting/faq",
  DATA_TRANSFER: "/setting/data_transfer",

  NEW_OPERATION: "/newoperation",
  PURCHASE: "/newoperation/purchase",
  PURCHASE_GROUP: "/newoperation/group_purchase",
  SALE: "/newoperation/sale",
  TICKET: "/newoperation/ticket",
  OTHER_OPERATION: "/newoperation/otheroperation",

  // Ломбард
  PROPERTY_LIENS: "propertyliens",
  TICKETS: "/propertyliens/ticket/",
  PROPERTY: "/propertyliens/property",
  PROPERTY_ID: "property/:id",
  TICKET_ID: "ticket/:id",

  // Клиент
  CUSTOMERS: "customers",
  CUSTOMER_ID: "customer/:id",
  TICKETS_CUSTOMER: "/customer/:id/tickets",
  SALES_CUSTOMER: "/customer/:id/sales",
  PURCHASES_CUSTOMER: "/customer/:id/purchases",

  FINANCE: "finance",
  ARRIVAL_EXPENSE_DATE: "/finance/operations_period",
  ARRIVAL_EXPENSE_OPERATIONS: "/finance/arrival_expense",
  ACCOUNT_TRANSACTIONS: "/finance/account_transactions",

  AUTHORIZATION: "auth",

  PRODUCT_ID: "product/:id",
  CATALOG: "/catalog",

  SEARCH: "search",
  SEARCH_PRODUCTS: "/search/products",
  SEARCH_PRODUCTS_USED: "/search/products_used",
  SEARCH_PROPERTY: "/search/property",
  SEARCH_CUSTOMERS: "/search/customers",
  ACTION_LOG: "/actionlog",
}

const url_formation = ({ route, id }) => {
  let url = route.join("/")
  url = `/${url.replace(":id", id)}`
  // FIXME косыль удаление лишней '/'
  url = url.replace("//", "/")
  return url
}

const ROUTES_ABSOLUTE = {
  CUSTOMER_ID: (id) => url_formation({ route: [ROUTES.CUSTOMER_ID], id: id }),
  PROPERTY_ID: (id) => url_formation({ route: [ROUTES.PROPERTY_ID], id: id }),
  TICKET_ID: (id) => url_formation({ route: [ROUTES.TICKET_ID], id: id }),
  PRODUCT_ID: (id) => url_formation({ route: [ROUTES.PRODUCT_ID], id: id }),
  TICKETS_CUSTOMER: (id) => url_formation({ route: [ROUTES.TICKETS_CUSTOMER], id: id }),
  SALES_CUSTOMER: (id) => url_formation({ route: [ROUTES.SALES_CUSTOMER], id: id }),
  PURCHASES_CUSTOMER: (id) => url_formation({ route: [ROUTES.PURCHASES_CUSTOMER], id: id }),
  BANK_ACCOUNT: (id) => url_formation({ route: [ROUTES.BANK_ACCOUNT], id: id }),

  RUN: (id, route) => url_formation({ route: [route], id: id }),
}

export const getAbsoluteURL = (route, id) => {
  if (ROUTES.CUSTOMER_ID === route) return ROUTES_ABSOLUTE.CUSTOMER_ID(id)
  if (ROUTES.PROPERTY_ID === route) return ROUTES_ABSOLUTE.PROPERTY_ID(id)
  if (ROUTES.PRODUCT_ID === route) return ROUTES_ABSOLUTE.PRODUCT_ID(id)
  if (ROUTES.TICKET_ID === route) return ROUTES_ABSOLUTE.TICKET_ID(id)
  if (ROUTES.TICKETS_CUSTOMER === route) return ROUTES_ABSOLUTE.TICKETS_CUSTOMER(id)
  if (ROUTES.SALES_CUSTOMER === route) return ROUTES_ABSOLUTE.SALES_CUSTOMER(id)
  if (ROUTES.PURCHASES_CUSTOMER === route) return ROUTES_ABSOLUTE.PURCHASES_CUSTOMER(id)
  if (ROUTES.BANK_ACCOUNT === route) return ROUTES_ABSOLUTE.RUN(id, route)
}

export const getAbsoluteURL2 = (route, id) => {
  let url = `/${route.replace(":id", id)}`
  return url.replace("//", "/")
}

export default ROUTES
