import { axiosBase } from "../../../features/globalApi"


export const API = {
    async getOperation(query) {
        const response = await axiosBase.get(`/operation/`, { params: query })
        return response.data
    },

    async deleteOperations(query) {
        const response = await axiosBase.delete(`/operation/${query.id}/`)
        return response.data
    },
}