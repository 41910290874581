import st from "./PropertyLiens.module.scss"
import ROUTES from "../../../../application/routes/routes"
import { Outlet } from "react-router-dom"
import { ButtonLink } from "../../../../shared"
import { Title } from "../../../../components"

const PropertyLiens = () => {
  return (
    <div className={st.new_operation}>
      <Title title="Залоги и имущество" subtitle="Выбор каталога"></Title>

      <div className={st.menu}>
        <ButtonLink link={ROUTES.TICKETS}>Залоговые билеты</ButtonLink>
        <ButtonLink link={ROUTES.PROPERTY}>Залоговое имущество</ButtonLink>
      </div>
      <div className="mt20">
        <Outlet />
      </div>
    </div>
  )
}

export default PropertyLiens
