import { Box, useTheme } from "@mui/material"
import Header from "../../../components/Title/Title"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { tokens } from "../../../theme"
// import { color } from "@mui/system"

const Faq = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  return (
    <Box>
      <Header title="Faq" subtitle="Manage Faq"></Header>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            An Important Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus pariatur inventore consectetur, accusamus
            voluptatum similique magni eos expedita asperiores architecto repellat aspernatur modi non. Similique
            delectus hic saepe maxime! Quae!
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            An Important Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus pariatur inventore consectetur, accusamus
            voluptatum similique magni eos expedita asperiores architecto repellat aspernatur modi non. Similique
            delectus hic saepe maxime! Quae!
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            An Important Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus pariatur inventore consectetur, accusamus
            voluptatum similique magni eos expedita asperiores architecto repellat aspernatur modi non. Similique
            delectus hic saepe maxime! Quae!
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            An Important Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus pariatur inventore consectetur, accusamus
            voluptatum similique magni eos expedita asperiores architecto repellat aspernatur modi non. Similique
            delectus hic saepe maxime! Quae!
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            An Important Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus pariatur inventore consectetur, accusamus
            voluptatum similique magni eos expedita asperiores architecto repellat aspernatur modi non. Similique
            delectus hic saepe maxime! Quae!
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            An Important Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus pariatur inventore consectetur, accusamus
            voluptatum similique magni eos expedita asperiores architecto repellat aspernatur modi non. Similique
            delectus hic saepe maxime! Quae!
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default Faq
