import ButtonGroup from "../../../../components/ButtonGroup/ButtonGroup"
import PopUpWindow from "../../../../shared/UI/PopUpWindow/PopUpWindow"
import { closeTicketThunk, withdrawalTicketThunk } from "../../store/ticketCardSlice"
import { surchargeTicketThunk } from "../../store/ticketCardSlice"
import { useDispatch } from "react-redux"
import CashRegisterForm from "../../../../modules/CashRegisterForm/components/CashRegisterForm"
import { ButtonElem, ContainerForm } from "../../../../shared"
import st from "./actions.module.scss"
import dayjs from "dayjs"
import { convertDate, convertTime } from "../../../../shared/utils/utils_date"

const Actions = ({ ticketCard, openWindow, setOpenWindow }) => {
  const dispatch = useDispatch()

  const onwithdrawalTicket = () => {
    const date = dayjs(new Date())
    const time = dayjs(new Date())
    const dateTime = `${convertDate(date)} ${convertTime(time)}`

    dispatch(withdrawalTicketThunk({ id: ticketCard.id, date: dateTime }))
  }

  const submittingFormClose = (props) => {
    const result = {
      amount_interest_paid: props.price_purchase,
      date: props.date,
      // payment_method: props.payment_method,
      id: ticketCard.id,
      payments: props.payments,
    }
    dispatch(closeTicketThunk(result))
  }

  const submittingFormSurcharge = (props) => {
    const result = {
      amount_interest_paid: props.price_purchase,
      date: props.date,
      // payment_method: props.payment_method,
      id: ticketCard.id,
      payments: props.payments,
    }
    dispatch(surchargeTicketThunk(result))
  }

  let actions = []
  if (ticketCard.ticket_status !== "Закрыт") {
    actions = [
      ...actions,
      {
        name: "Выкуп",
        title: "Выкуп",
        action: () => setOpenWindow("Выкуп"),
        content: () => (
          <ContainerForm>
            <CashRegisterForm
              finish={submittingFormClose}
              typeOperation={"redemption"}
              otherProps={{ amoun: ticketCard.total_amount }}
            />
          </ContainerForm>
        ),
      },
      {
        name: "Перезалог",
        title: "Перезалог",
        action: () => setOpenWindow("Перезалог"),
        content: () => (
          <ContainerForm>
            <CashRegisterForm
              finish={submittingFormSurcharge}
              typeOperation={"surcharge"}
              otherProps={{ procent_amount: ticketCard.procent_amount }}
            />
          </ContainerForm>
        ),
      },

      {
        name: "Вывод из залога",
        title: "Перевести имущество в основной товар?",
        action: () => setOpenWindow("Вывод из залога"),
        content: () => (
          <ContainerForm>
            <div>
              <div className={st.wrapper_btn}>
                <ButtonElem className={st.btn} onClick={onwithdrawalTicket}>
                  ДА
                </ButtonElem>
                <ButtonElem className={st.btn} onClick={() => setOpenWindow(false)}>
                  Нет
                </ButtonElem>
              </div>
            </div>
          </ContainerForm>
        ),
      },
    ]
  }

  return (
    <div>
      {actions.map((e) => {
        if (e.name === openWindow) {
          return <PopUpWindow key={e.name} content={e.content} title={e.title} close={() => setOpenWindow(false)} />
        } else {
          return ""
        }
      })}

      <ButtonGroup className="mt20" actions={actions} />
    </div>
  )
}

export default Actions
