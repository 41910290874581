import { useEffect } from "react"
import { ButtonElem, ButtonHref, FieldSearchProduct, FieldText, FieldSelect } from "../../../../shared"
import CategorySelect from "../../../../components/CategorySelect/CategorySelect"
import { useDispatch, useSelector } from "react-redux"
import { getProductNameThunk } from "../../../../features/globalSlice"

const FormWrite = (props) => {
  const dispatch = useDispatch()
  const { formik, condition_list, setTypeForm } = props
  const { product_name } = useSelector((e) => e.global)

  const onChangeCategory = ({ category, subCategory }) => {
    formik.setFieldValue("category", category)
    formik.setFieldValue("subcategory", subCategory)
  }

  const onFieldBrandChange = (e) => {
    for (let item of product_name) {
      if (item.id === e) {
        formik.setFieldValue("brand", item.brand)
        break
      }
    }
  }

  const onChangeFieldProduct = (e, data) => {
    if (data === null) {
      formik.setFieldValue("id", "")
      return formik.setFieldValue("product", "")
    }
    if (data.id) {
      onFieldBrandChange(data.id)
      formik.setFieldValue("id", data.id)
      return formik.setFieldValue("product", data.label)
    }
    formik.setFieldValue("id", "")
    return formik.setFieldValue("product", data)
  }

  useEffect(() => {
    if (formik.values.subcategory) {
      dispatch(getProductNameThunk({ cat: formik.values.category, subcat: formik.values.subcategory }))
    }
  }, [formik.values.subcategory, formik.values.category, dispatch])

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FieldSelect
          label="Состояние"
          fun={formik.handleChange}
          list={condition_list}
          id="condition"
          value={formik.values.condition}
        ></FieldSelect>

        {formik.values.condition && (
          <CategorySelect onChange={onChangeCategory} cat={formik.values.category} subCat={formik.values.subcategory} />
        )}

        {formik.values.subcategory && (
          <>
            <FieldSearchProduct
              id="product"
              label="Наименование"
              list={product_name}
              value={formik.values.product}
              fun={onChangeFieldProduct}
            ></FieldSearchProduct>

            <FieldText
              label="Бренд"
              id="brand"
              placeholder="Xiaomi"
              // error={!!formik.touched.product && !!formik.errors.product}
              value={formik.values.brand}
              onChange={formik.handleChange}
            ></FieldText>

            {/* {getErrorForm()} */}

            <div className="mt20">
              <ButtonElem type="submit" color="secondary" variant="contained">
                Сохранить
              </ButtonElem>
            </div>
          </>
        )}
      </form>

      <div className="mt20">
        <ButtonHref onClick={() => setTypeForm(2)}>Добавить новую категорию</ButtonHref>
      </div>
    </>
  )
}

export default FormWrite
