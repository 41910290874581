import st from "../style/Base.module.scss"
import * as React from "react"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { useState } from "react"
import MenuCustom from "../../../components/MenuCustom/MenuCustom"
import { getBankAccountsThunk } from "../../../modules/BankAccounts"

const SelectFilter = ({ status, setStatus }) => {
  const { bankingAccount } = useSelector((e) => e.BankAccounts)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBankAccountsThunk())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (bankingAccount) setStatus(bankingAccount[0].id)
    // eslint-disable-next-line
  }, [bankingAccount])

  if (!bankingAccount || status === "") return <div>Loading...</div>

  const amount = bankingAccount.filter((e) => e.id === status)

  return (
    <div className={st.items}>
      <Select className={st.select} size="small" value={status} onChange={(e) => setStatus(e.target.value)}>
        {bankingAccount.map((e) => {
          return (
            <MenuItem key={e.id} value={e.id}>
              {e.name}
            </MenuItem>
          )
        })}
      </Select>
      <div className={st.balance}>
        <div>Баланс счета:</div>
        <div className={st.amount}>
          {amount[0].balance} <span>руб.</span>
        </div>
      </div>
    </div>
  )
}

const DataFilter = ({ dateList, setDateList }) => {
  const handleChange = (date, type) => {
    setDateList((e) => ({ ...e, [type]: date }))
  }

  return (
    <>
      <div className={st.date}>
        <div className={st.label}>Показывать записи с</div>
        <DatePicker
          slotProps={{ textField: { size: "small" } }}
          disableFuture
          value={dateList.loanDate1}
          onChange={(newValue) => handleChange(newValue, "loanDate1")}
          className={st.field}
        />
        <div>по</div>
        <DatePicker
          slotProps={{ textField: { size: "small" } }}
          disableFuture
          value={dateList.loanDate2}
          onChange={(newValue) => handleChange(newValue, "loanDate2")}
          className={st.field}
        />
      </div>
    </>
  )
}

const AccountTransactionsMenu = ({ getData }) => {
  const [status, setStatus] = useState("")
  const [dateList, setDateList] = useState({ loanDate1: null, loanDate2: null })

  return (
    <>
      <MenuCustom onClickBtn={() => getData({ status, dateList })}>
        <SelectFilter status={status} setStatus={setStatus} />
        <DataFilter dateList={dateList} setDateList={setDateList} />
      </MenuCustom>
    </>
  )
}

export default AccountTransactionsMenu
