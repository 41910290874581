import { Title } from "../../../components"
import BaseFormFields from "../../../components/BaseFormFields/BaseFormFields"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { changeStatusRequest, dataChangeEmployeeThunk } from "../store/employeeStore"

const EmployeeEditForm = ({ data, closeForm, userId }) => {
  const dispatch = useDispatch()
  const { requestCompleted } = useSelector((e) => e.employee)

  const fields = [
    { id: "first_name", label: "Имя", placeholder: "Александр", validation: "string" },
    { id: "last_name", label: "Фамилия", placeholder: "Иванов", validation: "string" },
    {
      id: "active",
      label: "Действующий сотрудник",
      validation: "string",
      type: "select",
      list: [
        { id: "Да", name: "Да" },
        { id: "Нет", name: "Нет" },
      ],
    },
  ]

  const dataChangeFun = (props) => {
    const params = {
      id: userId,
      first_name: props.first_name,
      last_name: props.last_name,
      is_active: props.active === "Да" ? true : false,
    }
    // debugger
    dispatch(dataChangeEmployeeThunk(params))
  }

  useEffect(() => {
    if (requestCompleted.dataChange) {
      dispatch(changeStatusRequest("dataChange"))
      closeForm()
    }
    // eslint-disable-next-line
  }, [requestCompleted])

  return (
    <div>
      <Title title="" subtitle={"Изменения данных сотрудника"}></Title>
      <BaseFormFields fields={fields} closeForm={closeForm} SubmitForm={dataChangeFun} initialData={data} />
    </div>
  )
}

export default EmployeeEditForm
