import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Accordion from "@mui/material/Accordion"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useTheme } from "@mui/material"
import { tokens } from "../../../theme"

const AccordionUI = (props) => {
  const { title, investment } = props
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  return (
    <Accordion sx={{ mt: 2 }}>
      <AccordionSummary sx={{ backgroundColor: colors.primary[400] }} expandIcon={<ExpandMoreIcon />}>
        <div>{title}</div>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: colors.primary[400] }}>{investment.map((e) => e)}</AccordionDetails>
    </Accordion>
  )
}

export default AccordionUI
