import { axiosBase } from "../../../features/globalApi"


export const API = {
    async getArrivalExpenseOperations(query) {
        const response = await axiosBase.get('/arrival_expense_operations/', { params: query })
        return response.data

    },

    async getTypeOperationAPI(query) {
        const response = await axiosBase.get('/types_operations/', { params: query })
        return response.data

    },
}