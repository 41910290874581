import { createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { abbreviatedThunk } from "../../../shared/utils/utils_abbreviatedThunk"

const _nameStore = "purchaseProduct"

export const addOperationThunk = abbreviatedThunk({
  _nameStore,
  api_request: API.addOperation,
  redirectSuccess: "/operations",
})

const purchaseProductSlice = createSlice({
  name: _nameStore,
  initialState: {},
  extraReducers: (builder) => {
    // builder.addCase(addOperationThunk.fulfilled, (state, action) => {
    //   state.SuccessAddPurchase = true
    // })
    // ======================================================
  },
})

export default purchaseProductSlice.reducer
