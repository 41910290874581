import { axiosBase } from "../../../features/globalApi"

export const API = {
  async product(props = {}) {
    const response = await axiosBase.get(`/product/${props.id}/`)
    return response.data
  },

  async getProductWriteDown(props = {}) {
    const response = await axiosBase.get(`/product/write_downs/${props.id}/`)
    return response.data
  },

  async productWriteDown(props = {}) {
    const data = {
      item_id: props.item_id,
      date: props.date,
    }

    const response = await axiosBase.post(`/operation/write_downs/${props.idProduct}/`, data)
    return response.data
  },

  async getProductReturn(props = {}) {
    const response = await axiosBase.get(`/product/refund/${props.id}/`)
    return response.data
  },

  async productReturn(props = {}) {
    const response = await axiosBase.post(`/operation/refund/`, props)
    return response.data
  },

  async editCategory(props = {}) {
    const response = await axiosBase.put(`/category/`, props)
    return response.data
  },

  async editProductName(props = {}) {
    const response = await axiosBase.put(`/productname/`, props)
    return response.data
  },

  async editBrandName(props = {}) {
    const response = await axiosBase.put(`/brand/`, props)
    return response.data
  },

  async editPriceProduct(props = {}) {
    const response = await axiosBase.put(`/product/price_window/`, props)
    return response.data
  },

  async changeBrand(props = {}) {
    const response = await axiosBase.put(`/product/change_brand/`, props)
    return response.data
  },

  async getBrand(props = {}) {
    const response = await axiosBase.get(`/brand/`, props)
    return response.data
  },
}
