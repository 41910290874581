import { useCallback, useRef } from "react"

// хук useDebounce вызывает функцию когда нет никих действий больше 1 секунды

export default function useDebounce(callback, delay) {
  const timer = useRef()

  const debounceCallback = useCallback(
    (args, stop = null) => {
      if (timer.current) clearTimeout(timer.current)
      if (stop === "stop") {
        clearTimeout(timer.current)
        return
      }

      timer.current = setTimeout(() => callback(args), delay)
    },
    [callback, delay]
  )

  return debounceCallback
}
