import React, { useEffect } from "react"
import { Table } from "../../../components"
import { useDispatch, useSelector } from "react-redux"
import { getBankAccountsThunk } from "../store/BankAccounts"
import { Link } from "react-router-dom"
import ROUTES, { getAbsoluteURL } from "../../../application/routes/routes"
// import { Link } from "react-router-dom"
// import { getAbsoluteURL } from "../../../application/routes/routes"

const TableBankAccounts = () => {
  const dispatch = useDispatch()
  const { bankingAccount } = useSelector((e) => e.BankAccounts)

  useEffect(() => {
    dispatch(getBankAccountsThunk())
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: "140",
    },

    {
      field: "name",
      headerName: "Имя счета",
      width: "250",

      renderCell: (props) => {
        const url = getAbsoluteURL(ROUTES.BANK_ACCOUNT, props.row.id)
        return <Link to={url}>{props.value}</Link>
        // return <Link to={`/product/${props.row.id}`}>{props.value}</Link>
      },
    },

    {
      field: "balance",
      headerName: "Баланс",
      width: "140",
    },

    {
      field: "type_calculation",
      headerName: "Тим счета",
      width: "140",
    },
  ]

  if (bankingAccount === null) return <div>Loading...</div>

  return <Table className="mt20" c columns={columns} operations={bankingAccount} />
}

export default TableBankAccounts
