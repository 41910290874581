import { ButtonHref, FieldText, useGetCategories } from "../../../../shared"
import { convertIdInName } from "../../../../shared/utils/utils"

const FormReadOnly = ({ formik, clearActionProduct, condition_list }) => {
  const category = useGetCategories()[0]
  const itemCat = category.filter((item) => item.id === formik.values.category)
  const subCategory = itemCat[0].subcategories

  const cat_name = convertIdInName({ list: category, id: formik.values.category })
  const sub_cat_name = convertIdInName({ list: subCategory, id: formik.values.subcategory })
  const condition = convertIdInName({ list: condition_list, id: formik.values.condition })

  return (
    <div>
      <FieldText label="Состояние" id="condition" value={condition}></FieldText>
      <FieldText label="Категория" id="category" value={cat_name}></FieldText>
      <FieldText label="Подкатегория" id="subcategory" value={sub_cat_name}></FieldText>
      <FieldText label="Наименование" id="product" value={formik.values.product}></FieldText>
      <FieldText label="Бренд" id="brand" value={formik.values.brand}></FieldText>

      <div className="mt20">
        <ButtonHref onClick={clearActionProduct}>Выбрать другой товара</ButtonHref>
      </div>
    </div>
  )
}

export default FormReadOnly
