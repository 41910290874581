import React, { useEffect, useState } from "react"
import st from "../style/BankAccounts.module.scss"
import { BoxUI, ButtonElem, ButtonHref, ContainerForm, FieldReadOnly, FieldText } from "../../../shared"
import { useDispatch, useSelector } from "react-redux"
import { deleteBankAccountThunk, getBankAccountsThunk, updateBankAccountAPIThunk } from "../store/BankAccounts"
import { useNavigate, useParams } from "react-router-dom"
import ROUTES from "../../../application/routes/routes"

const Edit = ({ bankAccount, setTypeForm, saveName }) => {
  const [name, setName] = useState(bankAccount.name)

  return (
    <div>
      <FieldText
        label="Имя"
        id="first_name"
        placeholder="Пример: Иван"
        value={name}
        onChange={(e) => setName(e.target.value)}
      ></FieldText>

      <div className={st.btn_wrapper}>
        <ButtonHref onClick={setTypeForm}>Отмена</ButtonHref>
        <ButtonElem onClick={() => saveName(name)}>Сохранить</ButtonElem>
      </div>
    </div>
  )
}

const NoEdit = ({ bankAccount, setTypeForm, deleteBankAccount }) => {
  return (
    <div>
      <FieldReadOnly label="Имя счета" value={bankAccount.name} />
      <FieldReadOnly label="Баланс" value={bankAccount.balance} />
      <FieldReadOnly label="Тип счета" value={bankAccount.type_calculation} />
      <div className={st.btn_wrapper2}>
        <ButtonElem onClick={setTypeForm}>Внести изменения</ButtonElem>
        <ButtonElem onClick={deleteBankAccount}>Удалить</ButtonElem>
      </div>
    </div>
  )
}

const BankAccount = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const id_url = useParams()
  const [bankAccount, setBankAccount] = useState()
  const [edit, noEdit] = ["edit", "noEdit"]
  const [typeForm, setTypeForm] = useState(noEdit)
  const { bankingAccount } = useSelector((e) => e.BankAccounts)

  const saveName = (name) => {
    dispatch(updateBankAccountAPIThunk({ name: name, id: id_url.id }))
    setTypeForm(noEdit)
  }

  const deleteBankAccount = () => {
    dispatch(deleteBankAccountThunk({ id: id_url.id }))
  }

  useEffect(() => {
    if (bankingAccount === null) {
      dispatch(getBankAccountsThunk())
    } else {
      const result = bankingAccount.filter((e) => e.id === Number(id_url.id))
      if (!result[0]) navigate(ROUTES.BANK_ACCOUNTS)
      else setBankAccount(result[0])
    }
    // eslint-disable-next-line
  }, [bankingAccount])

  if (!bankAccount) return <div>Loading...</div>

  return (
    //
    <ContainerForm>
      <BoxUI>
        {typeForm === noEdit && (
          <NoEdit
            bankAccount={bankAccount}
            setTypeForm={() => setTypeForm(edit)}
            deleteBankAccount={deleteBankAccount}
          />
        )}
        {typeForm === edit && (
          <Edit bankAccount={bankAccount} setTypeForm={() => setTypeForm(noEdit)} saveName={saveName} />
        )}
      </BoxUI>
    </ContainerForm>
  )
}

export default BankAccount
