import st from "./ButtonHref.module.scss"

const ButtonHref = (props) => {
  const { children, className, onClick } = props
  return (
    <button {...props} className={className ? `${st.btn} ${className}` : st.btn} onClick={onClick}>
      {children}
    </button>
  )
}

export default ButtonHref
