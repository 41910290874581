import * as utils from "../../../../shared/utils/utils"
import { ButtonElem, ContainerForm, FieldSelect, FieldText } from "../../../../shared"
import { useState } from "react"

const FormEdit = (props) => {
  const { propertyId, category } = props

  const catId = utils.convertNameInId({ list: category, name: propertyId.category })
  const subCatList = utils.getSubCategory({ category, catId: catId })

  const subCatId = utils.convertNameInId({ list: subCatList, name: propertyId.subcategory })

  const [params, setParams] = useState({ category: catId, subcategory: subCatId })

  const onCategoryChange = (e) => {
    setParams((prev) => ({ ...prev, [e.target.name]: e.target.value }))

    if (e.target.name === "category") setParams((prev) => ({ ...prev, subcategory: "" }))
  }

  const result = category.filter((e) => e.id === params.category)
  // console.log(result[0].subcategories)
  // debugger

  return (
    <ContainerForm>
      <FieldSelect label="Категория" id="category" value={params.category} fun={onCategoryChange} list={category} />
      <FieldSelect
        label="Подкатегория"
        id="subcategory"
        value={params.subcategory}
        fun={onCategoryChange}
        // list={subCatList}
        list={result[0].subcategories || subCatList}
      />
      <FieldText label="Наименование" value={propertyId.product} fun={() => 1} />
      <FieldText label="Сумма оценки" value={propertyId.assessment_amount} fun={() => 1} />
      <ButtonElem className="mt20">Сохранить</ButtonElem>
    </ContainerForm>
  )
}

export default FormEdit
