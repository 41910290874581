import st from "./NewOperation.module.scss"
import Header from "../../components/Title/Title"
import ROUTES from "../../application/routes/routes"
import { Outlet } from "react-router-dom"
import { ButtonLink } from "../../shared"

const NewOperation = (props) => {
  const { subtitle = "Добавить новую операцию" } = props

  return (
    <div className={st.new_operation}>
      <Header title="Операции" subtitle={subtitle}></Header>

      <div className={st.items}>
        <ButtonLink link={ROUTES.PURCHASE}>Покупка и докупка товаров</ButtonLink>
        <ButtonLink link={ROUTES.PURCHASE_GROUP}>Покупка группы товаров</ButtonLink>
        <ButtonLink link={ROUTES.SALE}>Продажа товаров</ButtonLink>
        <ButtonLink link={ROUTES.TICKET}>Залог</ButtonLink>
        <ButtonLink link={ROUTES.OTHER_OPERATION}>Добавить другую операцию</ButtonLink>
      </div>
      <Outlet />
    </div>
  )
}

export default NewOperation
