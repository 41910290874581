import { useDispatch, useSelector } from "react-redux"
import { Title } from "../../../components"
import { getActionLogThunk } from "../store/actionLogSlice"
import { useEffect, useState } from "react"
import TableActionLog from "./TableActionLog"
import MenuActionLog from "./MenuActionLog"
import { getCurentDate } from "../../../shared/utils/utils_date"

const ActionLog = () => {
  const dispatch = useDispatch()
  const { actionLogList } = useSelector((e) => e.actionLog)
  const [actionLogListFilter, setActionLogListFilter] = useState()

  const getData = (props) => {
    dispatch(getActionLogThunk(props))
  }

  useEffect(() => {
    const _d = getCurentDate()
    const params = { start: _d, end: _d }
    getData(params)
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Title title="Журнал" subtitle="история всех действий"></Title>
      <MenuActionLog list={actionLogList} setActionLogListFilter={setActionLogListFilter} getData={getData} />
      <TableActionLog list={actionLogListFilter ? actionLogListFilter : actionLogList} />
    </div>
  )
}

export default ActionLog
