import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProductReturnThunk, productReturnThunk } from "../store/productSlice"
import st2 from "../style/PopUpProductReturn.module.scss"
import st from "../style/PopUp.module.scss"
import { getCurentDateTime } from "../../../shared/utils/utils_date"

const PopUpReturnProduct = ({ finalAction, idProduct }) => {
  const dispatch = useDispatch()
  const { productsReturn, loading } = useSelector((e) => e.product)

  useEffect(() => {
    dispatch(getProductReturnThunk({ id: idProduct }))
    // eslint-disable-next-line
  }, [])

  const onSelectProduct = (id_item) => {
    const dateTime = getCurentDateTime()
    const params = { item_id: id_item, date: dateTime }
    dispatch(productReturnThunk(params)).then((e) => {
      if (!e.error) finalAction()
    })
  }

  if (!productsReturn) return <div>Loading...</div>
  if (loading) return <div>Loading...</div>

  return (
    <div className={st.table}>
      <div className={st.title}>
        <div className={st2.cell}>id</div>
        <div className={st2.cell}>Товар</div>
        <div className={st2.cell}>Дата</div>
        <div className={st2.cell}>Цена</div>
      </div>
      <div className={st.wrapper}>
        {productsReturn.length === 0 ? (
          <div className={st.no_product}>Нет товаров для возврата</div>
        ) : (
          productsReturn.map((e) => {
            return (
              <div key={e.id} className={st.row} onClick={() => onSelectProduct(e.id)}>
                <div className={st2.cell}>{e.id}</div>
                <div className={st2.cell}>{e.product}</div>
                <div className={st2.cell}>{e.date}</div>
                <div className={st2.cell}>{e.price} ₽</div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default PopUpReturnProduct
