import st from "./Field.module.scss"
import { TextField } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"

export default function FieldReadOnly(props) {
  const { label, value, onFieldEdit } = props

  return (
    <div className={st.field}>
      <label className={st.label} htmlFor={label}>
        {label}
      </label>

      <div className={st.wrapper_input}>
        <div className={`${st.edit} ${onFieldEdit ? "" : st.display_off}`} onClick={onFieldEdit}>
          <EditIcon />
        </div>
        <TextField
          id={label}
          className={st.input}
          size="small"
          variant="outlined"
          InputProps={{ readOnly: true }}
          value={value}
        />
      </div>
    </div>
  )
}
