import React from "react"
import { useState } from "react"
import st from "./MenuCustom.module.scss"
import { BoxUI, ButtonElem } from "../../shared"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"

const MenuCustom = (props) => {
  const { onClickBtn, children } = props
  const [additionalMenuOpen, setAdditionalMenuOpen] = useState(false)

  if (!children) return <BoxUI>Нет дочерниго элемнета</BoxUI>

  return (
    <BoxUI className={st.menu}>
      <div className={st.fields}>
        {children.length > 0 ? children[0] : children}

        <div className={st.icon_wrapper}>
          <ButtonElem onClick={onClickBtn}>ОК</ButtonElem>

          {children[1] && (
            <SettingsOutlinedIcon className={st.icon} onClick={() => setAdditionalMenuOpen(!additionalMenuOpen)} />
          )}
        </div>
      </div>
      {additionalMenuOpen && children[1]}
    </BoxUI>
  )
}

export default MenuCustom
