import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = ({ procent }) => {
  console.log(procent)

  const options = {
    cutout: "86%",
  }

  const data = {
    // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],

    datasets: [
      {
        // label: "# of Votes",
        data: [procent, 100],
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          //   "rgba(255, 99, 132, 0.2)",
          //   "rgba(54, 162, 235, 0.2)",
          //   "rgba(153, 102, 255, 0.2)",
          //   "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(153, 102, 255, 1)",
          "rgba(75, 192, 192, 1)",
          //   "rgba(255, 99, 132, 1)",
          //   "rgba(54, 162, 235, 1)",
          //   "rgba(255, 206, 86, 1)",
          //   "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
      },
    ],
  }

  return <Pie data={data} options={options} />
}

export default PieChart
