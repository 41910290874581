import React from "react"
import { BoxUI, ButtonElem } from "../../../shared"

const MenuTable = ({ changeForm }) => {
  return (
    <BoxUI>
      <ButtonElem onClick={changeForm}>Добавить новый счет</ButtonElem>
    </BoxUI>
  )
}

export default MenuTable
