import React, { useEffect, useState } from "react"
import { Menu, Table } from "../../../components"
import { getArrivalExpenseOperationsThunk } from "../store/baseSlice"
import { useDispatch, useSelector } from "react-redux"
import { getValueDate } from "../../../shared/utils/utils_date"
import AdditionalMenuBase from "../../../components/AdditionalMenuBase/AdditionalMenuBase"

const Base = () => {
  const dispatch = useDispatch()
  const [dataMenu, setDataMenu] = useState({})
  const { operations, employees, type_operation, name_operations, bank_account } = useSelector(
    (e) => e.arrivalExpenseOperations
  )
  const [operationsCopy, setOperationsCopy] = useState("")

  const getData = (props) => {
    dispatch(getArrivalExpenseOperationsThunk({ ...props }))
    setOperationsCopy("")
  }

  useEffect(() => {
    dispatch(getArrivalExpenseOperationsThunk())
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: "40",
    },

    {
      field: "date",
      headerName: "Дата операции",
      valueGetter: getValueDate,
      flex: 1,
    },

    {
      field: "type_operation",
      headerName: "Тип операции",
      flex: 1,
    },

    {
      field: "expenditure",
      headerName: "Сумма",
      flex: 1,
    },

    {
      field: "type_operation_name",
      headerName: "Наименование операции",
      flex: 2,
    },
    {
      field: "description",
      headerName: "Описание",
      flex: 2,
    },

    {
      field: "customer",
      headerName: "Клиент",
      flex: 1,
    },

    {
      field: "employee",
      headerName: "Клиент",
      flex: 1,
    },
  ]

  const fields = [
    { name: "employee", list: employees, title: "Сотрудники" },
    { name: "type_operation", list: type_operation, title: "Вид операции" },
    { name: "type_operation_name", list: name_operations, title: "Основание" },
    { name: "bank_account", list: bank_account, title: "Способ расчета" },
  ]

  return (
    <div>
      <Menu onClickBtn={getData}>
        <AdditionalMenuBase
          dataMenu={dataMenu}
          setDataMenu={setDataMenu}
          fields={fields}
          list={operations}
          setList={setOperationsCopy}
        />
      </Menu>

      <Table className="mt20" columns={columns} operations={operationsCopy || operations} />
    </div>
  )
}

export default Base
