import React, { useState } from "react"
import { ButtonElem, FieldReadOnly } from "../../../shared"
import DatetimeBox from "../../../components/DatetimeBox/DatetimeBox"
import { convertDate, convertTime } from "../../../shared/utils/utils_date"

const FormCashRegister = ({ selectProduct, sendingForm }) => {
  const [date, setDate] = useState("")
  const [time, setTime] = useState("")

  const amount = useState(() => {
    const sum = selectProduct.reduce((partialSum, x) => {
      return partialSum + Number(x.amount)
    }, 0)
    return sum
  })

  const sendingFormModifi = () => {
    let params = {}
    if (date && time) {
      params = { ...params, date: `${convertDate(date)} ${convertTime(time)}` }
    } else if (date) {
      params = { ...params, date: `${convertDate(date)} ${convertTime(date)}` }
    }
    sendingForm(params)
  }

  return (
    <div>
      <FieldReadOnly label={"Итоговая сумма"} value={`${amount[0]} ₽`} />
      <FieldReadOnly label={"Способ расчета"} value={"Наличные"} />

      <DatetimeBox date={date} time={time} setdate={setDate} setTime={setTime} />

      <ButtonElem className={"mt20"} onClick={sendingFormModifi}>
        Сохранить
      </ButtonElem>
    </div>
  )
}

export default FormCashRegister
