import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"

export const getActionLogThunk = createAsyncThunk("actionLog/getActionLog", async (query, thunkAPI) => {
  try {
    const request = await API.getActionLog(query)
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

const actionLogSlice = createSlice({
  name: "actionLog",
  initialState: {
    actionLogList: null,

    employees: [],
    type_operation: [],
    bank_account: [],
  },

  extraReducers: (builder) => {
    //
    builder.addCase(getActionLogThunk.fulfilled, (state, action) => {
      state.actionLogList = action.payload

      let employees = []
      let type_operation = []
      let bank_account = []

      action.payload.map((e) => {
        employees.push(e.employee)
        type_operation.push(e.type_operation)
        // name_operations.push(e.type_operation_name)
        bank_account.push(e.calculation_method)
        return 0
      })

      employees = ["Все", ...new Set(employees)]
      type_operation = ["Все", ...new Set(type_operation)]
      bank_account = ["Все", ...new Set(bank_account)]

      state.employees = employees.map((e, i) => ({ id: i, value: e }))
      state.type_operation = type_operation.map((e, i) => ({ id: i, value: e }))
      state.bank_account = bank_account.map((e, i) => ({ id: i, value: e }))
    })
    // ======================================================
  },
})

export default actionLogSlice.reducer
