import React, { useEffect } from "react"
import st from "./Menu.module.scss"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { BoxUI, ButtonElem } from "../../../../shared"
import { useState } from "react"
import { getSubCategory, getSubCategoryId } from "../../../../shared/utils/utils"
import { useParams } from "react-router-dom"

const Menu = ({ getProducts, category, products }) => {
  const url_params = useParams()

  const [value, setValue] = useState({
    cat: url_params.catId,
    subCat: url_params.subId,
    сondition: "0",
    brand: "Все",
    listBrand: ["Все"],
  })

  const subCategory = getSubCategory({ category, catId: value.cat })

  const getBrand = (props) => {
    let res = props.filter((e) => {
      const slugCat = getSubCategoryId({ category: category, slug: value.subCat })
      const _subcategory = e.subcategory === slugCat
      return _subcategory
    })

    res = res.map((e) => e.brand)
    return ["Все", ...new Set(res)]
  }

  useEffect(() => {
    let res = getBrand(products)
    setValue({ ...value, listBrand: res })
    // eslint-disable-next-line
  }, [value.cat, value.subCat, products, category])

  const handleOnChange = () => {
    getProducts(value)
  }

  return (
    <BoxUI className={st.menu}>
      <div className={st.filters}>
        {/* FIXME: "Этот код повторяется !!!" */}
        <div className={st.item}>
          <p className={st.label}>Категория</p>
          <Select
            className={st.input}
            size="small"
            value={value.cat}
            onChange={(e) => {
              setValue({ ...value, cat: e.target.value, subCat: "", Brand: "Все" })
            }}
          >
            {category.map((e) => (
              <MenuItem key={e.id} value={e.slug}>
                {e.name}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className={st.item}>
          <p className={st.label}>Подкатегория</p>
          <Select
            className={st.input}
            size="small"
            labelId="employee-select-label"
            id="employee-select"
            value={value.subCat}
            // setSubCat(e.target.value)
            onChange={(e) => setValue({ ...value, subCat: e.target.value })}
          >
            {subCategory.map((e) => {
              return (
                <MenuItem key={e.id} value={e.slug}>
                  {e.name}
                </MenuItem>
              )
            })}
          </Select>
        </div>

        <div className={st.item}>
          <p className={st.label}>Состояние</p>
          <Select
            className={st.input}
            size="small"
            value={value.сondition}
            onChange={(e) => setValue({ ...value, сondition: e.target.value })}
          >
            <MenuItem value={"0"}>Все</MenuItem>
            <MenuItem value={"1"}>Новый</MenuItem>
            <MenuItem value={"2"}>Б/У</MenuItem>
          </Select>
        </div>

        <div className={st.item}>
          <p className={st.label}>Брэнд</p>
          <Select
            className={st.input}
            size="small"
            value={value.brand}
            onChange={(e) => setValue({ ...value, brand: e.target.value })}
          >
            {value.listBrand.map((e) => {
              return (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              )
            })}
          </Select>
        </div>
      </div>

      <div className={st.btn}>
        <ButtonElem onClick={handleOnChange}>Приминить фильтр</ButtonElem>
      </div>
    </BoxUI>
  )
}

export default Menu
