import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from '../api/api'
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"


export const getTicketStatusThunk = createAsyncThunk('ticketList/getTicketStatus', async (_, thunkAPI) => {
    try {
        const request = await API.getTicketStatus()
        return request
    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})


const ticketListSlice = createSlice({
    name: 'ticketList',
    initialState: {
        ticketStatus: [{ id: 0, name: 'Все статусы' }],
    },

    extraReducers: (builder) => {
        // ======================================================
        builder.addCase(getTicketStatusThunk.fulfilled, (state, action) => {
            state.ticketStatus = [{ id: 0, name: 'Все статусы' }, ...action.payload]
        })
    }
})

export default ticketListSlice.reducer