import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { tokens } from "../../theme"
import { useTheme } from "@mui/material"
import { ruRU } from "@mui/x-data-grid"
import st from "./Table.module.scss"

const Table = (props) => {
  const { columns, operations, className, getRowId, autoHeight = null } = props
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  return (
    <Box
      className={className}
      sx={{
        "& .MuiDataGrid-root": { border: "none" },
        "& .MuiDataGrid-cell": { borderBottom: "none" },
        "& .name-column--cell": { color: colors.greenAccent[300] },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[800],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[800],
        },
        "& .MuiDataGrid-cell:focus": { outline: "none" },
        "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      <DataGrid
        className={st.table_operations}
        autoHeight={true}
        rows={operations}
        columns={columns}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        getRowId={getRowId}
        getRowHeight={(props) => {
          if (autoHeight === null) return null

          for (let x in props.model) {
            if (!props.model[x]) continue
            if (props.model[x].length > 100) return "auto"
          }
          return null
        }}
      />
    </Box>
  )
}

export default Table
