import st from "../style/ProductCard.module.scss"
import { OperationPurchaseOperationSimple } from "../../../modules/OperationPurchaseProduct"
import { ProductSaleSimple } from "../../../modules/OperationSaleProduct"
import PopUpWriteDowns from "./PopUpWriteDowns"
import PopUpReturnProduct from "./PopUpReturnProduct"
import { ButtonBase, usePopUp } from "../../../shared"

const Actions = ({ idProduct, total_quantity, condition, sales, onUpdateData }) => {
  const finalPopUp2 = () => {
    onUpdateData()
    closePopUp()
  }

  const [showPopUp, setActivePopUp, closePopUp] = usePopUp([
    {
      name: "Продать",
      title: `Продажа продукта`,
      content: (
        <ProductSaleSimple
          finalAction={finalPopUp2}
          idProduct={idProduct}
        />
      ),
    },
    {
      name: "Докупить",
      title: `Покупка продукта`,
      content: (
        <OperationPurchaseOperationSimple
          finalAction={finalPopUp2}
          idProduct={idProduct}
        />
      ),
    },
    {
      name: "Списать",
      title: `Списание продукта`,
      content: (
        <PopUpWriteDowns
          finalAction={finalPopUp2}
          idProduct={idProduct}
        />
      ),
    },
    {
      name: "Возврат",
      title: `Возврат продукта`,
      content: (
        <PopUpReturnProduct
          finalAction={finalPopUp2}
          idProduct={idProduct}
        />
      ),
    },
  ])

  return (
    <>
      {showPopUp}
      <div className={st.buttons}>
        {total_quantity > 0 && (
          <ButtonBase
            sx={["size_2"]}
            onClick={() => setActivePopUp("Продать")}
          >
            Продать
          </ButtonBase>
        )}

        {Number(condition) === 2 && (
          <ButtonBase
            sx={["size_2"]}
            onClick={() => setActivePopUp("Докупить")}
          >
            Докупить
          </ButtonBase>
        )}

        {total_quantity > 0 && (
          <ButtonBase
            sx={["size_2"]}
            onClick={() => setActivePopUp("Списать")}
          >
            Списать
          </ButtonBase>
        )}

        {sales > 0 && (
          <ButtonBase
            sx={["size_2"]}
            onClick={() => setActivePopUp("Возврат")}
          >
            Возврат
          </ButtonBase>
        )}
      </div>
    </>
  )
}

export default Actions
