import { axiosBase, axiosBase2 } from "../../../features/globalApi"

export const API = {
  async getRateTicket() {
    const response = await axiosBase.get("/rateticket/")
    return response.data
  },

  async addSecurityTicket(props = {}) {
    const response = await axiosBase2.post(`/operation/ticket/`, props)
    return response.data
  },
}
