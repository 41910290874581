import React from "react"
import { BoxUI, ButtonElem, ButtonHref, ContainerForm, FieldSelect, FieldText } from "../../shared"
import st from "./BaseFormFields.module.scss"
import { useFormik } from "formik"
import { getValidationYup } from "../../shared/utils/validationYup"

const BaseFormFields = ({ fields, closeForm, SubmitForm, initialData }) => {
  const formik = useFormik({
    initialValues: {
      ...fields.reduce((accumulatorelem, currentValue) => {
        let value = ""
        if (initialData) {
          value = initialData[currentValue.id] ? initialData[currentValue.id] : value
        }

        return { ...accumulatorelem, ...{ [currentValue.id]: value } }
      }, {}),
    },
    onSubmit: (value) => {
      SubmitForm({ ...value })
    },
    validationSchema: getValidationYup(fields),
  })

  const field = (e) => {
    const _id = e.id
    const paramsField = { key: e.id, label: e.label, id: e.id }

    const typeFields = {
      text: (
        <FieldText
          {...paramsField}
          placeholder={e.placeholder}
          onChange={(e) => formik.setFieldValue(_id, e.target.value)}
          value={formik.values[_id]}
          error={{ touched: formik.touched[_id], message: formik.errors[_id] }}
        ></FieldText>
      ),

      select: (
        <FieldSelect
          {...paramsField}
          fun={formik.handleChange}
          list={e.list}
          value={formik.values[_id]}
          error={{ touched: formik.touched[_id], message: formik.errors[_id] }}
        ></FieldSelect>
      ),
    }

    return e.type ? typeFields[e.type] : typeFields.text
  }

  return (
    <ContainerForm>
      <BoxUI>
        <form onSubmit={formik.handleSubmit}>
          {fields.map((elem) => field(elem))}

          <div className={st.btn_wrapper}>
            <ButtonHref type="button" onClick={closeForm}>
              Отмена
            </ButtonHref>

            <ButtonElem type="submit" onClick={formik.onSubmit}>
              Сохранить
            </ButtonElem>
          </div>
        </form>
      </BoxUI>
    </ContainerForm>
  )
}

export default BaseFormFields
