import { axiosBase } from "../../../features/globalApi"

export const API = {
  async addCustomer(props = {}) {
    const response = await axiosBase.post(`/customer/`, props)
    return response.data
  },

  async getCustomersLite(query) {
    const response = await axiosBase.get(`/customer_lite/`)
    return response.data
  },

  async updateCustomer(query) {
    const response = await axiosBase.put(`/customer/${query.id}/`, query)
    return response.data
  },
}
