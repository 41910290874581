import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { useGetCategories } from "../../../../shared"
import { Table, Title } from "../../../../components"
import Menu from "../Menu/Menu"
import { getProductsThunk } from "../../store/productListSlice"
import { filterProducts } from "../../utils/filterProducts"

const columns = [
  //   {
  //     field: "Date",
  //     headerName: "Дата, время записи",
  //     // type: "date",
  //     flex: 1,
  //   },

  {
    field: "id",
    headerName: "ID",
    type: "number",
    headerAlign: "left",
    align: "left",
  },

  {
    field: "product",
    headerName: "Продукт",
    flex: 2,
    // cellClassName: st.bigcell,
    renderCell: (props) => {
      return <Link to={`/product/${props.row.id}`}>{props.value}</Link>
    },
  },

  {
    field: "total_quantity",
    headerName: "Количество",
    type: "number",
    headerAlign: "left",
    align: "left",
  },

  {
    field: "price_on_display",
    headerName: "Сумма",
    flex: 1,
  },

  {
    field: "brand",
    headerName: "Брэнд",
    flex: 1,
  },

  {
    field: "condition",
    headerName: "Состояние",
    flex: 0.5,
    // cellClassName: "name-column--cell",
    // rowclassName: "test",
  },
]

const ProductsList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { products } = useSelector((e) => e.productList)
  const url_params = useParams()
  const category = useGetCategories()[0]
  const [processedGoods, setProcessedGoods] = useState([])
  const [valueFilter, setValueFilter] = useState(null)

  useEffect(() => {
    dispatch(getProductsThunk({ cat: url_params.catId, subCat: url_params.subId }))
  }, [dispatch, url_params.catId, url_params.subId])

  useEffect(() => {
    setProcessedGoods(filterProducts(valueFilter, products, category))
    // eslint-disable-next-line
  }, [products, valueFilter])

  if (category.length === 0) {
    return <div>Ждем загрузку категорий...</div>
  }

  const getProducts = (props) => {
    setValueFilter(props)
    navigate(`/catalog/${props.cat}/${props.subCat}`)
  }

  const params = { getProducts: getProducts, category: category, products: products }
  return (
    <div>
      <Title title="Каталог" subtitle="Список товаров"></Title>
      <Menu {...params} />
      <Table className="mt20" columns={columns} operations={processedGoods} />
    </div>
  )
}

export default ProductsList
