import React, { useEffect, useState } from "react"
import st from "./FinanceFooter.module.scss"
import { BoxUI } from "../../shared"

const FinanceFooter = ({ Income, Expense }) => {
  const [calcAmounts, setCalcAmounts] = useState({ income: 0, arrival: 0, expense: 0, expenses: 0 })

  useEffect(() => {
    if (Income) {
      const income = Income.reduce((previousValue, currentValue) => {
        if (currentValue.income) return previousValue + currentValue.income
        return previousValue
      }, 0)
      setCalcAmounts((prev) => ({ ...prev, income }))
    }

    if (Expense) {
      const expenses = Expense.reduce((previousValue, currentValue) => {
        if (currentValue.income) return previousValue - currentValue.income
        return previousValue
      }, 0)
      setCalcAmounts((prev) => ({ ...prev, expenses }))
    }
  }, [Income, Expense])

  return (
    <BoxUI className={st.result}>
      <div className={st.item}>
        <div className={st.label}>Доход за период:</div>
        <div className={st.sum}>{calcAmounts.income} р</div>
      </div>

      <div className={st.item}>
        <div className={st.label}>Затраты за период:</div>
        <div className={st.sum}>{Math.abs(calcAmounts.expenses)} р</div>
      </div>

      <div className={st.item}>
        <div className={st.label}>Прибыль за период:</div>
        <div className={st.sum}>{calcAmounts.income + calcAmounts.expenses} р</div>
      </div>
    </BoxUI>
  )
}

export default FinanceFooter
