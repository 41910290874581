import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from '../api/api'
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"


export const getPropertyCardThunk = createAsyncThunk('propertyCard/getPropertyCard', async (query, thunkAPI) => {
    try {
        const request = await API.getPropertyCard(query)
        return request
    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})

const propertyCardSlice = createSlice({
    name: 'propertyCard',
    initialState: {
        ticketCard: null,
        propertyId: null,
    },

    extraReducers: (builder) => {
        // ======================================================
        builder.addCase(getPropertyCardThunk.fulfilled, (state, action) => {
            state.propertyId = action.payload
        })
        // ======================================================
    }
})

export default propertyCardSlice.reducer