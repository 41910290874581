import { configureStore } from "@reduxjs/toolkit"

import globalSlice from "./globalSlice"
import { NotificationMessageSlice } from "../modules/NotificationMessages"
import { HeaderSlice } from "../modules/Header"
import { arrivalExpenseOperationSlice } from "../pages/F_ArrivalExpenseDate"
import { ArrivalExpenseOperationsSlice } from "../pages/F_ArrivalExpenseOperations"
import { operationsSlice } from "../pages/OperationsPage"
import { productSlice } from "../pages/ProductCard"
import { productListSlice } from "../pages/ProductList"
import { customerCardSlice } from "../pages/СustomerCard"
import { authorizationSlice } from "../pages/Authorization"
import { ticketCardSlice } from "../pages/TicketCard"
import { ticketListSlice } from "../pages/TicketList"
import { propertyCardSlice } from "../pages/PropertyCard"
import { propertyListSlice } from "../pages/PropertyList"
import { purchaseProductSlice } from "../modules/OperationPurchaseProduct"
import { addUserSlice } from "../modules/AddUser"
import { customersSlice } from "../pages/Сustomers"
import { saleProductSlice } from "../modules/OperationSaleProduct"
import { selectProductSlice } from "../modules/SelectProduct"
import { otherOperationSlice } from "../modules/OperationOther"
import { securityTicketFormSlice } from "../modules/OperationSecurityTicket"
import { CreateСategorySlice } from "../modules/CreateСategory"
import { BankAccountsSlice } from "../modules/BankAccounts"
import { accountTransactionsSlice } from "../pages/F_AccountTransactions"
import { actionLogSlice } from "../pages/ActionLog"
import { teamSlice } from "../modules/Team"
import { employeeStore } from "../modules/Employee"
import { OperationPurchaseGroupProductsStore } from "../modules/OperationPurchaseGroupProducts"
import { SelectСategorySlice } from "../modules/SelectСategory"
import { DataTransferStore } from "../modules/DataTransfer"

export const store = configureStore({
  reducer: {
    global: globalSlice,
    notificationMessage: NotificationMessageSlice,
    header: HeaderSlice,
    arrivalExpenseOperation: arrivalExpenseOperationSlice,
    arrivalExpenseOperations: ArrivalExpenseOperationsSlice,
    operations: operationsSlice,
    product: productSlice,
    productList: productListSlice,
    customerCard: customerCardSlice,
    authorization: authorizationSlice,
    ticketCard: ticketCardSlice,
    ticketList: ticketListSlice,
    propertyCard: propertyCardSlice,
    propertyList: propertyListSlice,
    purchaseProduct: purchaseProductSlice,
    addUser: addUserSlice,
    customers: customersSlice,
    saleProduct: saleProductSlice,
    selectProduct: selectProductSlice,
    otherOperation: otherOperationSlice,
    securityTicketForm: securityTicketFormSlice,
    CreateСategory: CreateСategorySlice,
    BankAccounts: BankAccountsSlice,
    accountTransactions: accountTransactionsSlice,
    actionLog: actionLogSlice,
    team: teamSlice,
    employee: employeeStore,
    OperationPurchaseGroupProducts: OperationPurchaseGroupProductsStore,
    SelectСategory: SelectСategorySlice,
    DataTransfer: DataTransferStore,
  },

  devTools: true,
})
