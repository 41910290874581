import st from "../СustomerCard/СustomerCard.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { Table } from "../../../../components"
import { getProductPurchaseCustomerThunk } from "../../store/customerCardSlice"
import ROUTES, { getAbsoluteURL } from "../../../../application/routes/routes"

const PurchasesCustomer = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { productPurchaseCustomer } = useSelector((e) => e.customerCard)

  useEffect(() => {
    if (id) {
      dispatch(getProductPurchaseCustomerThunk({ customer: id }))
    }
    // eslint-disable-next-line
  }, [id])

  const columnsTable1 = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      flex: 0.2,
    },

    {
      field: "date",
      headerName: "Дата продажи",
      flex: 1,
    },

    {
      field: "product",
      headerName: "Товар",
      flex: 1,

      renderCell: (props) => {
        const url = getAbsoluteURL(ROUTES.PRODUCT_ID, props.row.product_id)
        return <Link to={url}>{props.value}</Link>
      },
    },

    {
      field: "condition",
      headerName: "Состояние товара",
      flex: 1,
    },

    {
      field: "quantity",
      headerName: "Количество",
      type: "number",
      flex: 1,
    },

    {
      field: "amount",
      headerName: "Сумма продажи",
      flex: 1,
    },

    // {
    //   field: "rate",
    //   headerName: "Тариф",
    //   flex: 1,
    // },

    // {
    //   field: "amount_interest_paid",
    //   headerName: "Уплаченные проценты",
    //   flex: 1,
    // },

    {
      field: "customer",
      headerName: "Клиент",
      flex: 1.2,
    },

    // {
    //   field: "ticket_status",
    //   headerName: "Статус",
    //   flex: 1,
    // },

    // {
    //   field: "closed_date",
    //   headerName: "Дата закрытия",
    //   // type: "date",
    //   flex: 1,
    // },
  ]

  return (
    <div className={st.table_wrapper}>
      <div className={st.title}>Залоги</div>
      <Table className={st.table} columns={columnsTable1} operations={productPurchaseCustomer} />
    </div>
  )
}

export default PurchasesCustomer
