import React, { useState } from "react"
import { Title } from "../../../components"
import { BoxUI, ButtonElem, ButtonHref, ContainerForm, FieldText } from "../../../shared"
import { useDispatch } from "react-redux"
import { createBankAccountsThunk } from "../store/BankAccounts"
import st from "../style/BankAccounts.module.scss"

const CreateBankAccount = ({ changeForm }) => {
  const [newBankAccount, setNewBankAccount] = useState("")
  const dispatch = useDispatch()

  const saveNewBankAccount = () => {
    dispatch(createBankAccountsThunk({ name: newBankAccount }))
    changeForm()
  }

  return (
    <div>
      <Title title="" subtitle={"Добавления счета"}></Title>

      <ContainerForm>
        <BoxUI>
          <FieldText
            label="Имя нового счета"
            id="first_name"
            placeholder="Пример: счет для кредитов"
            // error={{ touched: formik.touched.first_name, message: formik.errors.first_name }}
            value={newBankAccount}
            onChange={(e) => setNewBankAccount(e.target.value)}
          ></FieldText>

          <div className={st.btn_wrapper}>
            <div>
              <ButtonHref onClick={changeForm}>Отмена</ButtonHref>
            </div>

            <div>{newBankAccount.length > 0 && <ButtonElem onClick={saveNewBankAccount}>Сохранить</ButtonElem>}</div>
          </div>
        </BoxUI>
      </ContainerForm>
    </div>
  )
}

export default CreateBankAccount
