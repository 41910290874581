import * as React from "react"
import { useTheme } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
//
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import AccountBalance from "@mui/icons-material/AccountBalance"
import AddchartIcon from "@mui/icons-material/Addchart"
import AssignmentIcon from "@mui/icons-material/Assignment"
import StorageIcon from "@mui/icons-material/Storage"
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount"
import SplitscreenIcon from "@mui/icons-material/Splitscreen"
import HistoryIcon from "@mui/icons-material/History"
//
import { tokens } from "../../theme"
import st from "./Sidebar.module.scss"
import ROUTES from "../../application/routes/routes"
import { useDispatch, useSelector } from "react-redux"
import { changeCollapsed } from "../../features/globalSlice"

const Item = ({ title, to, icon, selected }) => {
  const list_patch = selected.split("/")
  const actual_path = to.split("/")
  const last_actual_path = actual_path[actual_path.length - 1]

  let class_selected = ""

  if (list_patch[1] !== "" && title !== "Dashboard") {
    class_selected = list_patch.indexOf(last_actual_path) !== -1 ? st.active : ""
  } else if (list_patch[1] === "" && title === "Dashboard") {
    class_selected = st.active
  }

  return (
    <Link className={`${st.link} ${class_selected}`} to={to}>
      <i className={st.icon}>{icon}</i>
      <p>{title}</p>
    </Link>
  )
}

function Sidebar() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const url_params = useLocation()
  const selected = url_params.pathname
  const { isCollapsed } = useSelector((e) => e.global)

  return (
    <div className={`${st.sidebar} ${!isCollapsed ? st.open : ""}`}>
      <div className={st.title}>
        <MenuOutlinedIcon
          className={st.icon}
          onClick={() => dispatch(changeCollapsed())}
          style={{ color: colors.grey[100] }}
        />
      </div>

      {/* Items */}
      <div className={st.items}>
        <Item
          title="Dashboard"
          to="/"
          icon={<HomeOutlinedIcon />}
          selected={selected}
          // setSelected={setSelected}
          isCollapsed={isCollapsed}
        />

        <div className={st.subtitle} />

        <Item
          title="Добавить операцию"
          to="/newoperation"
          icon={<AddchartIcon />}
          selected={selected}
          // setSelected={setSelected}
          isCollapsed={isCollapsed}
        />

        <Item
          title="Операции"
          to="/operations"
          icon={<AssignmentIcon />}
          selected={selected}
          // setSelected={setSelected}
          isCollapsed={isCollapsed}
        />

        <Item
          title="Каталог"
          to="/catalog"
          icon={<StorageIcon />}
          selected={selected}
          // setSelected={setSelected}
          isCollapsed={isCollapsed}
        />

        <Item
          title="Залоги и имущество"
          to="/propertyliens"
          icon={<SplitscreenIcon />}
          selected={selected}
          // setSelected={setSelected}
          isCollapsed={isCollapsed}
        />

        <Item
          title="Клиенты"
          to={ROUTES.CUSTOMERS}
          icon={<SupervisorAccountIcon />}
          selected={selected}
          // setSelected={setSelected}
          isCollapsed={isCollapsed}
        />

        <Item
          title="Финансы"
          to={ROUTES.FINANCE}
          icon={<AccountBalance />}
          selected={selected}
          // setSelected={setSelected}
          isCollapsed={isCollapsed}
        />

        <Item
          title="Журнал"
          to={ROUTES.ACTION_LOG}
          icon={<HistoryIcon />}
          selected={selected}
          isCollapsed={isCollapsed}
        />
      </div>
    </div>
  )
}

export default Sidebar
