import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { Menu, Title } from "../../../components"
import { useState } from "react"
import { getOperationThunk } from "../store/operationsSlice"
import AdditionalMenuBase from "../../../components/AdditionalMenuBase/AdditionalMenuBase"
import TableOperations from "./TableOperations"
import useDateQueryURL from "../../../shared/hooks/useDateQueryURL"

const Operations = () => {
  const dispatch = useDispatch()
  const [dataMenu, setDataMenu] = useState({})
  const { operations, employees, operation } = useSelector((e) => e.operations)
  const [operationsCopy, setOperationsCopy] = useState("")
  const [date, setDate] = useDateQueryURL()

  useEffect(() => {
    dispatch(getOperationThunk(date))
    // eslint-disable-next-line
  }, [dispatch])

  const getOperations = (props) => {
    dispatch(getOperationThunk(props))
    setDate(props)
  }

  const fields = [
    { name: "employee", list: employees, title: "Сотрудники" },
    { name: "operation", list: operation, title: "Тип операции" },
  ]

  return (
    <div>
      <Title title="Операции" subtitle="Управление операциями"></Title>

      <Menu onClickBtn={getOperations} date={date}>
        <AdditionalMenuBase
          dataMenu={dataMenu}
          setDataMenu={setDataMenu}
          fields={fields}
          list={operations}
          setList={setOperationsCopy}
        />
      </Menu>

      <TableOperations operations={operationsCopy || operations} />
    </div>
  )
}

export default Operations
