import { createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { abbreviatedThunk } from "../../../shared/utils/utils_abbreviatedThunk"

const _nameStore = "product"

export const getProductThunk = abbreviatedThunk({ _nameStore, api_request: API.product })
export const getProductWriteDownThunk = abbreviatedThunk({ _nameStore, api_request: API.getProductWriteDown })
export const productWriteDownThunk = abbreviatedThunk({ _nameStore, api_request: API.productWriteDown })
export const getProductReturnThunk = abbreviatedThunk({ _nameStore, api_request: API.getProductReturn })
export const productReturnThunk = abbreviatedThunk({ _nameStore, api_request: API.productReturn })
export const editCategoryThunk = abbreviatedThunk({ _nameStore, api_request: API.editCategory })
export const editProductNameThunk = abbreviatedThunk({ _nameStore, api_request: API.editProductName })
export const editBrandNameThunk = abbreviatedThunk({ _nameStore, api_request: API.editBrandName })
export const editPriceProductThunk = abbreviatedThunk({ _nameStore, api_request: API.editPriceProduct })

export const changeBrandThunk = abbreviatedThunk({ _nameStore, api_request: API.changeBrand })
export const getBrandThunk = abbreviatedThunk({ _nameStore, api_request: API.getBrand })

const productSlice = createSlice({
  name: _nameStore,
  initialState: {
    pageProduct: null,
    batches: [],
    purchaseHistory: [],
    redirect: false,
    productsWriteDown: null,
    productsReturn: null,
    loading: false,
    brandList: [],
  },
  reducers: {
    addBrandList: (state, data) => {
      state.brandList = [...state.brandList.filter((e) => e.id !== "a100"), data.payload]
    },

    changeStatusRedirect: (state, data) => {
      state.redirect = false
    },
  },

  extraReducers: (builder) => {
    // ======================================================
    builder.addCase(getProductThunk.rejected, (state, action) => {
      const type_error = action.payload.request.status
      if (type_error === 404) state.redirect = true
    })

    builder.addCase(getProductThunk.fulfilled, (state, action) => {
      const { batches, sales, ...other } = action.payload
      state.batches = batches
      state.purchaseHistory = sales
      state.pageProduct = other
    })

    // ======================================================
    builder.addCase(getProductWriteDownThunk.fulfilled, (state, action) => {
      state.productsWriteDown = action.payload
    })

    // ======================================================
    builder.addCase(getProductReturnThunk.pending, (state, action) => {
      state.loading = true
    })

    builder.addCase(getProductReturnThunk.fulfilled, (state, action) => {
      state.productsReturn = action.payload
      state.loading = false
    })

    builder.addCase(getBrandThunk.fulfilled, (state, action) => {
      state.brandList = action.payload
    })
    //
  },
})

export const { changeStatusRedirect, addBrandList } = productSlice.actions

export default productSlice.reducer
