import React from "react"
import { ButtonElem } from "../../../shared"
import BaseFormFieldReadOnly from "../../../components/BaseFormFieldReadOnly/BaseFormFieldReadOnly"
import { Title } from "../../../components"

const EmployeeNoEditForm = ({ data, formEditActive, formEmailActive, formPasswordActive }) => {
  let fields = [
    { id: "first_name", label: "Имя", value: "" },
    { id: "last_name", label: "Фамилия", value: "" },
    { id: "email", label: "Почта", value: "" },
    { id: "active", label: "Действующий сотрудник", value: "" },
  ]

  if (data) {
    fields = fields.map((e) => {
      return { ...e, value: data[e.id] }
    })
  }

  const Buttons = () => {
    return (
      <>
        <ButtonElem onClick={formEditActive}>Внести изменения</ButtonElem>
        <ButtonElem onClick={formPasswordActive}>Изменить пароль</ButtonElem>
        <ButtonElem onClick={formEmailActive}>Изменить логин авторизации</ButtonElem>
      </>
    )
  }

  return (
    <>
      <Title title="" subtitle={"Информация о сотруднике"}></Title>
      <BaseFormFieldReadOnly fields={fields} closeForm={() => 1} SubmitForm={() => 1} buttons={Buttons} />
    </>
  )
}

export default EmployeeNoEditForm
