import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"

export const getArrivalExpenseOperationsThunk = createAsyncThunk(
  "arrivalExpenseOperations/getArrivalExpense",
  async (query, thunkAPI) => {
    try {
      const request = await API.getArrivalExpenseOperations(query)
      return request
    } catch (err) {
      errorManager(err, thunkAPI.dispatch, addNotification)
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const initialState = {
  operations: [],

  employees: [],
  type_operation: null,
  name_operations: null,
  bank_account: null,
}

const arrivalExpenseOperationsSlice = createSlice({
  name: "arrivalExpenseOperations",
  initialState: initialState,
  extraReducers: (builder) => {
    // ======================================================
    builder.addCase(getArrivalExpenseOperationsThunk.fulfilled, (state, action) => {
      let employees = []
      let type_operation = []
      let name_operations = []
      let bank_account = []

      action.payload.map((e) => {
        employees.push(e.employee)
        type_operation.push(e.type_operation)
        name_operations.push(e.type_operation_name)
        bank_account.push(e.bank_account)
        return 0
      })

      employees = ["Все", ...new Set(employees)]
      type_operation = ["Все", ...new Set(type_operation)]
      name_operations = ["Все", ...new Set(name_operations)]
      bank_account = ["Все", ...new Set(bank_account)]

      state.employees = employees.map((e, i) => ({ id: i, value: e }))
      state.type_operation = type_operation.map((e, i) => ({ id: i, value: e }))
      state.name_operations = name_operations.map((e, i) => ({ id: i, value: e }))
      state.bank_account = bank_account.map((e, i) => ({ id: i, value: e }))

      state.operations = action.payload
    })
  },
})

export default arrivalExpenseOperationsSlice.reducer
