import { BoxUI, ButtonElem } from "../../../shared"

const MenuTeam = ({ activeElement }) => {
  return (
    <BoxUI>
      <ButtonElem onClick={activeElement}>Добавить сотрудника</ButtonElem>
    </BoxUI>
  )
}

export default MenuTeam
