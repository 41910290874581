import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from '../api/api'
import { addNotification } from "../../NotificationMessages"
import { errorManager } from "../../../shared/utils/utils"


export const searchThunk = createAsyncThunk('header/searchThunk', async (query, thunkAPI) => {
    const res = API.search(query)
    return !res.err ? res : thunkAPI.rejectWithValue('ОШИБКА!')
})

export const searchBigThunk = createAsyncThunk('header/searchBigThunk', async (query, thunkAPI) => {
    const res = API.search(query)
    return !res.err ? res : thunkAPI.rejectWithValue('ОШИБКА!')
})

export const getAuthUserThunk = createAsyncThunk('header/getAuthUser', async (query, thunkAPI) => {
    try {
        const request = await API.getAuthUser(query)
        return request

    } catch (err) {
        errorManager(err, thunkAPI.dispatch, addNotification)
        return thunkAPI.rejectWithValue(err)
    }
})


const initialState = {
    search: null,
    searchLength: 0,
    searchGroup: { products: [], products_used: [], property: [], customers: [] },
    searchValue: null,
    prioritySearch: [],
    employee: { first_name: null, last_name: null, },
}

const headerSlice = createSlice({
    name: 'header',
    initialState: initialState,
    reducers: {
        clearSearchData: (state, data) => {
            state.search = null
        },

        writeSearchValue: (state, data) => {
            state.searchValue = data.payload
        },

        clearEmployeeData: (state, data) => {
            state.employee = { first_name: null, last_name: null, }
        },
    },
    extraReducers: (builder) => {
        // ======================================================
        builder.addCase(searchThunk.fulfilled, (state, action) => {
            const keys = Object.keys(action.payload)

            let prioritySearch = [0, '']
            for (let item of keys) {
                if (action.payload[item].length > prioritySearch[0]) {
                    prioritySearch[0] = action.payload[item].length
                    prioritySearch[1] = item
                }
            }

            const result = Object.values(action.payload)

            const sumWithInitial = result.reduce((previousValue, currentValue) => {
                let counter = previousValue
                if (currentValue.length > 0) {
                    counter = previousValue + 1
                }
                return counter;
            }, 0);

            state.searchLength = sumWithInitial
            state.search = action.payload
            state.prioritySearch = prioritySearch
        })

        builder.addCase(searchBigThunk.fulfilled, (state, action) => {
            state.searchGroup = { ...state.searchGroup, ...action.payload }
        })

        builder.addCase(getAuthUserThunk.fulfilled, (state, action) => {
            state.employee = action.payload
        })


    }
})

export const {
    clearSearchData,
    writeSearchValue,
    clearEmployeeData,
} = headerSlice.actions

export default headerSlice.reducer