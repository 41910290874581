import { useState } from "react"
import PopUp from "./PopUp"

const usePopUp = (props) => {
  const [activePopUp, setActivePopUp] = useState()
  const closePopUp = () => setActivePopUp("")

  const listPopUp = {}

  const start = (_list) => {
    for (let x of _list) {
      const _p = (
        <PopUp
          title={x.title}
          content={x.content}
          close={closePopUp}
          active={!!activePopUp}
        />
      )
      listPopUp[x.name] = _p
    }
    return listPopUp
  }
  start(props)

  // const [listPopUp, setListPopUp] = useState(() => start(props))

  const getActivePopUp = () => {
    return listPopUp[activePopUp] ? listPopUp[activePopUp] : ""
    // return listPopUp[activePopUp] ? listPopUp[activePopUp] : ""
  }

  const reset = () => {
    // setListPopUp(start(props))
  }

  return [getActivePopUp(), setActivePopUp, closePopUp, reset]
}

export default usePopUp
