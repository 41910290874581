import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import CreateProperty from "./CreateProperty"
import { FieldSelect } from "../../../shared"
import { getRateTicketThunk } from "../store/securityTicketFormSlice"
import FieldElementList from "../../../shared/UI/Field/FieldElementList"

const AddProperty = (props) => {
  const dispatch = useDispatch()
  const { selectProperty, setSelectProperty, rateTicketSelect, setRateTicketSelect } = props
  const { rateTicket } = useSelector((e) => e.securityTicketForm)
  const [isPropertyForm, setIsPropertyForm] = useState(false)
  const [selectPropertyEdit, setSelectPropertyEdit] = useState(null)

  useEffect(() => {
    dispatch(getRateTicketThunk())
  }, [dispatch])

  const closeFormAddProperty = () => {
    setIsPropertyForm(false)
    setSelectPropertyEdit(null)
  }

  const openFormAddProperty = () => {
    setIsPropertyForm(true)
  }

  const addPropertyFun = (item) => {
    setSelectProperty((e) => {
      const numberId = e.length
      return [...e, { ...item, id: numberId }]
    })
    setIsPropertyForm(false)
  }

  const updatePropertyFun = (item) => {
    for (let i in selectProperty) {
      if (selectProperty[i].id === item.id) {
        selectProperty[i] = item
      }
    }
    setSelectPropertyEdit(null)
    setIsPropertyForm(false)
  }

  const deleteProperty = (id) => {
    const property_list = selectProperty.filter((e, i) => i !== id)
    setSelectProperty(property_list)
  }

  const openFormEditProperty = (obj) => {
    setSelectPropertyEdit(obj)
    openFormAddProperty(true)
  }

  return (
    <>
      {isPropertyForm ? (
        <CreateProperty
          closeFormAddProperty={closeFormAddProperty}
          property={selectPropertyEdit}
          setSelectProperty={setSelectProperty}
          addPropertyFun={selectPropertyEdit === null ? addPropertyFun : updatePropertyFun}
        />
      ) : (
        <div>
          <FieldSelect
            label="Тариф"
            // fun={(e) => setRateTicketSelect(e.target.value)}
            fun={(e) => setRateTicketSelect(e.target.value)}
            list={rateTicket}
            id="rate"
            value={rateTicketSelect}
          ></FieldSelect>

          <FieldElementList
            label="Залоговое имущество"
            addButton="Добавить залоговое имущество"
            openFormAddElement={openFormAddProperty}
            openFormEditElement={openFormEditProperty}
            listElements={selectProperty}
            deleteElement={deleteProperty}
          />
        </div>
      )}
    </>
  )
}

export default AddProperty
