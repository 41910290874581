import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../NotificationMessages"

export const addCustomerThunk = createAsyncThunk("addUserSlice/addCustomer", async (query, thunkAPI) => {
  try {
    const request = await API.addCustomer(query)
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

export const getCustomersLiteThunk = createAsyncThunk("addUserSlice/getCustomers", async (query, thunkAPI) => {
  try {
    const request = await API.getCustomersLite(query)
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

export const updateCustomerThunk = createAsyncThunk("Customer/updateCustomer", async (query, thunkAPI) => {
  const res = API.updateCustomer(query)
  return !res.err ? res : thunkAPI.rejectWithValue("ОШИБКА!")
})

const addUserSlice = createSlice({
  name: "addUser",
  initialState: {
    created_user: null,
    customers: null,
    customerCard: null,
  },
  reducers: {
    changeStatusAddPurchase: (state) => {
      state.SuccessAddPurchase = false
    },
  },
  extraReducers: (builder) => {
    //
    builder.addCase(addCustomerThunk.fulfilled, (state, action) => {
      state.created_user = action.payload
      state.customers.push(action.payload)
    })
    // ======================================================
    builder.addCase(getCustomersLiteThunk.fulfilled, (state, action) => {
      state.customers = action.payload
    })
    // ======================================================
    builder.addCase(updateCustomerThunk.fulfilled, (state, action) => {
      state.customerCard = action.payload
    })
  },
})

// export const {
//     changeStatusAddPurchase,
// } = purchaseProductSlice.actions

export default addUserSlice.reducer
