import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"

export const getTicketCardThunk = createAsyncThunk("ticketCard/getTicketCard", async (query, thunkAPI) => {
  try {
    const request = await API.getTicketCard(query)
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

export const closeTicketThunk = createAsyncThunk("ticketCard/closeTicket", async (query, thunkAPI) => {
  try {
    const request = await API.closeTicket(query)
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

export const surchargeTicketThunk = createAsyncThunk("ticketCard/surchargeTicket", async (query, thunkAPI) => {
  try {
    const request = await API.surchargeTicket(query)
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

export const withdrawalTicketThunk = createAsyncThunk("ticketCard/withdrawalTicket", async (query, thunkAPI) => {
  try {
    const request = await API.withdrawalTicket(query)
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

const ticketCardSlice = createSlice({
  name: "ticketCard",
  initialState: {
    ticketCard: null,
    isUpdateTicket: false,
    redirect: false,
  },
  reducers: {
    changeIsUpdateTicket: (state) => {
      state.isUpdateTicket = false
    },

    changeIsRedirect: (state) => {
      state.redirect = false
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTicketCardThunk.rejected, (state, action) => {
      const status_code = action.payload.response.status
      if (status_code === 404) state.redirect = true
    })
    // ======================================================
    builder.addCase(getTicketCardThunk.fulfilled, (state, action) => {
      state.ticketCard = action.payload
      state.isUpdateTicket = false
    })
    // ======================================================
    builder.addCase(closeTicketThunk.fulfilled, (state, action) => {
      state.isUpdateTicket = true
    })
    // ======================================================
    builder.addCase(surchargeTicketThunk.fulfilled, (state, action) => {
      state.isUpdateTicket = true
    })
    // ======================================================
    builder.addCase(withdrawalTicketThunk.fulfilled, (state, action) => {
      state.isUpdateTicket = true
    })
  },
})

export const { changeIsUpdateTicket, changeIsRedirect } = ticketCardSlice.actions

export default ticketCardSlice.reducer
