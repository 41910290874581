import { useState } from "react"
import AddProduct from "./AddProduct/AddProduct"
import { SwitchingForm } from "../../../components"
import { BoxUI, ContainerForm } from "../../../shared"
import { AddUser } from "../../AddUser"
import { useDispatch } from "react-redux"
import { addOperationThunk } from "../store/purchaseProductSlice"
import CashRegisterForm from "../../CashRegisterForm/components/CashRegisterForm"
import useRedirect from "../../../shared/hooks/useRedirect"

const PurchaseOperation = () => {
  const dispatch = useDispatch()

  const [stepForm, setStepForm] = useState(1)
  const [selectClient, setSelectClient] = useState("")
  const [selectProduct, setSelectProduct] = useState("")
  useRedirect()

  const stepActive = [!!selectClient, !!selectProduct]

  const finish = (res) => {
    const result = { ...res, customer: selectClient.id, product: [selectProduct] }
    dispatch(addOperationThunk(result))
  }

  const displayCounteBuy = true ? selectProduct.condition === 2 : false

  const isStepForm = (numberForm) => {
    if (numberForm === 1) {
      return <AddUser setSelectClient={setSelectClient} selectClient={selectClient} />
    } else if (numberForm === 2) {
      return <AddProduct setSelectProduct={setSelectProduct} selectProduct={selectProduct} />
    } else if (numberForm === 3) {
      return <CashRegisterForm finish={finish} typeOperation={"buy"} countBuy={displayCounteBuy} />
    }
  }

  return (
    <div>
      <ContainerForm>
        <BoxUI>
          <SwitchingForm setStepForm={setStepForm} stepForm={stepForm} stepActive={stepActive} />
          {isStepForm(stepForm)}
        </BoxUI>
      </ContainerForm>
    </div>
  )
}

export default PurchaseOperation
