import st from "./Menu.module.scss"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from "react"
import MenuCustom from "../../../../components/MenuCustom/MenuCustom"
import { getPropertyStatusThunk } from "../../store/propertyListSlice"

const Menu = (props) => {
  const { onTicketSubmit } = props
  const dispatch = useDispatch()
  const [status, setStatus] = useState("Все статусы")

  const { propertyStatus } = useSelector((e) => e.propertyList)

  useEffect(() => {
    dispatch(getPropertyStatusThunk())
    // eslint-disable-next-line
  }, [])

  const SelectFilter = () => {
    return (
      <Select className={st.select} size="small" value={status} onChange={(e) => setStatus(e.target.value)}>
        {propertyStatus.map((e) => {
          return (
            <MenuItem key={e.id} value={e.name}>
              {e.name}
            </MenuItem>
          )
        })}
      </Select>
    )
  }

  const onClickMenu = () => {
    onTicketSubmit(status)
  }

  return (
    <MenuCustom onClickBtn={onClickMenu}>
      <SelectFilter />
    </MenuCustom>
  )
}

export default Menu
