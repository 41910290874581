import { Autocomplete, TextField } from "@mui/material"
import st from "./Field.module.scss"

export default function FieldSearch(props) {
  const { label, value, fun, id, list, render } = props

  const id_vs_name = id ? { name: id, id: id } : ""

  let render_options = {}

  if (render) {
    render_options = list.map((e) => render(e))
  } else {
    render_options = list.map((e) => ({ label: e.name, id: e.id }))
  }

  return (
    <div className={st.field}>
      <label className={st.label}>{label}</label>
      <Autocomplete
        {...id_vs_name}
        value={value}
        onChange={fun}
        size="small"
        className={st.input}
        freeSolo
        options={render_options}
        renderInput={(params) => {
          return <TextField {...params} {...id_vs_name} />
        }}
      />
    </div>
  )
}
