import React from "react"
import st from "./PopUpWindow.module.scss"
import CloseIcon from "@mui/icons-material/Close"

const PopUpWindow = ({ content, close, title }) => {
  return (
    <div className={st.pop_up}>
      <div className={st.message}>
        <div className={st.close}>
          <CloseIcon className={st.icon} onClick={close} />
        </div>
        {title ? <div className={st.title}>{title}</div> : ""}

        <div className={st.content}>{content()}</div>
      </div>
    </div>
  )
}

export default PopUpWindow
