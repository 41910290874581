import { createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { abbreviatedThunk } from "../../../shared/utils/utils_abbreviatedThunk"

const _nameStore = "OperationPurchaseGroupProducts"

export const addPartyProductThunk = abbreviatedThunk({
  _nameStore,
  api_request: API.addPartyProduct,
  redirectSuccess: "/operations",
})

const Slice = createSlice({
  name: _nameStore,
  initialState: {
    requestCompleted: {},
  },
  reducers: {
    changeStatusRequest: (state, action) => {
      state.requestCompleted[action.payload] = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPartyProductThunk.fulfilled, (state, action) => {
      // debugger
      // const keys = Object.keys(action.payload)
      // state.requestCompleted = keys[0]
    })
    // ======================================================
  },
})

export const { changeStatusRequest } = Slice.actions

export default Slice.reducer
