import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { redirectClear } from "../../features/globalSlice"

export default function useRedirect() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { redirectURL } = useSelector((e) => e.global)

  useEffect(() => {
    if (redirectURL) {
      dispatch(redirectClear())
      navigate(redirectURL)
    }
    // eslint-disable-next-line
  }, [redirectURL])
}
