import dayjs from "dayjs"

export const getValueDate = (row) => {
  const data_field = row.row[row.field]
  const data = dayjs(data_field, "DD.MM.YYYY")
  return data.format("DD.MM.YYYY")
}

export const convertDate = (date) => {
  if (date === undefined) return

  if (typeof date === "string") {
    return dayjs(date, "DD.MM.YYYY")
  } else {
    return date.format("DD.MM.YYYY")
  }
}

export const convertTime = (time) => {
  if (time === undefined) return

  if (typeof time === "string") {
    return dayjs(time, "HH:mm:ss")
  } else {
    return time.format("HH:mm:ss")
  }
}

export const getCurentDateTime = () => {
  const date = dayjs(new Date())
  const dateTime = `${convertDate(date)} ${convertTime(date)}`
  return dateTime
}

export const getCurentDate = () => {
  const date = dayjs(new Date())
  const dateTime = `${convertDate(date)}`
  return dateTime
}
