import { axiosBase } from "../../../features/globalApi"

export const API = {
  async getEmployeeId(params) {
    const response = await axiosBase.get(`/employee/${params.id}/`)
    return response.data
  },

  async passwordChangeEmployee(params) {
    const response = await axiosBase.put(`/employee/password_change/`, params)
    return response.data
  },

  async loginChangeEmployee(params) {
    const response = await axiosBase.put(`/employee/login_change/`, params)
    return response.data
  },

  async dataChangeEmployee(params) {
    const response = await axiosBase.put(`/employee/`, params)
    return response.data
  },
}
