import React, { useEffect, useState } from "react"
import { ButtonBase, Container600 } from "../../../shared"
import FieldSelectPlus from "../../../shared/UI/Field/FieldSelectPlus"
import { useDispatch, useSelector } from "react-redux"
import { addBrandList, changeBrandThunk, getBrandThunk } from "../store/productSlice"

const SelectBrand = ({ id_product, closePopUp, onUpdateData, value }) => {
  const { brandList } = useSelector((e) => e.product)
  const dispatch = useDispatch()
  const [valueField, setValueField] = useState("")

  useEffect(() => {
    dispatch(getBrandThunk()).then((e) => {
      if (!e.error) {
        const result = e.payload.filter((item) => item.name === value)[0]
        setValueField(result.id)
      }
    })
    // eslint-disable-next-line
  }, [])

  const cleanText = (text) => {
    return text.toLowerCase().trim()
  }

  const addCategory = (value) => {
    const _t = brandList.filter((e) => cleanText(e.name) === cleanText(value))
    let params = null
    if (_t.length > 0) {
      params = { id: _t[0].id, name: _t[0].name }
    } else {
      params = { id: `a100`, name: value }
      dispatch(addBrandList(params))
    }

    setValueField(params.id)
  }

  const onSave = () => {
    const name = brandList.filter((e) => e.id === valueField)[0]
    const params = { id_product, brand: name.name }

    dispatch(changeBrandThunk(params)).then((e) => {
      if (!e.error) {
        onUpdateData()
        closePopUp()
      }
    })
  }

  if (!brandList === null) return <div>Loadiang...</div>

  return (
    <Container600>
      <FieldSelectPlus
        label="Бренд"
        fun={setValueField}
        addFun={addCategory}
        list={brandList}
        id="category"
        value={valueField}
      ></FieldSelectPlus>

      <div className="mt20">{value && <ButtonBase onClick={onSave}>Сохранить изменения</ButtonBase>}</div>
    </Container600>
  )
}

export default SelectBrand
