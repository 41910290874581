import st from "./Search.module.scss"
import Title from "../../../../components/Title/Title"
import ROUTES from "../../../../application/routes/routes"
import { Outlet, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { ButtonLink } from "../../../../shared"

const Search = () => {
  // TODO сервер должен дать информацию какая категория поиска приоритетный к показу
  const navigate = useNavigate()
  const { searchValue, prioritySearch } = useSelector((e) => e.header)

  useEffect(() => {
    if (prioritySearch[1]) navigate(`${prioritySearch[1]}`)
    // eslint-disable-next-line
  }, [prioritySearch])

  useEffect(() => {
    if (!searchValue || searchValue.length === 0) {
      navigate("/")
    }
    // eslint-disable-next-line
  }, [searchValue])

  return (
    <div>
      <Title title={`Результат поиска "${searchValue}"`} subtitle={""}></Title>

      <div className={st.items}>
        <ButtonLink link={ROUTES.SEARCH_PRODUCTS}>Новые товары</ButtonLink>
        <ButtonLink link={ROUTES.SEARCH_PRODUCTS_USED}>Б/У товары</ButtonLink>
        <ButtonLink link={ROUTES.SEARCH_PROPERTY}>Имущество</ButtonLink>
        <ButtonLink link={ROUTES.SEARCH_CUSTOMERS}>Клиенты</ButtonLink>
      </div>
      <div className={st.content}>{searchValue ? <Outlet /> : "Loadiang..."}</div>
    </div>
  )
}

export default Search
