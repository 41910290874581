import React from "react"
import st from "./ButtonGroup.module.scss"
import { ButtonElem } from "../../shared"

const ButtonGroup = (props) => {
  const { className, actions } = props

  return (
    <div className={`${st.menu} ${className}`}>
      <div className={st.buttons}>
        {actions.map((e) => {
          if (e.disabled == true) return ""
          return (
            <ButtonElem key={e.name} className={st.btn} onClick={e.action}>
              {e.name}
            </ButtonElem>
          )
        })}
      </div>
    </div>
  )
}

export default ButtonGroup
