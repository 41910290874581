import { ButtonHref, FieldSearch, FieldTextEmpty } from "../../../../shared"
import { useSelector } from "react-redux"
import { getCustomersLiteThunk } from "../../store/addUserSlice"
import { useDispatch } from "react-redux"
import { useEffect } from "react"

const SelectUser = (props) => {
  const { customers } = useSelector((e) => e.addUser)
  const { setSelectClient, selectClient, changeStateForm } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCustomersLiteThunk())
  }, [dispatch])

  const onWriteFields = (e, data) => {
    if (data) {
      setSelectClient(data)
    } else {
      setSelectClient("")
    }
  }

  const isCustomers = (customers) => {
    if (customers === null) {
      return <FieldTextEmpty label="Выбор клиента" valueField={() => <div>Идет загрузка клиентов!</div>} />
    }

    if (customers.length === 0) {
      return (
        <FieldTextEmpty
          label="Выбор клиента"
          valueField={() => <div>Список клиентов пуст, добавьте нового клиента!</div>}
        />
      )
    }

    return (
      <FieldSearch
        id="client"
        label="Выбор клиента"
        list={customers}
        value={selectClient}
        fun={onWriteFields}
        render={(e) => ({ label: `id_${e.id} ${e.full_name}`, id: e.id })}
      ></FieldSearch>
    )
  }

  return (
    <>
      {isCustomers(customers)}
      <div>
        <ButtonHref onClick={changeStateForm}>Добавить нового клиента</ButtonHref>
      </div>
    </>
  )
}

export default SelectUser
