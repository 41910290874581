import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../api/api"
import { errorManager } from "../../../shared/utils/utils"
import { addNotification } from "../../../modules/NotificationMessages"

export const authThunk = createAsyncThunk("Customer/auth", async (query, thunkAPI) => {
  try {
    const request = await API.auth(query)
    thunkAPI.dispatch(addNotification("Авторизация выполнена"))
    return request
  } catch (err) {
    errorManager(err, thunkAPI.dispatch, addNotification)
    return thunkAPI.rejectWithValue(err)
  }
})

const authorizationSlice = createSlice({
  name: "authorization",
  initialState: {
    IsAuth: null,
  },

  reducers: {
    IsAuthStatus: (state, data) => {
      const token = localStorage.getItem("token")
      state.IsAuth = token ? true : false
    },

    Logout: (state, data) => {
      localStorage.removeItem("token")
      state.IsAuth = false
    },
  },

  extraReducers: (builder) => {
    // ======================================================
    builder.addCase(authThunk.fulfilled, (state, action) => {
      localStorage.setItem("token", action.payload.token)
      state.IsAuth = true
    })
    // ======================================================
  },
})

export const { IsAuthStatus, Logout } = authorizationSlice.actions

export default authorizationSlice.reducer
