import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useParams } from "react-router-dom"
import st from "./СustomerCard.module.scss"
import ROUTES, { getAbsoluteURL } from "../../../../application/routes/routes"
import { AccordionUI, ButtonElem, ButtonLink, FieldReadOnly } from "../../../../shared"
// import AddUserForm from "../../../../components/AddUser/AddUserForm"
import { Title } from "../../../../components"
import { getCustomerThunk } from "../../store/customerCardSlice"
import { AddUserForm } from "../../../../modules/AddUser"

const Edit = ({ customerCard, setEditMode }) => {
  return (
    <div className={st.items}>
      {
        <AddUserForm
          customer={customerCard}
          nameButtomSubmit={"Сохранить изменения"}
          buttomCancel={false}
          setFormAddOpen={setEditMode}
        />
      }
    </div>
  )
}

const NoEdit = ({ customerCard }) => {
  const passport = customerCard.passport

  return (
    <div className={st.items}>
      <FieldReadOnly label="ФИО" value={customerCard.full_name} />
      <FieldReadOnly label="Дата рождения" value={customerCard.date_birth} />

      {passport ? (
        <AccordionUI
          title={"Паспорт"}
          investment={[
            <FieldReadOnly key="1" label="Серия, номер" value={passport.series_number} />,
            <FieldReadOnly key="2" label="Кем выдан" value={passport.issued_whom} />,
            <FieldReadOnly key="3" label="дата выдачи документа" value={passport.date_issue} />,
            <FieldReadOnly key="4" label="Код подразделения" value={passport.division_code} />,
            <FieldReadOnly key="5" label="Адрес регистрации" value={passport.place_residence} />,
          ]}
        />
      ) : (
        <div>Данные паспорта не заполнены</div>
      )}
    </div>
  )
}

const СustomerCard = () => {
  const dispatch = useDispatch()
  const url_params = useParams()
  const [editMode, setEditMode] = useState(false)
  const { customerCard, loadingStatus } = useSelector((e) => e.customerCard)
  //
  useEffect(() => {
    dispatch(getCustomerThunk({ id: url_params.id }))
    // eslint-disable-next-line
  }, [])

  if (loadingStatus.customerCard === true) return "Londing..."

  const getURL = (path) => {
    return getAbsoluteURL(path, customerCard.id)
  }

  return (
    <div className={st.product}>
      <Title title="Страница клиента" subtitle={customerCard.full_name}></Title>

      <div className={st.info}>
        <ButtonElem className={st.btn_edit} onClick={() => setEditMode(!editMode)}>
          Изменить
        </ButtonElem>

        {editMode ? (
          <Edit customerCard={customerCard} setEditMode={setEditMode} />
        ) : (
          <NoEdit customerCard={customerCard} />
        )}
      </div>

      <div className={st.btn_list}>
        <ButtonLink link={getURL(ROUTES.TICKETS_CUSTOMER)}>Залоги</ButtonLink>
        <ButtonLink link={getURL(ROUTES.SALES_CUSTOMER)}>Клиент продал</ButtonLink>
        <ButtonLink link={getURL(ROUTES.PURCHASES_CUSTOMER)}>Клиент купил</ButtonLink>
      </div>

      <Outlet />
    </div>
  )
}

export default СustomerCard
