import React from "react"
import st from "../style/ProductCard.module.scss"
import { Table } from "../../../components"
import ROUTES, { getAbsoluteURL } from "../../../application/routes/routes"
import { Link } from "react-router-dom"
import DeleteIconTable from "../../../shared/UI/DeleteIconTable/DeleteIconTable"

const PurchaseTable = ({ popUpOpenFun, data }) => {
  const columnsTable1 = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.1,
    },

    {
      field: "date",
      headerName: "Дата, время записи",
      // type: "date",
      flex: 1,
    },

    {
      field: "type_operation",
      headerName: "Тип операции",
      flex: 1,
    },

    {
      field: "customer",
      headerName: "Клиент",
      flex: 1.1,

      renderCell: (props) => {
        if (props.row.customer_id) {
          const url = getAbsoluteURL(ROUTES.CUSTOMER_ID, props.row.customer_id)
          return <Link to={url}>{props.row.customer}</Link>
        }
        return <div></div>
      },
    },

    {
      field: "quantity",
      headerName: "Количество",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },

    {
      field: "amount",
      headerName: "Цена за шт",
      flex: 1,
    },

    {
      field: "total_amount",
      headerName: "Сумма",
      flex: 1,
    },

    {
      field: "employee",
      headerName: "Сотрудник",
      flex: 1,

      // cellClassName: st.bigcell,
      // renderCell: (props) => {
      //   return <Link to={`/product/${props.row.id}`}>{props.value}</Link>
      // },
    },

    {
      field: "subcategory",
      headerName: "Функции",
      flex: 0.1,

      renderCell: (props) => {
        return <DeleteIconTable deleteOperation={() => popUpOpenFun(props.row.operation)} />
      },
    },
  ]
  return (
    <div className={st.wrapper}>
      <div className={st.title}>История покупок</div>
      <Table className={st.table} columns={columnsTable1} operations={data} />
    </div>
  )
}

export default PurchaseTable
