import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

import st from "./SwitchingForm.module.scss"

const SwitchingForm = (props) => {
  const { setStepForm, stepForm, stepActive } = props

  const NextPageForm = (param) => {
    if (param === "next" && stepForm !== 3) {
      setStepForm(stepForm + 1)
    } else if (param === "prev" && stepForm !== 1) {
      setStepForm(stepForm - 1)
    }
  }

  //   Отключение кнопок
  let btn_disabled_1 = true
  let btn_disabled_2 = true

  if (stepActive[0] === true && stepForm === 1) btn_disabled_2 = false
  if (stepActive[1] === true && stepForm === 2) btn_disabled_2 = false
  if (stepForm > 1) btn_disabled_1 = false
  if (stepActive.length === stepForm - 1) btn_disabled_2 = true

  return (
    <div className={st.btn}>
      {btn_disabled_1 ? (
        <div className={`${st.link_step} ${st.disabled}`}>
          <ArrowBackIcon className={st.arrow} />
        </div>
      ) : (
        <div className={st.link_step} onClick={() => NextPageForm("prev")}>
          <ArrowBackIcon className={st.arrow} />
        </div>
      )}

      {btn_disabled_2 ? (
        <div className={`${st.link_step} ${st.disabled}`}>
          <ArrowForwardIcon className={st.arrow} />
        </div>
      ) : (
        <div className={st.link_step} onClick={() => NextPageForm("next")}>
          <ArrowForwardIcon className={st.arrow} />
        </div>
      )}
    </div>
  )
}

export default SwitchingForm
