import React from "react"
import st from "./ButtonElem.module.scss"
import { Link } from "react-router-dom"

const ButtonElem = (props) => {
  const { children, link, className } = props

  if (link) {
    return (
      <Link to={link} className={st.btn}>
        {children}
      </Link>
    )
  } else {
    return (
      <button {...props} className={className ? `${st.btn} ${className}` : st.btn}>
        {children}
      </button>
    )
  }
}

export default ButtonElem
